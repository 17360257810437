"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var schema_1 = require("../data/schema");
var linkLayer_1 = require("../diagram/linkLayer");
var elements_1 = require("../diagram/elements");
var geometry_1 = require("../diagram/geometry");
var paper_1 = require("../diagram/paper");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var spinner_1 = require("../viewUtils/spinner");
var authoringState_1 = require("./authoringState");
var EditLayerMode;
(function (EditLayerMode) {
    EditLayerMode[EditLayerMode["establishLink"] = 0] = "establishLink";
    EditLayerMode[EditLayerMode["moveLinkSource"] = 1] = "moveLinkSource";
    EditLayerMode[EditLayerMode["moveLinkTarget"] = 2] = "moveLinkTarget";
})(EditLayerMode = exports.EditLayerMode || (exports.EditLayerMode = {}));
var EditLayer = /** @class */ (function (_super) {
    tslib_1.__extends(EditLayer, _super);
    function EditLayer(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.cancellation = new async_1.Cancellation();
        _this.canDropOnElementCancellation = new async_1.Cancellation();
        _this.beginCreatingLink = function (params) {
            var editor = _this.props.editor;
            var sourceId = params.sourceId, point = params.point;
            var temporaryElement = _this.createTemporaryElement(point);
            var linkTemplate = new elements_1.Link({
                typeId: schema_1.PLACEHOLDER_LINK_TYPE,
                sourceId: sourceId,
                targetId: temporaryElement.id,
            });
            var temporaryLink = editor.createNewLink({ link: linkTemplate, temporary: true });
            var fatLinkType = editor.model.createLinkType(temporaryLink.typeId);
            fatLinkType.setVisibility({ visible: true, showLabel: false });
            _this.temporaryElement = temporaryElement;
            _this.temporaryLink = temporaryLink;
        };
        _this.onMouseMove = function (e) {
            var _a = _this.props, view = _a.view, paperArea = _a.paperArea;
            var _b = _this.state, targetElement = _b.targetElement, waitingForMetadata = _b.waitingForMetadata;
            e.preventDefault();
            e.stopPropagation();
            if (waitingForMetadata) {
                return;
            }
            var point = paperArea.pageToPaperCoords(e.pageX, e.pageY);
            _this.temporaryElement.setPosition(point);
            var newTargetElement = geometry_1.findElementAtPoint(view.model.elements, point);
            if (newTargetElement !== targetElement) {
                _this.queryCanDropOnElement(newTargetElement);
            }
            _this.setState({ targetElement: newTargetElement });
        };
        _this.onMouseUp = function (e) {
            if (_this.state.waitingForMetadata) {
                return;
            }
            // show spinner while waiting for additinal MetadataApi queries
            _this.setState({ waitingForMetadata: true });
            var selectedPosition = _this.props.paperArea.pageToPaperCoords(e.pageX, e.pageY);
            _this.executeEditOperation(selectedPosition);
        };
        _this.createNewElement = function (source) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, editor, metadataApi, elementTypes, classId, type, typeName, labelText, types, entityIri, elementModel;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, editor = _a.editor, metadataApi = _a.metadataApi;
                        if (!metadataApi) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.typesOfElementsDraggedFrom(source, this.cancellation.signal))];
                    case 1:
                        elementTypes = _b.sent();
                        if (elementTypes === null) {
                            return [2 /*return*/];
                        }
                        classId = elementTypes.length === 1 ? elementTypes[0] : schema_1.PLACEHOLDER_ELEMENT_TYPE;
                        type = this.props.editor.model.createClass(classId);
                        typeName = this.props.view.formatLabel(type.label, type.id);
                        labelText = classId === schema_1.PLACEHOLDER_ELEMENT_TYPE ? 'New Entity' : "New " + typeName;
                        types = [classId];
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.generateNewElementIri(types, this.cancellation.signal))];
                    case 2:
                        entityIri = _b.sent();
                        if (entityIri === null) {
                            return [2 /*return*/];
                        }
                        elementModel = {
                            id: entityIri,
                            types: types,
                            label: { values: [{ value: labelText, language: '' }] },
                            properties: {},
                        };
                        return [2 /*return*/, editor.createNewEntity({ elementModel: elementModel, temporary: true })];
                }
            });
        }); };
        _this.state = {};
        return _this;
    }
    EditLayer.prototype.componentDidMount = function () {
        var _a = this.props, mode = _a.mode, target = _a.target, point = _a.point;
        if (mode === EditLayerMode.establishLink) {
            this.beginCreatingLink({ sourceId: target.id, point: point });
        }
        else if (mode === EditLayerMode.moveLinkSource || mode === EditLayerMode.moveLinkTarget) {
            this.beginMovingLink(target, point);
        }
        else {
            throw new Error('Unknown edit mode');
        }
        this.forceUpdate();
        this.queryCanLinkFrom();
        this.queryCanDropOnCanvas();
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    };
    EditLayer.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.cancellation.abort();
        this.canDropOnElementCancellation.abort();
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };
    EditLayer.prototype.beginMovingLink = function (target, startingPoint) {
        var _a = this.props, editor = _a.editor, mode = _a.mode;
        if (!(mode === EditLayerMode.moveLinkSource || mode === EditLayerMode.moveLinkTarget)) {
            throw new Error('Unexpected edit mode for moving link');
        }
        this.oldLink = target;
        editor.model.removeLink(target.id);
        var id = target.id, typeId = target.typeId, sourceId = target.sourceId, targetId = target.targetId, otherProps = tslib_1.__rest(target, ["id", "typeId", "sourceId", "targetId"]);
        var temporaryElement = this.createTemporaryElement(startingPoint);
        var linkTemplate = new elements_1.Link(tslib_1.__assign({ typeId: typeId, sourceId: mode === EditLayerMode.moveLinkSource ? temporaryElement.id : sourceId, targetId: mode === EditLayerMode.moveLinkTarget ? temporaryElement.id : targetId }, otherProps));
        var temporaryLink = editor.createNewLink({ link: linkTemplate, temporary: true });
        this.temporaryElement = temporaryElement;
        this.temporaryLink = temporaryLink;
    };
    EditLayer.prototype.createTemporaryElement = function (point) {
        var temporaryElement = this.props.view.model.createTemporaryElement();
        temporaryElement.setPosition(point);
        return temporaryElement;
    };
    EditLayer.prototype.queryCanLinkFrom = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, metadataApi = _a.metadataApi;
        if (!metadataApi) {
            this.setState({ canLinkFrom: false });
            return;
        }
        this.setState({ canLinkFrom: undefined });
        var source = editor.model.getElement(this.temporaryLink.sourceId);
        async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.canLinkElement(source.data, this.cancellation.signal)).then(function (canLinkFrom) {
            if (canLinkFrom === null) {
                return;
            }
            _this.setState({ canLinkFrom: canLinkFrom });
        }, function (error) {
            // tslint:disable-next-line: no-console
            console.error('Error calling canLinkElement:', error);
            _this.setState({ canLinkFrom: false });
        });
    };
    EditLayer.prototype.queryCanDropOnCanvas = function () {
        var _this = this;
        var _a = this.props, mode = _a.mode, editor = _a.editor, metadataApi = _a.metadataApi;
        if (!metadataApi || mode !== EditLayerMode.establishLink) {
            this.setState({ canDropOnCanvas: false });
            return;
        }
        this.setState({ canDropOnCanvas: undefined });
        var source = editor.model.getElement(this.temporaryLink.sourceId);
        async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.canDropOnCanvas(source.data, this.cancellation.signal)).then(function (canDropOnCanvas) {
            if (canDropOnCanvas === null) {
                return;
            }
            _this.setState({ canDropOnCanvas: canDropOnCanvas });
        }, function (error) {
            // tslint:disable-next-line: no-console
            console.error('Error calling canDropOnCanvas:', error);
            _this.setState({ canDropOnCanvas: false });
        });
    };
    EditLayer.prototype.queryCanDropOnElement = function (targetElement) {
        var _this = this;
        var _a = this.props, mode = _a.mode, editor = _a.editor, metadataApi = _a.metadataApi;
        this.canDropOnElementCancellation.abort();
        this.canDropOnElementCancellation = new async_1.Cancellation();
        if (!(metadataApi && targetElement)) {
            this.setState({ canDropOnElement: false });
            return;
        }
        this.setState({ canDropOnElement: undefined });
        var source;
        var target;
        if (mode === EditLayerMode.establishLink || mode === EditLayerMode.moveLinkTarget) {
            source = editor.model.getElement(this.temporaryLink.sourceId).data;
            target = targetElement.data;
        }
        else if (mode === EditLayerMode.moveLinkSource) {
            source = targetElement.data;
            target = editor.model.getElement(this.temporaryLink.targetId).data;
        }
        var signal = this.canDropOnElementCancellation.signal;
        async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.canDropOnElement(source, target, signal)).then(function (canDropOnElement) {
            if (canDropOnElement === null) {
                return;
            }
            _this.setState({ canDropOnElement: canDropOnElement });
        });
    };
    EditLayer.prototype.executeEditOperation = function (selectedPosition) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, view, editor, mode, _b, targetElement, canLinkFrom, canDropOnCanvas, canDropOnElement, canDrop, modifiedLink, createdTarget, _c, source, sourceElement, focusedLink, source;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props, view = _a.view, editor = _a.editor, mode = _a.mode;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, , 11, 12]);
                        _b = this.state, targetElement = _b.targetElement, canLinkFrom = _b.canLinkFrom, canDropOnCanvas = _b.canDropOnCanvas, canDropOnElement = _b.canDropOnElement;
                        if (this.oldLink) {
                            editor.model.addLink(this.oldLink);
                        }
                        canDrop = targetElement ? canDropOnElement : canDropOnCanvas;
                        if (!(canLinkFrom && canDrop)) return [3 /*break*/, 10];
                        modifiedLink = void 0;
                        createdTarget = targetElement;
                        _c = mode;
                        switch (_c) {
                            case EditLayerMode.establishLink: return [3 /*break*/, 2];
                            case EditLayerMode.moveLinkSource: return [3 /*break*/, 6];
                            case EditLayerMode.moveLinkTarget: return [3 /*break*/, 7];
                        }
                        return [3 /*break*/, 8];
                    case 2:
                        if (!!createdTarget) return [3 /*break*/, 4];
                        source = editor.model.getElement(this.temporaryLink.sourceId);
                        return [4 /*yield*/, this.createNewElement(source.data)];
                    case 3:
                        createdTarget = _d.sent();
                        createdTarget.setPosition(selectedPosition);
                        view.performSyncUpdate();
                        centerElementAtPoint(createdTarget, selectedPosition);
                        _d.label = 4;
                    case 4:
                        sourceElement = editor.model.getElement(this.temporaryLink.sourceId);
                        return [4 /*yield*/, this.createNewLink(sourceElement, createdTarget)];
                    case 5:
                        modifiedLink = _d.sent();
                        return [3 /*break*/, 9];
                    case 6:
                        {
                            modifiedLink = editor.moveLinkSource({ link: this.oldLink, newSource: targetElement });
                            return [3 /*break*/, 9];
                        }
                        _d.label = 7;
                    case 7:
                        {
                            modifiedLink = editor.moveLinkTarget({ link: this.oldLink, newTarget: targetElement });
                            return [3 /*break*/, 9];
                        }
                        _d.label = 8;
                    case 8:
                        {
                            throw new Error('Unknown edit mode');
                        }
                        _d.label = 9;
                    case 9:
                        if (targetElement) {
                            focusedLink = modifiedLink || this.oldLink;
                            editor.setSelection([focusedLink]);
                            editor.showEditLinkForm(focusedLink);
                        }
                        else if (createdTarget && modifiedLink) {
                            editor.setSelection([createdTarget]);
                            source = editor.model.getElement(modifiedLink.sourceId);
                            editor.showEditElementTypeForm({
                                link: modifiedLink,
                                source: source,
                                target: createdTarget,
                                targetIsNew: true,
                            });
                        }
                        _d.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        this.cleanupAndFinish();
                        return [7 /*endfinally*/];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    EditLayer.prototype.createNewLink = function (source, target) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, editor, metadataApi, linkTypes, placeholder, _b, typeId, direction, data, _c, sourceId, targetId, link, existingLink;
            var _d;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.props, editor = _a.editor, metadataApi = _a.metadataApi;
                        if (!metadataApi) {
                            return [2 /*return*/, undefined];
                        }
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.possibleLinkTypes(source.data, target.data, this.cancellation.signal))];
                    case 1:
                        linkTypes = _e.sent();
                        if (linkTypes === null) {
                            return [2 /*return*/, undefined];
                        }
                        placeholder = { linkTypeIri: schema_1.PLACEHOLDER_LINK_TYPE, direction: elements_1.LinkDirection.out };
                        _b = linkTypes.length === 1 ? linkTypes[0] : placeholder, typeId = _b.linkTypeIri, direction = _b.direction;
                        data = {
                            linkTypeId: typeId,
                            sourceId: source.iri,
                            targetId: target.iri,
                        };
                        _c = [source.id, target.id], sourceId = _c[0], targetId = _c[1];
                        // switches source and target if the direction equals 'in'
                        if (direction === elements_1.LinkDirection.in) {
                            data.sourceId = target.iri;
                            data.targetId = source.iri;
                            _d = [targetId, sourceId], sourceId = _d[0], targetId = _d[1];
                        }
                        link = new elements_1.Link({ typeId: typeId, sourceId: sourceId, targetId: targetId, data: data });
                        existingLink = editor.model.findLink(link.typeId, link.sourceId, link.targetId);
                        return [2 /*return*/, existingLink || editor.createNewLink({ link: link, temporary: true })];
                }
            });
        });
    };
    EditLayer.prototype.cleanupAndFinish = function () {
        var _a = this.props, editor = _a.editor, onFinishEditing = _a.onFinishEditing;
        var batch = editor.model.history.startBatch();
        editor.model.removeElement(this.temporaryElement.id);
        editor.model.removeLink(this.temporaryLink.id);
        editor.setTemporaryState(authoringState_1.TemporaryState.deleteLink(editor.temporaryState, this.temporaryLink.data));
        batch.discard();
        onFinishEditing();
    };
    EditLayer.prototype.render = function () {
        var _a = this.props, view = _a.view, paperTransform = _a.paperTransform;
        var waitingForMetadata = this.state.waitingForMetadata;
        if (!this.temporaryLink) {
            return null;
        }
        return (React.createElement(paper_1.TransformedSvgCanvas, { paperTransform: paperTransform, style: { overflow: 'visible' } },
            React.createElement(linkLayer_1.LinkMarkers, { view: view }),
            this.renderHighlight(),
            this.renderCanDropIndicator(),
            waitingForMetadata ? null : React.createElement(linkLayer_1.LinkLayer, { view: view, links: [this.temporaryLink] })));
    };
    EditLayer.prototype.renderHighlight = function () {
        var _a = this.state, targetElement = _a.targetElement, canLinkFrom = _a.canLinkFrom, canDropOnElement = _a.canDropOnElement, waitingForMetadata = _a.waitingForMetadata;
        if (!targetElement) {
            return null;
        }
        var _b = geometry_1.boundsOf(targetElement), x = _b.x, y = _b.y, width = _b.width, height = _b.height;
        if (canLinkFrom === undefined || canDropOnElement === undefined || waitingForMetadata) {
            return (React.createElement("g", { transform: "translate(" + x + "," + y + ")" },
                React.createElement("rect", { width: width, height: height, fill: 'white', fillOpacity: 0.5 }),
                React.createElement(spinner_1.Spinner, { size: 30, position: { x: width / 2, y: height / 2 } })));
        }
        var stroke = (canLinkFrom && canDropOnElement) ? '#5cb85c' : '#c9302c';
        return (React.createElement("rect", { x: x, y: y, width: width, height: height, fill: 'transparent', stroke: stroke, strokeWidth: 3 }));
    };
    EditLayer.prototype.renderCanDropIndicator = function () {
        var _a = this.state, targetElement = _a.targetElement, canLinkFrom = _a.canLinkFrom, canDropOnCanvas = _a.canDropOnCanvas, waitingForMetadata = _a.waitingForMetadata;
        if (targetElement) {
            return null;
        }
        var _b = this.temporaryElement.position, x = _b.x, y = _b.y;
        var indicator;
        if (canLinkFrom === undefined || canDropOnCanvas === undefined) {
            indicator = React.createElement(spinner_1.Spinner, { size: 1.2, position: { x: 0.5, y: -0.5 } });
        }
        else if (canLinkFrom && canDropOnCanvas) {
            indicator = React.createElement("path", { d: 'M0.5,0 L0.5,-1 M0,-0.5 L1,-0.5', strokeWidth: 0.2, stroke: '#5cb85c' });
        }
        else {
            indicator = (React.createElement("g", null,
                React.createElement("circle", { cx: '0.5', cy: '-0.5', r: '0.5', fill: 'none', strokeWidth: 0.2, stroke: '#c9302c' }),
                React.createElement("path", { d: 'M0.5,0 L0.5,-1', strokeWidth: 0.2, stroke: '#c9302c', transform: 'rotate(-45 0.5 -0.5)' })));
        }
        return (React.createElement("g", { transform: "translate(" + x + " " + y + ")scale(40)" },
            React.createElement("rect", { x: -0.5, y: -0.5, width: 1, height: 1, fill: 'rgba(0, 0, 0, 0.1)', rx: 0.25, ry: 0.25 }),
            waitingForMetadata
                ? React.createElement(spinner_1.Spinner, { size: 0.8 })
                : React.createElement("g", { transform: "translate(" + 0.5 + ", -" + 0.5 + ")scale(" + 0.25 + ")" }, indicator)));
    };
    return EditLayer;
}(React.Component));
exports.EditLayer = EditLayer;
function centerElementAtPoint(element, point) {
    element.setPosition({
        x: point.x - element.size.width / 2,
        y: point.y - element.size.height / 2,
    });
}

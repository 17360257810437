"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var react_dom_1 = require("react-dom");
var d3_color_1 = require("d3-color");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var react_1 = require("../viewUtils/react");
var keyedObserver_1 = require("../viewUtils/keyedObserver");
var commands_1 = require("./commands");
var view_1 = require("./view");
// tslint:disable:no-bitwise
var RedrawFlags;
(function (RedrawFlags) {
    RedrawFlags[RedrawFlags["None"] = 0] = "None";
    RedrawFlags[RedrawFlags["Render"] = 1] = "Render";
    RedrawFlags[RedrawFlags["RecomputeTemplate"] = 3] = "RecomputeTemplate";
    RedrawFlags[RedrawFlags["RecomputeBlurred"] = 5] = "RecomputeBlurred";
})(RedrawFlags || (RedrawFlags = {}));
var ElementLayer = /** @class */ (function (_super) {
    tslib_1.__extends(ElementLayer, _super);
    function ElementLayer(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.listener = new events_1.EventObserver();
        _this.redrawBatch = {
            requests: new Map(),
            forAll: RedrawFlags.None,
        };
        _this.delatedRedraw = new async_1.Debouncer();
        _this.sizeRequests = new Map();
        _this.delayedUpdateSizes = new async_1.Debouncer();
        _this.onMount = function (layer) {
            _this.layer = layer;
        };
        _this.requestRedraw = function (element, request) {
            // tslint:disable:no-bitwise
            var flagsWithForAll = _this.redrawBatch.forAll | request;
            if (flagsWithForAll === _this.redrawBatch.forAll) {
                // forAll flags already include the request
                return;
            }
            var existing = _this.redrawBatch.requests.get(element.id);
            _this.redrawBatch.requests.set(element.id, existing | request);
            _this.delatedRedraw.call(_this.redrawElements);
            // tslint:enable:no-bitwise
        };
        _this.redrawElements = function () {
            var props = _this.props;
            _this.setState(function (state) { return ({
                elementStates: applyRedrawRequests(props.view, props.group, _this.redrawBatch, state.elementStates)
            }); });
        };
        _this.requestSizeUpdate = function (element, node) {
            _this.sizeRequests.set(element.id, { element: element, node: node });
            _this.delayedUpdateSizes.call(_this.recomputeQueuedSizes);
        };
        _this.recomputeQueuedSizes = function () {
            var batch = _this.sizeRequests;
            _this.sizeRequests = new Map();
            batch.forEach(function (_a) {
                var element = _a.element, node = _a.node;
                var clientWidth = node.clientWidth, clientHeight = node.clientHeight;
                element.setSize({ width: clientWidth, height: clientHeight });
            });
        };
        var _a = _this.props, view = _a.view, group = _a.group;
        _this.state = {
            elementStates: applyRedrawRequests(view, group, _this.redrawBatch, new Map())
        };
        return _this;
    }
    ElementLayer.prototype.render = function () {
        var _this = this;
        var _a = this.props, view = _a.view, style = _a.style;
        var elementStates = this.state.elementStates;
        var elementsToRender = [];
        for (var _i = 0, _b = view.model.elements; _i < _b.length; _i++) {
            var id = _b[_i].id;
            var state = elementStates.get(id);
            if (state) {
                elementsToRender.push(state);
            }
        }
        return React.createElement("div", { className: 'ontodia-element-layer', ref: this.onMount, style: style }, elementsToRender.map(function (state) {
            var overlayElement = (React.createElement(OverlayedElement, { key: state.element.id, state: state, view: view, onInvalidate: _this.requestRedraw, onResize: _this.requestSizeUpdate }));
            var elementDecorator = view._decorateElement(state.element);
            if (elementDecorator) {
                return (React.createElement("div", { key: state.element.id },
                    overlayElement,
                    elementDecorator));
            }
            return overlayElement;
        }));
    };
    ElementLayer.prototype.componentDidMount = function () {
        var _this = this;
        var view = this.props.view;
        this.listener.listen(view.model.events, 'changeCells', function (e) {
            if (e.updateAll) {
                _this.requestRedrawAll(RedrawFlags.None);
            }
            else {
                if (e.changedElement) {
                    _this.requestRedraw(e.changedElement, RedrawFlags.None);
                }
            }
        });
        this.listener.listen(view.events, 'changeLanguage', function () {
            _this.requestRedrawAll(RedrawFlags.RecomputeTemplate);
        });
        this.listener.listen(view.events, 'changeHighlight', function () {
            _this.requestRedrawAll(RedrawFlags.RecomputeBlurred);
        });
        this.listener.listen(view.model.events, 'elementEvent', function (_a) {
            var data = _a.data;
            var invalidatesTemplate = data.changeData || data.changeExpanded || data.changeElementState;
            if (invalidatesTemplate) {
                _this.requestRedraw(invalidatesTemplate.source, RedrawFlags.RecomputeTemplate);
            }
            var invalidatesRender = data.changePosition || data.requestedRedraw;
            if (invalidatesRender) {
                _this.requestRedraw(invalidatesRender.source, RedrawFlags.Render);
            }
        });
        this.listener.listen(view.events, 'syncUpdate', function (_a) {
            var layer = _a.layer;
            if (layer === view_1.RenderingLayer.Element) {
                _this.delatedRedraw.runSynchronously();
            }
            else if (layer === view_1.RenderingLayer.ElementSize) {
                _this.delayedUpdateSizes.runSynchronously();
            }
        });
    };
    ElementLayer.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (this.props.group !== nextProps.group) {
            this.setState(function (state) { return ({
                elementStates: applyRedrawRequests(nextProps.view, nextProps.group, _this.redrawBatch, state.elementStates)
            }); });
        }
    };
    ElementLayer.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.delatedRedraw.dispose();
        this.delayedUpdateSizes.dispose();
    };
    ElementLayer.prototype.requestRedrawAll = function (request) {
        // tslint:disable-next-line:no-bitwise
        this.redrawBatch.forAll |= request;
        this.delatedRedraw.call(this.redrawElements);
    };
    return ElementLayer;
}(React.Component));
exports.ElementLayer = ElementLayer;
function applyRedrawRequests(view, targetGroup, batch, previous) {
    var computed = new Map();
    for (var _i = 0, _a = view.model.elements; _i < _a.length; _i++) {
        var element = _a[_i];
        if (element.group !== targetGroup) {
            continue;
        }
        var elementId = element.id;
        if (previous.has(elementId)) {
            var state = previous.get(elementId);
            // tslint:disable:no-bitwise
            var request = (batch.requests.get(elementId) || RedrawFlags.None) | batch.forAll;
            if (request & RedrawFlags.Render) {
                state = {
                    element: element,
                    templateProps: (request & RedrawFlags.RecomputeTemplate) === RedrawFlags.RecomputeTemplate
                        ? computeTemplateProps(state.element, view) : state.templateProps,
                    blurred: (request & RedrawFlags.RecomputeBlurred) === RedrawFlags.RecomputeBlurred
                        ? computeIsBlurred(state.element, view) : state.blurred,
                };
            }
            computed.set(elementId, state);
            batch.requests.delete(elementId);
            // tslint:enable:no-bitwise
        }
        else {
            computed.set(element.id, {
                element: element,
                templateProps: computeTemplateProps(element, view),
                blurred: computeIsBlurred(element, view),
            });
        }
    }
    batch.forAll = RedrawFlags.None;
    return computed;
}
exports.ElementContextTypes = {
    ontodiaElement: react_1.PropTypes.anything,
};
var OverlayedElement = /** @class */ (function (_super) {
    tslib_1.__extends(OverlayedElement, _super);
    function OverlayedElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listener = new events_1.EventObserver();
        _this.disposed = false;
        _this.rerenderTemplate = function () {
            if (_this.disposed) {
                return;
            }
            _this.props.onInvalidate(_this.props.state.element, RedrawFlags.RecomputeTemplate);
        };
        _this.onMount = function (node) {
            if (!node) {
                return;
            }
            var _a = _this.props, state = _a.state, onResize = _a.onResize;
            onResize(state.element, node);
        };
        _this.onLoadOrErrorEvent = function () {
            var _a = _this.props, state = _a.state, onResize = _a.onResize;
            onResize(state.element, react_dom_1.findDOMNode(_this));
        };
        _this.onClick = function (e) {
            if (e.target instanceof HTMLElement && e.target.localName === 'a') {
                var anchor = e.target;
                var _a = _this.props, view = _a.view, state = _a.state;
                var clickIntent = e.target.getAttribute('data-iri-click-intent') === view_1.IriClickIntent.OpenEntityIri ?
                    view_1.IriClickIntent.OpenEntityIri : view_1.IriClickIntent.OpenOtherIri;
                view.onIriClick(decodeURI(anchor.href), state.element, clickIntent, e);
            }
        };
        _this.onDoubleClick = function (e) {
            e.preventDefault();
            e.stopPropagation();
            var _a = _this.props, view = _a.view, element = _a.state.element;
            view.model.history.execute(commands_1.setElementExpanded(element, !element.isExpanded));
        };
        return _this;
    }
    OverlayedElement.prototype.getChildContext = function () {
        var ontodiaElement = {
            element: this.props.state.element,
        };
        return { ontodiaElement: ontodiaElement };
    };
    OverlayedElement.prototype.render = function () {
        var _a = this.props.state, element = _a.element, blurred = _a.blurred;
        if (element.temporary) {
            return React.createElement("div", null);
        }
        var _b = element.position, _c = _b.x, x = _c === void 0 ? 0 : _c, _d = _b.y, y = _d === void 0 ? 0 : _d;
        var transform = "translate(" + x + "px," + y + "px)";
        // const angle = model.get('angle') || 0;
        // if (angle) { transform += `rotate(${angle}deg)`; }
        var className = ("ontodia-overlayed-element " + (blurred ? 'ontodia-overlayed-element--blurred' : ''));
        return React.createElement("div", { className: className, "data-element-id": element.id, style: { position: 'absolute', transform: transform }, tabIndex: 0, ref: this.onMount, 
            // resize element when child image loaded
            onLoad: this.onLoadOrErrorEvent, onError: this.onLoadOrErrorEvent, onClick: this.onClick, onDoubleClick: this.onDoubleClick },
            React.createElement(TemplatedElement, tslib_1.__assign({}, this.props)));
    };
    OverlayedElement.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, state = _a.state, view = _a.view;
        this.listener.listen(state.element.events, 'requestedFocus', function () {
            var element = react_dom_1.findDOMNode(_this);
            if (element) {
                element.focus();
            }
        });
        this.typesObserver = keyedObserver_1.observeElementTypes(view.model, 'changeLabel', this.rerenderTemplate);
        this.propertiesObserver = keyedObserver_1.observeProperties(view.model, 'changeLabel', this.rerenderTemplate);
        this.observeTypes();
    };
    OverlayedElement.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.typesObserver.stopListening();
        this.propertiesObserver.stopListening();
        this.disposed = true;
    };
    OverlayedElement.prototype.shouldComponentUpdate = function (nextProps) {
        return this.props.state !== nextProps.state;
    };
    OverlayedElement.prototype.componentDidUpdate = function () {
        this.observeTypes();
        this.props.onResize(this.props.state.element, react_dom_1.findDOMNode(this));
    };
    OverlayedElement.prototype.observeTypes = function () {
        var element = this.props.state.element;
        this.typesObserver.observe(element.data.types);
        this.propertiesObserver.observe(Object.keys(element.data.properties));
    };
    OverlayedElement.childContextTypes = exports.ElementContextTypes;
    return OverlayedElement;
}(React.Component));
var TemplatedElement = /** @class */ (function (_super) {
    tslib_1.__extends(TemplatedElement, _super);
    function TemplatedElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TemplatedElement.prototype.render = function () {
        var _a = this.props, state = _a.state, view = _a.view;
        var element = state.element, templateProps = state.templateProps;
        var templateClass = view.getElementTemplate(element.data.types);
        this.cachedTemplateClass = templateClass;
        this.cachedTemplateProps = templateProps;
        return React.createElement(templateClass, templateProps);
    };
    TemplatedElement.prototype.shouldComponentUpdate = function (nextProps) {
        var templateClass = nextProps.view.getElementTemplate(nextProps.state.element.data.types);
        return !(this.cachedTemplateClass === templateClass &&
            this.cachedTemplateProps === nextProps.state.templateProps);
    };
    return TemplatedElement;
}(React.Component));
function computeTemplateProps(model, view) {
    var types = model.data.types.length > 0
        ? view.getElementTypeString(model.data) : 'Thing';
    var label = view.formatLabel(model.data.label.values, model.iri);
    var _a = computeStyleFor(model, view), color = _a.color, icon = _a.icon;
    var propsAsList = computePropertyTable(model, view);
    return {
        elementId: model.id,
        data: model.data,
        iri: model.iri,
        types: types,
        label: label,
        color: color,
        iconUrl: icon,
        imgUrl: model.data.image,
        isExpanded: model.isExpanded,
        props: model.data.properties,
        propsAsList: propsAsList,
    };
}
function computePropertyTable(model, view) {
    if (!model.data.properties) {
        return [];
    }
    var propertyIris = Object.keys(model.data.properties);
    var propTable = propertyIris.map(function (key) {
        var property = view.model.createProperty(key);
        var name = view.formatLabel(property.label, key);
        return {
            id: key,
            name: name,
            property: model.data.properties[key],
        };
    });
    propTable.sort(function (a, b) {
        var aLabel = (a.name || a.id).toLowerCase();
        var bLabel = (b.name || b.id).toLowerCase();
        return aLabel.localeCompare(bLabel);
    });
    return propTable;
}
function computeStyleFor(model, view) {
    var _a = view.getTypeStyle(model.data.types), _b = _a.color, h = _b.h, c = _b.c, l = _b.l, icon = _a.icon;
    return {
        icon: icon,
        color: d3_color_1.hcl(h, c, l).toString(),
    };
}
function computeIsBlurred(element, view) {
    return view.highlighter && !view.highlighter(element);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schema_1 = require("../data/schema");
exports.LINK_SHOW_IRI = {
    renderLink: function (link) { return ({
        properties: [{
                position: 0.5,
                attrs: {
                    text: {
                        text: [{
                                value: link.typeId,
                                language: '',
                            }],
                        fill: 'gray',
                        'font-size': 12,
                        'font-weight': 'lighter',
                    },
                },
            }],
    }); },
};
var LINK_SUB_CLASS_OF = {
    markerTarget: {
        fill: '#f8a485',
        stroke: '#cf8e76',
    },
    renderLink: function () { return ({
        connection: {
            stroke: '#f8a485',
            'stroke-width': 2,
        },
    }); },
};
var LINK_DOMAIN = {
    markerTarget: {
        fill: '#34c7f3',
        stroke: '#38b5db',
    },
    renderLink: function () { return ({
        connection: {
            stroke: '#34c7f3',
            'stroke-width': 2,
        },
    }); },
};
var LINK_RANGE = {
    markerTarget: {
        fill: '#34c7f3',
        stroke: '#38b5db',
    },
    renderLink: function () { return ({
        connection: {
            stroke: '#34c7f3',
            'stroke-width': 2,
        },
    }); },
};
var LINK_TYPE_OF = {
    markerTarget: {
        fill: '#8cd965',
        stroke: '#5b9a3b',
    },
    renderLink: function () { return ({
        connection: {
            stroke: '#8cd965',
            'stroke-width': 2,
        },
    }); },
};
exports.DefaultLinkTemplateBundle = function (type) {
    if (type === 'http://www.w3.org/2000/01/rdf-schema#subClassOf') {
        return LINK_SUB_CLASS_OF;
    }
    else if (type === 'http://www.w3.org/2000/01/rdf-schema#domain') {
        return LINK_DOMAIN;
    }
    else if (type === 'http://www.w3.org/2000/01/rdf-schema#range') {
        return LINK_RANGE;
    }
    else if (type === 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type') {
        return LINK_TYPE_OF;
    }
    else if (type === schema_1.PLACEHOLDER_LINK_TYPE) {
        return { markerTarget: { fill: 'none' } };
    }
    else {
        return undefined;
    }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
function htmlToSvg(htmlView, blackList, mockImages) {
    blackList = blackList || [];
    var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    var defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    var primitives = breakIntoPrimitives(htmlView);
    for (var _i = 0, primitives_1 = primitives; _i < primitives_1.length; _i++) {
        var primitive = primitives_1[_i];
        var id = primitive.getAttribute('id');
        if (!id) {
            g.appendChild(primitive);
        }
        else {
            primitive.removeAttribute('id');
            var clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
            clipPath.setAttribute('id', id);
            clipPath.appendChild(primitive);
            defs.appendChild(clipPath);
        }
    }
    g.appendChild(defs);
    return g;
    function breakIntoPrimitives(domElement) {
        if (domElement instanceof Element) {
            if (domElement instanceof HTMLImageElement) {
                return !mockImages ? processImage(domElement) : processElement(domElement);
            }
            else if (domElement instanceof HTMLElement) {
                return processElement(domElement);
            }
        }
        else if (domElement instanceof Text) {
            return processText(domElement);
        }
        return [];
    }
    function processImage(htmlElement) {
        var image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        // Process current node
        var style = window.getComputedStyle(htmlElement);
        var offsetTop = getOffsetTop(htmlElement);
        var offsetLeft = getOffsetLeft(htmlElement);
        var width = style.width;
        var height = style.height;
        // path.setAttribute('className', htmlElement.className);
        image.setAttribute('stroke', style.borderColor);
        image.setAttribute('stroke-width', style.borderWidth);
        image.setAttribute('x', "" + offsetLeft);
        image.setAttribute('y', "" + offsetTop);
        image.setAttribute('width', "" + width);
        image.setAttribute('height', "" + height);
        image.setAttribute('href', htmlElement.getAttribute('src'));
        htmlElement.removeAttribute('crossorigin');
        return [image];
    }
    function processElement(htmlElement) {
        var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        var parts = [];
        // Process children
        var contentSize = { width: 0, height: 0 };
        forEachNode(htmlElement.childNodes, function (child) {
            var nestedParts = breakIntoPrimitives(child);
            if (nestedParts.length > 0) {
                for (var _i = 0, nestedParts_1 = nestedParts; _i < nestedParts_1.length; _i++) {
                    var p = nestedParts_1[_i];
                    var bbox = getElementBoundingBox(p);
                    contentSize.width = Math.max(contentSize.width, bbox.x + bbox.width);
                    contentSize.height = Math.max(contentSize.height, bbox.y + bbox.height);
                }
                parts = parts.concat(nestedParts);
            }
        });
        // Process current node
        var style = window.getComputedStyle(htmlElement);
        var y = getOffsetTop(htmlElement);
        var x = getOffsetLeft(htmlElement);
        var _a = htmlElement.getBoundingClientRect(), width = _a.width, height = _a.height;
        // const width = !style.width.endsWith('px') ? contentSize.width + 'px' : style.width;
        // const height = !style.width.endsWith('px') ? contentSize.height + 'px' : style.height;
        var radius = getBorderRadius(style);
        var borderColor = getBorderColor(style);
        var borderWidth = getBorderWidth(style);
        // path.setAttribute('className', htmlElement.className);
        path.setAttribute('fill', style.backgroundColor);
        path.setAttribute('stroke', borderColor);
        path.setAttribute('stroke-width', borderWidth + "px");
        // path.setAttribute('transform', `translate(${x}, ${y})`);
        path.setAttribute('x', "" + x);
        path.setAttribute('y', "" + y);
        path.setAttribute('width', "" + width);
        path.setAttribute('height', "" + height);
        path.setAttribute('d', generatePathData(x, y, width, height, radius[0], radius[1], radius[2], radius[3]));
        // create mask if it's needed
        if (style.overflow === 'hidden' || style.overflow === 'auto') {
            var rect = createRect(x, y, width, height);
            var id_1 = rect.getAttribute('id');
            parts.forEach(function (p) {
                if (!p.getAttribute('clip-path') && !p.getAttribute('id') && !(p instanceof SVGTextElement)) {
                    p.setAttribute('clip-path', "url(" + ('#' + id_1) + ")");
                }
            });
            parts.push(rect);
        }
        parts = [path].concat(parts);
        return parts;
    }
    function processText(textElement) {
        var parts = [];
        var textContent = textElement.textContent.trim();
        if (textContent.length === 0 || !(textElement.parentNode instanceof Element)) {
            return [];
        }
        var parent = textElement.parentNode;
        var style = window.getComputedStyle(parent);
        var x = getOffsetLeft(parent);
        var y = getOffsetTop(parent);
        var fill = style.color;
        var textSize = getTextSize(textContent, style.font);
        var prefferedWidth = stringToNumber(style.width);
        var lines = breakInLines(textContent, textSize, stringToNumber(style.width));
        lines.forEach(function (line, index) {
            var text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            text.setAttribute('transform', "translate(" + x + ", " + (y + index * textSize.height) + ")");
            text.setAttribute('x', "" + 0);
            text.setAttribute('y', "" + 0);
            // text.setAttribute('x', `${x}`);
            // text.setAttribute('y', `${y + index * textSize.height}`);
            text.setAttribute('width', "" + (prefferedWidth || textSize.width));
            text.setAttribute('height', "" + textSize.height);
            text.setAttribute('fill', fill);
            text.setAttribute('font', style.font);
            // It's not working for IE11 (our main goal)
            // text.setAttribute('alignment-baseline', 'hanging');
            text.setAttribute('dy', "" + textSize.height);
            text.textContent = line;
            parts.push(text);
        });
        return parts;
    }
}
exports.htmlToSvg = htmlToSvg;
function getBorderRadius(style) {
    var radius = Math.round(stringToNumber(style.borderRadius.split(' ')[0])) || 0;
    return [
        Math.round(stringToNumber(style.borderTopLeftRadius)) || radius,
        Math.round(stringToNumber(style.borderTopRightRadius)) || radius,
        Math.round(stringToNumber(style.borderBottomRightRadius)) || radius,
        Math.round(stringToNumber(style.borderBottomLeftRadius)) || radius,
    ];
}
function getBorderColor(style) {
    var color = style.borderColor.split(' ')[0];
    return style.borderTopColor ||
        style.borderRightColor ||
        style.borderBottomColor ||
        style.borderLeftColor || color || null;
}
function getBorderWidth(style) {
    // const width = Math.round(stringToNumber(style.borderWidth.split(' ')[0]) || 0);
    var top = Math.round(stringToNumber(style.borderTopWidth) || 0);
    var right = Math.round(stringToNumber(style.borderRightWidth) || 0);
    var bottom = Math.round(stringToNumber(style.borderBottomWidth) || 0);
    var left = Math.round(stringToNumber(style.borderLeftWidth) || 0);
    return Math.min(top, right, bottom, left);
}
function createRect(x, y, width, height) {
    var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('id', lodash_1.uniqueId('clip-rect-'));
    rect.setAttribute('x', "" + x);
    rect.setAttribute('y', "" + y);
    rect.setAttribute('width', width + "px");
    rect.setAttribute('height', height + "px");
    return rect;
}
function getOffsetTop(element) {
    if (element.className !== 'ontodia-overlayed-element') {
        return element.offsetTop +
            element.clientTop +
            (element.offsetParent && element.offsetParent instanceof HTMLElement ?
                getOffsetTop(element.offsetParent) : 0);
    }
    else {
        return 0;
    }
}
function getOffsetLeft(element) {
    if (element.className !== 'ontodia-overlayed-element') {
        return element.offsetLeft +
            element.clientLeft +
            (element.offsetParent && element.offsetParent instanceof HTMLElement ?
                getOffsetLeft(element.offsetParent) : 0);
    }
    else {
        return 0;
    }
}
function breakInLines(text, textSize, prefferedWidth, elipsis) {
    var width = textSize.width, height = textSize.height;
    var lines = [];
    // breakText
    if (!prefferedWidth || width <= prefferedWidth) {
        lines.push(text);
    }
    else {
        var oneCharLength = width / text.length;
        var charsInLine = Math.floor(prefferedWidth / oneCharLength);
        if (elipsis) {
            var ELIPSIS_LENGTH = 3;
            lines.push(text.substring(0, charsInLine - ELIPSIS_LENGTH) + '...');
        }
        else {
            var c = 0;
            var line = '';
            for (var i = 0; i < text.length; i++, c++) {
                if (c >= charsInLine) {
                    lines.push(line);
                    line = '';
                    c = 0;
                }
                line += text[i];
            }
        }
    }
    return lines;
}
function getElementBoundingBox(element) {
    return {
        x: stringToNumber(element.getAttribute('x')),
        y: stringToNumber(element.getAttribute('y')),
        width: stringToNumber(element.getAttribute('width')),
        height: stringToNumber(element.getAttribute('height')),
    };
}
/**
 * @param {string} text
 * @param {string} font
 */
function getTextSize(text, font) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = font;
    var fontSizeString = context.font.split(' ').filter(function (term) { return term.indexOf('px') !== -1; })[0];
    var fontSize = +fontSizeString.substring(0, fontSizeString.length - 2);
    var metrics = context.measureText(text);
    return {
        width: metrics.width,
        height: fontSize,
    };
}
function forEachNode(nodeList, callback) {
    for (var i = 0; i < nodeList.length; i++) {
        callback(nodeList[i]);
    }
}
function stringToNumber(value) {
    var newValue = +cutExtension(value);
    return newValue;
}
var postfixes = ['px', 'vh', 'vw'];
function cutExtension(value) {
    var lowerCaseValue = value.toLowerCase();
    if (postfixes.filter(function (postfix) { return lowerCaseValue.indexOf(postfix) !== -1; }).length > 0) {
        return value.substring(0, value.length - 2);
    }
    else {
        return value;
    }
}
/*
 * Generate a path's data attribute
 *
 * @param {Number} width Width of the rectangular shape
 * @param {Number} height Height of the rectangular shape
 * @param {Number} tr Top border radius of the rectangular shape
 * @param {Number} br Bottom border radius of the rectangular shape
 * @return {String} a path's data attribute value
 */
function generatePathData(x, y, width, height, tl, tr, br, bl) {
    var data = [];
    // start point in top-middle of the rectangle
    data.push('M' + (width / 2 + x) + ',' + y);
    // next we go to the right
    data.push('H' + (x + width - tr));
    if (tr > 0) {
        // now we draw the arc in the top-right corner
        data.push('A' + arcParameter(tr, tr, 0, 0, 1, x + width, y + tr));
    }
    // next we go down
    data.push('V' + (y + height - br));
    if (br > 0) {
        // now we draw the arc in the lower-right corner
        data.push('A' + arcParameter(br, br, 0, 0, 1, x + width - br, y + height));
    }
    // now we go to the left
    data.push('H' + (x + bl));
    if (bl > 0) {
        // now we draw the arc in the lower-left corner
        data.push('A' + arcParameter(bl, bl, 0, 0, 1, x, y + height - bl));
    }
    // next we go up
    data.push('V' + (y + tl));
    if (tl > 0) {
        // now we draw the arc in the top-left corner
        data.push('A' + arcParameter(tl, tl, 0, 0, 1, x + tl, y));
    }
    // and we close the path
    data.push('Z');
    return data.join(' ');
}
function arcParameter(rx, ry, xAxisRotation, largeArcFlag, sweepFlag, x, y) {
    return [
        rx, ',',
        ry, ' ',
        xAxisRotation, ' ',
        largeArcFlag, ',',
        sweepFlag, ' ',
        x, ',',
        y,
    ].join('');
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var elements_1 = require("../diagram/elements");
var events_1 = require("../viewUtils/events");
var progressBar_1 = require("../widgets/progressBar");
var listElementView_1 = require("./listElementView");
var searchResults_1 = require("./searchResults");
var workspaceContext_1 = require("../workspace/workspaceContext");
var MAX_LINK_COUNT = 100;
var ALL_RELATED_ELEMENTS_LINK = new elements_1.FatLinkType({
    id: 'allRelatedElements',
    label: [{ value: 'All', language: '' }],
});
var ConnectionsMenu = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionsMenu, _super);
    function ConnectionsMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handler = new events_1.EventObserver();
        _this.linkTypesListener = new events_1.EventObserver();
        _this.loadingState = progressBar_1.ProgressState.none;
        _this.updateAll = function () { return _this.forceUpdate(); };
        _this.addSelectedElements = function (selectedObjects) {
            var _a = _this.props, onClose = _a.onClose, onAddElements = _a.onAddElements;
            var addedElementsIris = selectedObjects.map(function (item) { return item.model.id; });
            var linkType = _this.linkDataChunk ? _this.linkDataChunk.link : undefined;
            var hasChosenLinkType = _this.linkDataChunk && linkType !== ALL_RELATED_ELEMENTS_LINK;
            onAddElements(addedElementsIris, hasChosenLinkType ? linkType : undefined);
            onClose();
        };
        _this.onExpandLink = function (linkDataChunk) {
            var alreadyLoaded = (_this.objects &&
                _this.linkDataChunk &&
                _this.linkDataChunk.link === linkDataChunk.link &&
                _this.linkDataChunk.direction === linkDataChunk.direction);
            if (!alreadyLoaded) {
                _this.loadObjects(linkDataChunk);
            }
            _this.updateAll();
            _this.context.ontodiaWorkspace.triggerWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.connectionsExpandLink);
        };
        _this.onMoveToFilter = function (linkDataChunk) {
            var _a = _this.props, view = _a.view, target = _a.target;
            var link = linkDataChunk.link, direction = linkDataChunk.direction;
            if (link === ALL_RELATED_ELEMENTS_LINK) {
                target.addToFilter();
            }
            else {
                var selectedElement = view.model.getElement(target.id);
                selectedElement.addToFilter(link, direction);
            }
        };
        return _this;
    }
    ConnectionsMenu.prototype.componentDidMount = function () {
        var view = this.props.view;
        this.handler.listen(view.events, 'changeLanguage', this.updateAll);
        this.loadLinks();
    };
    ConnectionsMenu.prototype.componentWillUnmount = function () {
        this.handler.stopListening();
        this.linkTypesListener.stopListening();
    };
    ConnectionsMenu.prototype.resubscribeOnLinkTypeEvents = function (linkTypesOfElement) {
        this.linkTypesListener.stopListening();
        for (var _i = 0, linkTypesOfElement_1 = linkTypesOfElement; _i < linkTypesOfElement_1.length; _i++) {
            var linkType = linkTypesOfElement_1[_i];
            this.linkTypesListener.listen(linkType.events, 'changeLabel', this.updateAll);
            this.linkTypesListener.listen(linkType.events, 'changeVisibility', this.updateAll);
        }
    };
    ConnectionsMenu.prototype.loadLinks = function () {
        var _this = this;
        var _a = this.props, view = _a.view, editor = _a.editor, target = _a.target;
        this.loadingState = progressBar_1.ProgressState.loading;
        this.links = [];
        this.countMap = {};
        editor.model.dataProvider.linkTypesOf({ elementId: target.iri })
            .then(function (linkTypes) {
            _this.loadingState = progressBar_1.ProgressState.completed;
            var countMap = {};
            var links = [];
            for (var _i = 0, linkTypes_1 = linkTypes; _i < linkTypes_1.length; _i++) {
                var _a = linkTypes_1[_i], linkTypeId = _a.id, inCount = _a.inCount, outCount = _a.outCount;
                countMap[linkTypeId] = { inCount: inCount, outCount: outCount };
                links.push(view.model.createLinkType(linkTypeId));
            }
            countMap[ALL_RELATED_ELEMENTS_LINK.id] = Object.keys(countMap)
                .map(function (key) { return countMap[key]; })
                .reduce(function (a, b) {
                return { inCount: a.inCount + b.inCount, outCount: a.outCount + b.outCount };
            }, { inCount: 0, outCount: 0 });
            _this.countMap = countMap;
            _this.links = links;
            _this.resubscribeOnLinkTypeEvents(_this.links);
            _this.updateAll();
            _this.context.ontodiaWorkspace.triggerWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.connectionsLoadLinks);
        })
            .catch(function (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
            _this.loadingState = progressBar_1.ProgressState.error;
            _this.updateAll();
        });
        this.updateAll();
    };
    ConnectionsMenu.prototype.loadObjects = function (linkDataChunk) {
        var _this = this;
        var _a = this.props, view = _a.view, editor = _a.editor, target = _a.target;
        var link = linkDataChunk.link, direction = linkDataChunk.direction;
        var offset = (linkDataChunk.offset || 0);
        this.loadingState = progressBar_1.ProgressState.loading;
        this.linkDataChunk = linkDataChunk;
        this.objects = [];
        editor.model.dataProvider.linkElements({
            elementId: target.iri,
            linkId: (link === ALL_RELATED_ELEMENTS_LINK ? undefined : link.id),
            limit: offset + MAX_LINK_COUNT,
            offset: offset,
            direction: direction,
        }).then(function (elements) {
            _this.loadingState = progressBar_1.ProgressState.completed;
            _this.objects = Object.keys(elements).map(function (iri) { return ({
                model: elements[iri],
                presentOnDiagram: view.model.elements.findIndex(function (element) { return element.iri === iri && element.group === undefined; }) >= 0,
            }); });
            _this.updateAll();
            _this.context.ontodiaWorkspace.triggerWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.connectionsLoadElements);
        }).catch(function (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
            _this.loadingState = progressBar_1.ProgressState.error;
            _this.updateAll();
        });
    };
    ConnectionsMenu.prototype.render = function () {
        var connectionsData = {
            links: this.links || [],
            countMap: this.countMap || {},
        };
        var objectsData = null;
        if (this.linkDataChunk && this.objects) {
            objectsData = {
                linkDataChunk: this.linkDataChunk,
                objects: this.objects,
            };
        }
        var _a = this.props, view = _a.view, target = _a.target, suggestProperties = _a.suggestProperties;
        return (React.createElement(ConnectionsMenuMarkup, { target: target, connectionsData: connectionsData, objectsData: objectsData, state: this.loadingState, view: view, onExpandLink: this.onExpandLink, onPressAddSelected: this.addSelectedElements, onMoveToFilter: this.onMoveToFilter, propertySuggestionCall: suggestProperties }));
    };
    ConnectionsMenu.contextTypes = workspaceContext_1.WorkspaceContextTypes;
    return ConnectionsMenu;
}(React.Component));
exports.ConnectionsMenu = ConnectionsMenu;
var ConnectionsMenuMarkup = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionsMenuMarkup, _super);
    function ConnectionsMenuMarkup(props) {
        var _this = _super.call(this, props) || this;
        _this.onChangeFilter = function (e) {
            var filterKey = e.currentTarget.value;
            _this.setState({ filterKey: filterKey });
        };
        _this.getTitle = function () {
            if (_this.props.objectsData && _this.state.panel === 'objects') {
                return 'Objects';
            }
            else if (_this.props.connectionsData && _this.state.panel === 'connections') {
                return 'Connections';
            }
            return 'Error';
        };
        _this.onExpandLink = function (linkDataChunk) {
            _this.setState({ filterKey: '', panel: 'objects' });
            _this.props.onExpandLink(linkDataChunk);
        };
        _this.onCollapseLink = function () {
            _this.setState({ filterKey: '', panel: 'connections' });
        };
        _this.getBreadCrumbs = function () {
            if (_this.props.objectsData && _this.state.panel === 'objects') {
                var _a = _this.props.objectsData.linkDataChunk, link = _a.link, direction = _a.direction;
                var localizedText = _this.props.view.formatLabel(link.label, link.id);
                return React.createElement("span", { className: 'ontodia-connections-menu_bread-crumbs' },
                    React.createElement("a", { className: 'ontodia-connections-menu__link', onClick: _this.onCollapseLink }, "Connections"),
                    '\u00A0' + '/' + '\u00A0',
                    localizedText,
                    " ",
                    direction ? "(" + direction + ")" : null);
            }
            else {
                return null;
            }
        };
        _this.getBody = function () {
            if (_this.props.state === 'error') {
                return React.createElement("label", { className: 'ontodia-label ontodia-connections-menu__error' }, "Error");
            }
            else if (_this.props.objectsData && _this.state.panel === 'objects') {
                return React.createElement(ObjectsPanel, { data: _this.props.objectsData, onMoveToFilter: _this.props.onMoveToFilter, view: _this.props.view, filterKey: _this.state.filterKey, loading: _this.props.state === progressBar_1.ProgressState.loading, onPressAddSelected: _this.props.onPressAddSelected });
            }
            else if (_this.props.connectionsData && _this.state.panel === 'connections') {
                if (_this.props.state === progressBar_1.ProgressState.loading) {
                    return React.createElement("label", { className: 'ontodia-label ontodia-connections-menu__loading' }, "Loading...");
                }
                return React.createElement(ConnectionsList, { id: _this.props.target.id, data: _this.props.connectionsData, view: _this.props.view, filterKey: _this.state.filterKey, onExpandLink: _this.onExpandLink, onMoveToFilter: _this.props.onMoveToFilter, propertySuggestionCall: _this.props.propertySuggestionCall, sortMode: _this.state.sortMode });
            }
            else {
                return React.createElement("div", null);
            }
        };
        _this.onSortChange = function (e) {
            var value = e.target.value;
            if (_this.state.sortMode === value) {
                return;
            }
            _this.setState({ sortMode: value });
        };
        _this.renderSortSwitch = function (id, icon, title) {
            return (React.createElement("div", null,
                React.createElement("input", { type: 'radio', name: 'sort', id: id, value: id, className: 'ontodia-connections-menu__sort-switch', onChange: _this.onSortChange, checked: _this.state.sortMode === id }),
                React.createElement("label", { htmlFor: id, className: 'ontodia-connections-menu__sort-switch-label', title: title },
                    React.createElement("i", { className: "fa " + icon }))));
        };
        _this.renderSortSwitches = function () {
            if (_this.state.panel !== 'connections' || !_this.props.propertySuggestionCall) {
                return null;
            }
            return (React.createElement("div", { className: 'ontodia-connections-menu_search-line-sort-switches' },
                _this.renderSortSwitch('alphabet', 'fa-sort-alpha-asc', 'Sort alphabetically'),
                _this.renderSortSwitch('smart', 'fa-lightbulb-o', 'Smart sort')));
        };
        _this.state = {
            filterKey: '',
            panel: 'connections',
            sortMode: 'alphabet',
        };
        return _this;
    }
    ConnectionsMenuMarkup.prototype.render = function () {
        return (React.createElement("div", { className: 'ontodia-connections-menu' },
            React.createElement("label", { className: 'ontodia-label ontodia-connections-menu__title-label' }, this.getTitle()),
            this.getBreadCrumbs(),
            React.createElement("div", { className: 'ontodia-connections-menu_search-line' },
                React.createElement("input", { type: 'text', className: 'search-input ontodia-form-control ontodia-connections-menu__search-line-input', value: this.state.filterKey, onChange: this.onChangeFilter, placeholder: 'Search for...' }),
                this.renderSortSwitches()),
            React.createElement(progressBar_1.ProgressBar, { state: this.props.state, height: 10 }),
            this.getBody()));
    };
    return ConnectionsMenuMarkup;
}(React.Component));
var ConnectionsList = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionsList, _super);
    function ConnectionsList(props) {
        var _this = _super.call(this, props) || this;
        _this.updateScores = function (props) {
            if (props.propertySuggestionCall && (props.filterKey || props.sortMode === 'smart')) {
                var id = props.id, data = props.data, view = props.view, filterKey = props.filterKey;
                var lang = view.getLanguage();
                var token = filterKey.trim();
                var properties = data.links.map(function (l) { return l.id; });
                props.propertySuggestionCall({ elementId: id, token: token, properties: properties, lang: lang }).then(function (scores) {
                    return _this.setState({ scores: scores });
                });
            }
        };
        _this.compareLinks = function (a, b) {
            var view = _this.props.view;
            var aText = view.formatLabel(a.label, a.id);
            var bText = view.formatLabel(b.label, b.id);
            return aText.localeCompare(bText);
        };
        _this.compareLinksByWeight = function (a, b) {
            var view = _this.props.view;
            var aText = view.formatLabel(a.label, a.id);
            var bText = view.formatLabel(b.label, b.id);
            var aWeight = _this.state.scores[a.id] ? _this.state.scores[a.id].score : 0;
            var bWeight = _this.state.scores[b.id] ? _this.state.scores[b.id].score : 0;
            return (aWeight > bWeight ? -1 :
                aWeight < bWeight ? 1 :
                    aText.localeCompare(bText));
        };
        _this.getLinks = function () {
            var _a = _this.props, view = _a.view, data = _a.data, filterKey = _a.filterKey;
            return (data.links || []).filter(function (link) {
                var text = view.formatLabel(link.label, link.id).toLowerCase();
                return !filterKey || text.indexOf(filterKey.toLowerCase()) >= 0;
            })
                .sort(_this.compareLinks);
        };
        _this.getProbableLinks = function () {
            var isSmartMode = _this.isSmartMode();
            return (_this.props.data.links || []).filter(function (link) {
                return _this.state.scores[link.id] && (_this.state.scores[link.id].score > 0 || isSmartMode);
            }).sort(_this.compareLinksByWeight);
        };
        _this.getViews = function (links, notSure) {
            var view = _this.props.view;
            var countMap = _this.props.data.countMap || {};
            var views = [];
            var addView = function (link, direction) {
                var count = direction === 'in'
                    ? countMap[link.id].inCount
                    : countMap[link.id].outCount;
                if (count === 0) {
                    return;
                }
                var postfix = notSure ? '-probable' : '';
                views.push(React.createElement(LinkInPopupMenu, { key: direction + "-" + link.id + "-" + postfix, link: link, onExpandLink: _this.props.onExpandLink, view: view, count: count, direction: direction, filterKey: notSure ? '' : _this.props.filterKey, onMoveToFilter: _this.props.onMoveToFilter, probability: (_this.state.scores[link.id] && notSure ? _this.state.scores[link.id].score : 0) }));
            };
            for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
                var link = links_1[_i];
                addView(link, 'in');
                addView(link, 'out');
            }
            return views;
        };
        _this.state = { scores: {} };
        _this.updateScores(props);
        return _this;
    }
    ConnectionsList.prototype.componentWillReceiveProps = function (newProps) {
        this.updateScores(newProps);
    };
    ConnectionsList.prototype.isSmartMode = function () {
        return this.props.sortMode === 'smart' && !this.props.filterKey;
    };
    ConnectionsList.prototype.render = function () {
        var view = this.props.view;
        var isSmartMode = this.isSmartMode();
        var links = isSmartMode ? [] : this.getLinks();
        var probableLinks = this.getProbableLinks().filter(function (link) { return links.indexOf(link) === -1; });
        var views = this.getViews(links);
        var probableViews = this.getViews(probableLinks, true);
        var viewList;
        if (views.length === 0 && probableViews.length === 0) {
            viewList = React.createElement("label", { className: 'ontodia-label ontodia-connections-menu_links-list__empty' }, "List empty");
        }
        else {
            viewList = views;
            if (views.length > 1 || (isSmartMode && probableViews.length > 1)) {
                var countMap = this.props.data.countMap || {};
                var allRelatedElements = countMap[ALL_RELATED_ELEMENTS_LINK.id];
                viewList = [
                    React.createElement(LinkInPopupMenu, { key: ALL_RELATED_ELEMENTS_LINK.id, link: ALL_RELATED_ELEMENTS_LINK, onExpandLink: this.props.onExpandLink, view: view, count: allRelatedElements.inCount + allRelatedElements.outCount, onMoveToFilter: this.props.onMoveToFilter }),
                    React.createElement("hr", { key: 'ontodia-hr-line', className: 'ontodia-connections-menu_links-list__hr' }),
                ].concat(viewList);
            }
        }
        var probablePart = null;
        if (probableViews.length !== 0) {
            probablePart = [
                isSmartMode ? null : (React.createElement("li", { key: 'probable-links' },
                    React.createElement("span", { className: 'ontodia-label' }, "Probably, you're looking for.."))),
                probableViews,
            ];
        }
        return React.createElement("ul", { className: 'ontodia-connections-menu_links-list '
                + (views.length === 0 && probableViews.length === 0 ? 'ocm_links-list-empty' : '') },
            viewList,
            probablePart);
    };
    return ConnectionsList;
}(React.Component));
var LinkInPopupMenu = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInPopupMenu, _super);
    function LinkInPopupMenu(props) {
        var _this = _super.call(this, props) || this;
        _this.onExpandLink = function (expectedCount, direction) {
            _this.props.onExpandLink({
                link: _this.props.link,
                direction: direction,
                expectedCount: expectedCount,
            });
        };
        _this.onMoveToFilter = function (evt) {
            evt.stopPropagation();
            _this.props.onMoveToFilter({
                link: _this.props.link,
                direction: _this.props.direction,
                expectedCount: _this.props.count,
            });
        };
        return _this;
    }
    LinkInPopupMenu.prototype.render = function () {
        var _this = this;
        var _a = this.props, view = _a.view, link = _a.link;
        var fullText = view.formatLabel(link.label, link.id);
        var probability = Math.round(this.props.probability * 100);
        var textLine = listElementView_1.highlightSubstring(fullText + (probability > 0 ? ' (' + probability + '%)' : ''), this.props.filterKey);
        var directionName = this.props.direction === 'in' ? 'source' :
            this.props.direction === 'out' ? 'target' :
                'all connected';
        return (React.createElement("li", { "data-linktypeid": this.props.link.id, className: 'link-in-popup-menu', title: directionName + " of \"" + fullText + "\" " + view.formatIri(link.id), onClick: function () { return _this.onExpandLink(_this.props.count, _this.props.direction); } },
            this.props.direction === 'in' || this.props.direction === 'out' ?
                React.createElement("div", { className: 'link-in-popup-menu_direction' },
                    this.props.direction === 'in' && React.createElement("div", { className: 'link-in-popup-menu_direction__in-direction' }),
                    this.props.direction === 'out' && React.createElement("div", { className: 'link-in-popup-menu_direction__out-direction' }))
                : null,
            React.createElement("div", { className: 'link-in-popup-menu__link-title' }, textLine),
            React.createElement("span", { className: 'ontodia-badge link-in-popup-menu__count' }, this.props.count <= MAX_LINK_COUNT ? this.props.count : '100+'),
            React.createElement("div", { className: 'link-in-popup-menu__filter-button', onClick: this.onMoveToFilter, title: 'Set as filter in the Instances panel' }),
            React.createElement("div", { className: 'link-in-popup-menu__navigate-button', title: "Navigate to " + directionName + " \"" + fullText + "\" elements" })));
    };
    return LinkInPopupMenu;
}(React.Component));
var ObjectsPanel = /** @class */ (function (_super) {
    tslib_1.__extends(ObjectsPanel, _super);
    function ObjectsPanel(props) {
        var _this = _super.call(this, props) || this;
        _this.onSelectAll = function () {
            var objects = _this.props.data.objects;
            if (objects.length === 0) {
                return;
            }
            var allSelected = allNonPresentedAreSelected(objects, _this.state.selection);
            var newSelection = allSelected ? new Set() : selectNonPresented(_this.props.data.objects);
            _this.updateSelection(newSelection);
        };
        _this.updateSelection = function (newSelection) {
            _this.setState({ selection: newSelection });
        };
        _this.counter = function (activeObjCount) {
            var countString = activeObjCount + "\u00A0of\u00A0" + _this.props.data.objects.length;
            var wrongNodes = Math.min(MAX_LINK_COUNT, _this.props.data.linkDataChunk.expectedCount) - _this.props.data.objects.length;
            var wrongNodesString = Math.abs(wrongNodes) > MAX_LINK_COUNT ?
                MAX_LINK_COUNT + "+" : Math.abs(wrongNodes).toString();
            var wrongNodesCount = wrongNodes === 0 ? '' : (wrongNodes < 0 ?
                "\u00A0(" + wrongNodesString + ")" : "\u00A0(" + wrongNodesString + ")");
            var wrongNodesTitle = wrongNodes === 0 ? '' : (wrongNodes > 0 ? 'Unavailable nodes' : 'Extra nodes');
            return React.createElement("div", { className: 'ontodia-label ontodia-connections-menu_objects-panel_bottom-panel__count-label' },
                React.createElement("span", null, countString),
                React.createElement("span", { className: 'ontodia-connections-menu_objects-panel_bottom-panel__extra-elements', title: wrongNodesTitle }, wrongNodesCount));
        };
        _this.state = { selection: new Set() };
        return _this;
    }
    ObjectsPanel.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.data.objects.length < nextProps.data.objects.length) {
            this.setState({ selection: new Set() });
        }
    };
    ObjectsPanel.prototype.getFilteredObjects = function () {
        var _this = this;
        if (!this.props.filterKey) {
            return this.props.data.objects;
        }
        var filterKey = this.props.filterKey.toLowerCase();
        return this.props.data.objects.filter(function (element) {
            var text = _this.props.view.formatLabel(element.model.label.values, element.model.id).toLowerCase();
            return text && text.indexOf(filterKey) >= 0;
        });
    };
    ObjectsPanel.prototype.getItems = function (list) {
        var added = {};
        var result = [];
        for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
            var obj = list_1[_i];
            if (added[obj.model.id]) {
                continue;
            }
            added[obj.model.id] = true;
            result.push(obj.model);
        }
        return result;
    };
    ObjectsPanel.prototype.render = function () {
        var _this = this;
        var _a = this.props, onPressAddSelected = _a.onPressAddSelected, filterKey = _a.filterKey;
        var selection = this.state.selection;
        var objects = this.getFilteredObjects();
        var isAllSelected = allNonPresentedAreSelected(objects, selection);
        var nonPresented = objects.filter(function (el) { return !el.presentOnDiagram; });
        var active = nonPresented.filter(function (el) { return selection.has(el.model.id); });
        return React.createElement("div", { className: 'ontodia-connections-menu_objects-panel' },
            React.createElement("div", { className: 'ontodia-connections-menu_objects-panel__select-all' },
                React.createElement("label", null,
                    React.createElement("input", { type: 'checkbox', checked: isAllSelected && nonPresented.length > 0, onChange: this.onSelectAll, disabled: nonPresented.length === 0 }),
                    "Select All")),
            (this.props.loading ?
                React.createElement("label", { className: 'ontodia-label ontodia-connections-menu__loading-objects' }, "Loading...") :
                objects.length === 0 ?
                    React.createElement("label", { className: 'ontodia-label ontodia-connections-menu__loading-objects' }, "No available nodes") :
                    React.createElement("div", { className: 'ontodia-connections-menu_objects-panel_objects-list' },
                        React.createElement(searchResults_1.SearchResults, { view: this.props.view, items: this.getItems(objects), selection: this.state.selection, onSelectionChanged: this.updateSelection, highlightText: filterKey }),
                        this.props.data.linkDataChunk.expectedCount > MAX_LINK_COUNT ? (React.createElement("div", { className: 'ontodia-connections-menu__move-to-filter', onClick: function () { return _this.props.onMoveToFilter(_this.props.data.linkDataChunk); } }, "The list was truncated, for more data click here to use the filter panel")) : null)),
            React.createElement("div", { className: 'ontodia-connections-menu_objects-panel_bottom-panel' },
                this.counter(active.length),
                React.createElement("button", { className: 'ontodia-btn ontodia-btn-primary pull-right ' +
                        'ontodia-connections-menu_objects-panel_bottom-panel__add-button', disabled: this.props.loading || nonPresented.length === 0, onClick: function () { return onPressAddSelected(active.length > 0 ? active : nonPresented); } }, active.length > 0 ? 'Add selected' : 'Add all')));
    };
    return ObjectsPanel;
}(React.Component));
function selectNonPresented(objects) {
    var selection = new Set();
    for (var _i = 0, objects_1 = objects; _i < objects_1.length; _i++) {
        var object = objects_1[_i];
        if (object.presentOnDiagram) {
            continue;
        }
        selection.add(object.model.id);
    }
    return selection;
}
function allNonPresentedAreSelected(objects, selection) {
    var allSelected = true;
    for (var _i = 0, objects_2 = objects; _i < objects_2.length; _i++) {
        var object = objects_2[_i];
        if (object.presentOnDiagram) {
            continue;
        }
        allSelected = allSelected && selection.has(object.model.id);
    }
    return allSelected;
}

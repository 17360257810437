"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var collections_1 = require("../viewUtils/collections");
var events_1 = require("../viewUtils/events");
var Graph = /** @class */ (function () {
    function Graph() {
        var _this = this;
        this.source = new events_1.EventSource();
        this.events = this.source;
        this.elements = new collections_1.OrderedMap();
        this.links = new collections_1.OrderedMap();
        this.classesById = new Map();
        this.propertiesById = new Map();
        this.linkTypes = new Map();
        this.onElementEvent = function (data, key) {
            _this.source.trigger('elementEvent', { key: key, data: data });
        };
        this.onLinkEvent = function (data, key) {
            _this.source.trigger('linkEvent', { key: key, data: data });
        };
        this.onLinkTypeEvent = function (data, key) {
            _this.source.trigger('linkTypeEvent', { key: key, data: data });
        };
        this.onClassEvent = function (data, key) {
            _this.source.trigger('classEvent', { key: key, data: data });
        };
    }
    Graph.prototype.getElements = function () { return this.elements.items; };
    Graph.prototype.getLinks = function () { return this.links.items; };
    Graph.prototype.getLink = function (linkId) {
        return this.links.get(linkId);
    };
    Graph.prototype.findLink = function (linkTypeId, sourceId, targetId) {
        var source = this.getElement(sourceId);
        if (!source) {
            return undefined;
        }
        var index = findLinkIndex(source.links, linkTypeId, sourceId, targetId);
        return index >= 0 ? source.links[index] : undefined;
    };
    Graph.prototype.sourceOf = function (link) {
        return this.getElement(link.sourceId);
    };
    Graph.prototype.targetOf = function (link) {
        return this.getElement(link.targetId);
    };
    Graph.prototype.reorderElements = function (compare) {
        this.elements.reorder(compare);
    };
    Graph.prototype.getElement = function (elementId) {
        return this.elements.get(elementId);
    };
    Graph.prototype.addElement = function (element) {
        if (this.getElement(element.id)) {
            throw new Error("Element '" + element.id + "' already exists.");
        }
        element.events.onAny(this.onElementEvent);
        this.elements.push(element.id, element);
        this.source.trigger('changeCells', { updateAll: false, changedElement: element });
    };
    Graph.prototype.removeElement = function (elementId) {
        var element = this.elements.get(elementId);
        if (element) {
            var options = { silent: true };
            // clone links to prevent modifications during iteration
            var changedLinks = tslib_1.__spreadArrays(element.links);
            for (var _i = 0, changedLinks_1 = changedLinks; _i < changedLinks_1.length; _i++) {
                var link = changedLinks_1[_i];
                this.removeLink(link.id, options);
            }
            this.elements.delete(elementId);
            element.events.offAny(this.onElementEvent);
            this.source.trigger('changeCells', { updateAll: false, changedElement: element, changedLinks: changedLinks });
        }
    };
    Graph.prototype.addLink = function (link) {
        if (this.getLink(link.id)) {
            throw new Error("Link '" + link.id + "' already exists.");
        }
        var linkType = this.getLinkType(link.typeId);
        if (!linkType) {
            throw new Error("Link type '" + link.typeId + "' not found.");
        }
        this.registerLink(link);
    };
    Graph.prototype.registerLink = function (link) {
        this.sourceOf(link).links.push(link);
        if (link.sourceId !== link.targetId) {
            this.targetOf(link).links.push(link);
        }
        link.events.onAny(this.onLinkEvent);
        this.links.push(link.id, link);
        this.source.trigger('changeCells', { updateAll: false, changedLinks: [link] });
    };
    Graph.prototype.removeLink = function (linkId, options) {
        var link = this.links.delete(linkId);
        if (link) {
            var typeId = link.typeId, sourceId = link.sourceId, targetId = link.targetId;
            link.events.offAny(this.onLinkEvent);
            this.removeLinkReferences(typeId, sourceId, targetId);
            if (!(options && options.silent)) {
                this.source.trigger('changeCells', { updateAll: false, changedLinks: [link] });
            }
        }
    };
    Graph.prototype.removeLinkReferences = function (linkTypeId, sourceId, targetId) {
        var source = this.getElement(sourceId);
        if (source) {
            removeLinkFrom(source.links, linkTypeId, sourceId, targetId);
        }
        var target = this.getElement(targetId);
        if (target) {
            removeLinkFrom(target.links, linkTypeId, sourceId, targetId);
        }
    };
    Graph.prototype.getLinkTypes = function () {
        var result = [];
        this.linkTypes.forEach(function (type) { return result.push(type); });
        return result;
    };
    Graph.prototype.getLinkType = function (linkTypeId) {
        return this.linkTypes.get(linkTypeId);
    };
    Graph.prototype.addLinkType = function (linkType) {
        if (this.getLinkType(linkType.id)) {
            throw new Error("Link type '" + linkType.id + "' already exists.");
        }
        linkType.setIndex(Graph.nextLinkTypeIndex++);
        linkType.events.onAny(this.onLinkTypeEvent);
        this.linkTypes.set(linkType.id, linkType);
    };
    Graph.prototype.getProperty = function (propertyId) {
        return this.propertiesById.get(propertyId);
    };
    Graph.prototype.addProperty = function (property) {
        if (this.getProperty(property.id)) {
            throw new Error("Property '" + property.id + "' already exists.");
        }
        this.propertiesById.set(property.id, property);
    };
    Graph.prototype.getClass = function (classId) {
        return this.classesById.get(classId);
    };
    Graph.prototype.getClasses = function () {
        var classes = [];
        this.classesById.forEach(function (richClass) { return classes.push(richClass); });
        return classes;
    };
    Graph.prototype.addClass = function (classModel) {
        if (this.getClass(classModel.id)) {
            throw new Error("Class '" + classModel.id + "' already exists.");
        }
        classModel.events.onAny(this.onClassEvent);
        this.classesById.set(classModel.id, classModel);
    };
    Graph.nextLinkTypeIndex = 0;
    return Graph;
}());
exports.Graph = Graph;
function removeLinkFrom(links, linkTypeId, sourceId, targetId) {
    if (!links) {
        return;
    }
    while (true) {
        var index = findLinkIndex(links, linkTypeId, sourceId, targetId);
        if (index < 0) {
            break;
        }
        links.splice(index, 1);
    }
}
function findLinkIndex(haystack, linkTypeId, sourceId, targetId) {
    for (var i = 0; i < haystack.length; i++) {
        var link = haystack[i];
        if (link.sourceId === sourceId &&
            link.targetId === targetId &&
            link.typeId === linkTypeId) {
            return i;
        }
    }
    return -1;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var KeyedObserver = /** @class */ (function () {
    function KeyedObserver(subscribe) {
        this.subscribe = subscribe;
        this.observedKeys = new Map();
    }
    KeyedObserver.prototype.observe = function (keys) {
        if (keys.length === 0 && this.observedKeys.size === 0) {
            return;
        }
        var newObservedKeys = new Map();
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            if (newObservedKeys.has(key)) {
                continue;
            }
            var unsubscribe = this.observedKeys.get(key);
            if (!unsubscribe) {
                unsubscribe = this.subscribe(key);
            }
            newObservedKeys.set(key, unsubscribe);
        }
        this.observedKeys.forEach(function (unsubscribe, key) {
            if (!newObservedKeys.has(key)) {
                unsubscribe();
            }
        });
        this.observedKeys = newObservedKeys;
    };
    KeyedObserver.prototype.stopListening = function () {
        this.observe([]);
    };
    return KeyedObserver;
}());
exports.KeyedObserver = KeyedObserver;
function observeElementTypes(model, event, listener) {
    return new KeyedObserver(function (key) {
        var type = model.getClass(key);
        if (type) {
            type.events.on(event, listener);
            return function () { return type.events.off(event, listener); };
        }
        return undefined;
    });
}
exports.observeElementTypes = observeElementTypes;
function observeProperties(model, event, listener) {
    return new KeyedObserver(function (key) {
        var property = model.getProperty(key);
        if (property) {
            property.events.on(event, listener);
            return function () { return property.events.off(event, listener); };
        }
        return undefined;
    });
}
exports.observeProperties = observeProperties;
function observeLinkTypes(model, event, listener) {
    return new KeyedObserver(function (key) {
        var type = model.createLinkType(key);
        if (type) {
            type.events.on(event, listener);
            return function () { return type.events.off(event, listener); };
        }
        return undefined;
    });
}
exports.observeLinkTypes = observeLinkTypes;

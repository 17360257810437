"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var polyfills_1 = require("../viewUtils/polyfills");
var react_1 = require("../viewUtils/react");
var toSvg_1 = require("../viewUtils/toSvg");
var commands_1 = require("./commands");
var elements_1 = require("./elements");
var geometry_1 = require("./geometry");
var view_1 = require("./view");
var paper_1 = require("./paper");
exports.PaperAreaContextTypes = {
    ontodiaPaperArea: react_1.PropTypes.anything,
};
var CLASS_NAME = 'ontodia-paper-area';
var DEFAULT_ANIMATION_DURATION = 500;
var LEFT_MOUSE_BUTTON = 0;
var PaperArea = /** @class */ (function (_super) {
    tslib_1.__extends(PaperArea, _super);
    function PaperArea(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.listener = new events_1.EventObserver();
        _this.source = new events_1.EventSource();
        _this.events = _this.source;
        _this.widgets = {};
        _this.pageSize = { x: 1500, y: 800 };
        _this.cssAnimations = 0;
        _this.delayedPaperAdjust = new async_1.Debouncer();
        _this.onOuterMount = function (outer) { _this.outer = outer; };
        _this.onAreaMount = function (area) { _this.area = area; };
        _this.onWidgetsMouseDown = function (e) {
            // prevent PaperArea from generating click on a blank area
            e.stopPropagation();
        };
        _this.adjustPaper = function (callback) {
            var _a = _this.area, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
            var adjusted = tslib_1.__assign(tslib_1.__assign({}, _this.computeAdjustedBox()), { paddingX: Math.ceil(clientWidth), paddingY: Math.ceil(clientHeight) });
            var previous = _this.state;
            var samePaperProps = (adjusted.paperWidth === previous.paperWidth &&
                adjusted.paperHeight === previous.paperHeight &&
                adjusted.originX === previous.originX &&
                adjusted.originY === previous.originY &&
                adjusted.paddingX === previous.paddingX &&
                adjusted.paddingY === previous.paddingY);
            if (!samePaperProps) {
                _this.scrollBeforeUpdate = {
                    left: _this.area.scrollLeft,
                    top: _this.area.scrollTop,
                };
                _this.setState(adjusted, callback);
            }
            else if (callback) {
                callback();
            }
        };
        _this.onPaperPointerDown = function (e, cell) {
            if (_this.movingState) {
                return;
            }
            var restore = commands_1.RestoreGeometry.capture(_this.props.view.model);
            var batch = _this.props.view.model.history.startBatch(restore.title);
            if (cell && e.button === LEFT_MOUSE_BUTTON) {
                if (cell instanceof elements_1.Element) {
                    e.preventDefault();
                    _this.startMoving(e, cell);
                    _this.listenToPointerMove(e, cell, batch, restore);
                }
                else {
                    e.preventDefault();
                    _this.listenToPointerMove(e, cell, batch, restore);
                }
            }
            else {
                e.preventDefault();
                _this.listenToPointerMove(e, undefined, batch, restore);
            }
        };
        _this.onAreaPointerDown = function (e) {
            if (e.target === _this.area) {
                _this.onPaperPointerDown(e, undefined);
            }
        };
        _this.onPointerMove = function (e) {
            if (!_this.movingState || _this.scrollBeforeUpdate) {
                return;
            }
            var _a = _this.movingState, origin = _a.origin, target = _a.target, panning = _a.panning;
            var pageOffsetX = e.pageX - origin.pageX;
            var pageOffsetY = e.pageY - origin.pageY;
            if (Math.abs(pageOffsetX) >= 1 && Math.abs(pageOffsetY) >= 1) {
                _this.movingState.pointerMoved = true;
            }
            if (typeof target === 'undefined') {
                if (panning) {
                    _this.area.scrollLeft = _this.panningScrollOrigin.scrollLeft - pageOffsetX;
                    _this.area.scrollTop = _this.panningScrollOrigin.scrollTop - pageOffsetY;
                }
                _this.source.trigger('pointerMove', { source: _this, sourceEvent: e, target: target, panning: panning });
            }
            else if (target instanceof elements_1.Element) {
                var _b = _this.pageToPaperCoords(e.pageX, e.pageY), x = _b.x, y = _b.y;
                var _c = _this.movingElementOrigin, pointerX = _c.pointerX, pointerY = _c.pointerY, elementX = _c.elementX, elementY = _c.elementY;
                target.setPosition({
                    x: elementX + x - pointerX,
                    y: elementY + y - pointerY,
                });
                _this.source.trigger('pointerMove', { source: _this, sourceEvent: e, target: target, panning: panning });
                _this.props.view.performSyncUpdate();
            }
            else if (target instanceof elements_1.Link) {
                var location_1 = _this.pageToPaperCoords(e.pageX, e.pageY);
                var linkVertex = _this.generateLinkVertex(target, location_1);
                linkVertex.createAt(location_1);
                _this.movingState.target = linkVertex;
            }
            else if (target instanceof elements_1.LinkVertex) {
                var location_2 = _this.pageToPaperCoords(e.pageX, e.pageY);
                target.moveTo(location_2);
                _this.source.trigger('pointerMove', { source: _this, sourceEvent: e, target: target, panning: panning });
                _this.props.view.performSyncUpdate();
            }
        };
        _this.stopListeningToPointerMove = function (e) {
            var movingState = _this.movingState;
            _this.movingState = undefined;
            if (movingState) {
                document.removeEventListener('mousemove', _this.onPointerMove);
                document.removeEventListener('mouseup', _this.stopListeningToPointerMove);
            }
            if (e && movingState) {
                var pointerMoved = movingState.pointerMoved, target = movingState.target, batch = movingState.batch, restoreGeometry = movingState.restoreGeometry;
                _this.source.trigger('pointerUp', {
                    source: _this,
                    sourceEvent: e,
                    target: target,
                    panning: movingState.panning,
                    triggerAsClick: !pointerMoved,
                });
                var restore = restoreGeometry.filterOutUnchanged();
                if (restore.hasChanges()) {
                    batch.history.registerToUndo(restore);
                }
                batch.store();
            }
        };
        _this.onWheel = function (e) {
            if (_this.shouldStartZooming(e)) {
                e.preventDefault();
                var delta = Math.max(-1, Math.min(1, e.deltaY || e.deltaX));
                var pivot = _this.pageToPaperCoords(e.pageX, e.pageY);
                _this.zoomBy(-delta * 0.1, { pivot: pivot });
            }
        };
        _this.onDragOver = function (e) {
            // Necessary. Allows us to drop.
            if (e.preventDefault) {
                e.preventDefault();
            }
            e.dataTransfer.dropEffect = 'move';
            var _a = clientCoordsFor(_this.area, e), x = _a.x, y = _a.y;
            return false;
        };
        _this.onDragDrop = function (e) {
            if (_this.props.onDragDrop) {
                var _a = clientCoordsFor(_this.area, e), x = _a.x, y = _a.y;
                var paperPosition = _this.clientToPaperCoords(x, y);
                _this.props.onDragDrop(e, paperPosition);
            }
        };
        _this.onScroll = function () {
            _this.source.trigger('scroll', { source: _this });
        };
        _this.applyViewportState = function (targetState) {
            var scale = targetState.scale.x;
            var paperCenter = targetState.center;
            _this.setState({ scale: scale }, function () {
                var _a = _this.state, originX = _a.originX, originY = _a.originY, paddingX = _a.paddingX, paddingY = _a.paddingY;
                var scrollCenterX = (paperCenter.x + originX) * scale;
                var scrollCenterY = (paperCenter.y + originY) * scale;
                var _b = _this.area, clientWidth = _b.clientWidth, clientHeight = _b.clientHeight;
                _this.area.scrollLeft = scrollCenterX - clientWidth / 2 + paddingX;
                _this.area.scrollTop = scrollCenterY - clientHeight / 2 + paddingY;
                if (_this.props.onZoom) {
                    _this.props.onZoom(scale, scale);
                }
            });
        };
        _this.state = {
            paperWidth: _this.pageSize.x,
            paperHeight: _this.pageSize.y,
            originX: 0,
            originY: 0,
            scale: 1,
            paddingX: 0,
            paddingY: 0,
            renderedWidgets: [],
        };
        return _this;
    }
    Object.defineProperty(PaperArea.prototype, "zoomOptions", {
        get: function () {
            var _a = this.props.zoomOptions || {}, _b = _a.min, min = _b === void 0 ? 0.2 : _b, _c = _a.max, max = _c === void 0 ? 2 : _c, _d = _a.step, step = _d === void 0 ? 0.1 : _d, _e = _a.maxFit, maxFit = _e === void 0 ? 1 : _e, _f = _a.fitPadding, fitPadding = _f === void 0 ? 20 : _f, _g = _a.requireCtrl, requireCtrl = _g === void 0 ? true : _g;
            return { min: min, max: max, step: step, maxFit: maxFit, fitPadding: fitPadding, requireCtrl: requireCtrl };
        },
        enumerable: true,
        configurable: true
    });
    PaperArea.prototype.getChildContext = function () {
        var view = this.props.view;
        var ontodiaPaperArea = { paperArea: this, view: view };
        return { ontodiaPaperArea: ontodiaPaperArea };
    };
    PaperArea.prototype.render = function () {
        var _a = this.props, view = _a.view, watermarkSvg = _a.watermarkSvg, watermarkUrl = _a.watermarkUrl;
        var _b = this.state, paperWidth = _b.paperWidth, paperHeight = _b.paperHeight, originX = _b.originX, originY = _b.originY, scale = _b.scale, paddingX = _b.paddingX, paddingY = _b.paddingY, renderedWidgets = _b.renderedWidgets;
        var paperTransform = {
            width: paperWidth, height: paperHeight,
            originX: originX, originY: originY, scale: scale, paddingX: paddingX, paddingY: paddingY,
        };
        var widgetProps = { paperArea: this, paperTransform: paperTransform };
        var areaClass = CLASS_NAME + "__area";
        if (this.props.hideScrollBars) {
            areaClass += " " + CLASS_NAME + "--hide-scrollbars";
        }
        var componentClass = CLASS_NAME;
        if (this.isAnimatingGraph()) {
            componentClass += " " + CLASS_NAME + "--animated";
        }
        return (React.createElement("div", { className: componentClass, ref: this.onOuterMount },
            React.createElement("div", { className: areaClass, ref: this.onAreaMount, onMouseDown: this.onAreaPointerDown },
                React.createElement(paper_1.Paper, { view: view, paperTransform: paperTransform, onPointerDown: this.onPaperPointerDown, linkLayerWidgets: React.createElement("div", { className: CLASS_NAME + "__widgets", onMouseDown: this.onWidgetsMouseDown }, renderedWidgets.filter(function (w) { return w.attachment === view_1.WidgetAttachment.OverLinks; }).map(function (widget) { return React.cloneElement(widget.element, widgetProps); })), elementLayerWidgets: React.createElement("div", { className: CLASS_NAME + "__widgets", onMouseDown: this.onWidgetsMouseDown }, renderedWidgets.filter(function (w) { return w.attachment === view_1.WidgetAttachment.OverElements; }).map(function (widget) { return React.cloneElement(widget.element, widgetProps); })) }),
                watermarkSvg ? (React.createElement("a", { href: watermarkUrl, target: '_blank', rel: 'noopener' },
                    React.createElement("img", { className: CLASS_NAME + "__watermark", src: watermarkSvg, draggable: false }))) : null),
            renderedWidgets.filter(function (w) { return w.attachment === view_1.WidgetAttachment.Viewport; }).map(function (widget) {
                return React.cloneElement(widget.element, widgetProps);
            })));
    };
    PaperArea.prototype.componentDidMount = function () {
        var _this = this;
        this.adjustPaper(function () { return _this.centerTo(); });
        var view = this.props.view;
        var delayedAdjust = function () { return _this.delayedPaperAdjust.call(_this.adjustPaper); };
        this.listener.listen(view.model.events, 'changeCells', delayedAdjust);
        this.listener.listen(view.model.events, 'elementEvent', function (_a) {
            var data = _a.data;
            if (data.changePosition || data.changeSize) {
                delayedAdjust();
            }
        });
        this.listener.listen(view.model.events, 'linkEvent', function (_a) {
            var data = _a.data;
            if (data.changeVertices) {
                delayedAdjust();
            }
        });
        this.listener.listen(view.events, 'syncUpdate', function (_a) {
            var layer = _a.layer;
            if (layer !== view_1.RenderingLayer.PaperArea) {
                return;
            }
            _this.delayedPaperAdjust.runSynchronously();
        });
        this.listener.listen(view.events, 'updateWidgets', function (_a) {
            var widgets = _a.widgets;
            _this.updateWidgets(widgets);
        });
        this.area.addEventListener('dragover', this.onDragOver);
        this.area.addEventListener('drop', this.onDragDrop);
        this.area.addEventListener('scroll', this.onScroll);
        this.area.addEventListener('wheel', this.onWheel, polyfills_1.isIE11() ? false : { passive: false });
    };
    PaperArea.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.scrollBeforeUpdate) {
            var _a = this.state, scale = _a.scale, originX = _a.originX, originY = _a.originY, paddingX = _a.paddingX, paddingY = _a.paddingY;
            var scrollX_1 = (originX - prevState.originX) * scale + (paddingX - prevState.paddingX);
            var scrollY_1 = (originY - prevState.originY) * scale + (paddingY - prevState.paddingY);
            var scrollLeft = this.scrollBeforeUpdate.left + scrollX_1;
            var scrollTop = this.scrollBeforeUpdate.top + scrollY_1;
            this.area.scrollLeft = scrollLeft;
            this.area.scrollTop = scrollTop;
            this.scrollBeforeUpdate = undefined;
        }
    };
    PaperArea.prototype.componentWillUnmount = function () {
        this.stopListeningToPointerMove();
        this.listener.stopListening();
        this.area.removeEventListener('dragover', this.onDragOver);
        this.area.removeEventListener('drop', this.onDragDrop);
        this.area.removeEventListener('scroll', this.onScroll);
        this.area.removeEventListener('wheel', this.onWheel);
    };
    PaperArea.prototype.updateWidgets = function (update) {
        var _this = this;
        this.widgets = tslib_1.__assign(tslib_1.__assign({}, this.widgets), update);
        var renderedWidgets = Object.keys(this.widgets)
            .filter(function (key) { return _this.widgets[key]; })
            .map(function (key) {
            var widget = _this.widgets[key];
            var element = React.cloneElement(widget.element, { key: key });
            return tslib_1.__assign(tslib_1.__assign({}, widget), { element: element });
        });
        this.setState({ renderedWidgets: renderedWidgets });
    };
    PaperArea.prototype.pageToPaperCoords = function (pageX, pageY) {
        var _a = this.area.getBoundingClientRect(), left = _a.left, top = _a.top;
        return this.clientToPaperCoords(pageX - (left + window.pageXOffset), pageY - (top + window.pageYOffset));
    };
    PaperArea.prototype.clientToPaperCoords = function (areaClientX, areaClientY) {
        var _a = this.clientToScrollablePaneCoords(areaClientX, areaClientY), paneX = _a.x, paneY = _a.y;
        return this.scrollablePaneToPaperCoords(paneX, paneY);
    };
    PaperArea.prototype.clientToScrollablePaneCoords = function (areaClientX, areaClientY) {
        var _a = this.state, paddingX = _a.paddingX, paddingY = _a.paddingY;
        var paneX = areaClientX + this.area.scrollLeft - paddingX;
        var paneY = areaClientY + this.area.scrollTop - paddingY;
        return { x: paneX, y: paneY };
    };
    PaperArea.prototype.scrollablePaneToPaperCoords = function (paneX, paneY) {
        var _a = this.state, scale = _a.scale, paddingX = _a.paddingX, paddingY = _a.paddingY, originX = _a.originX, originY = _a.originY;
        var paperX = paneX / scale - originX;
        var paperY = paneY / scale - originY;
        return { x: paperX, y: paperY };
    };
    PaperArea.prototype.paperToScrollablePaneCoords = function (paperX, paperY) {
        var _a = this.state, scale = _a.scale, paddingX = _a.paddingX, paddingY = _a.paddingY, originX = _a.originX, originY = _a.originY;
        var paneX = (paperX + originX) * scale;
        var paneY = (paperY + originY) * scale;
        return { x: paneX, y: paneY };
    };
    /** Returns bounding box of paper content in paper coordinates. */
    PaperArea.prototype.getContentFittingBox = function () {
        var _a = this.props.view.model, elements = _a.elements, links = _a.links;
        return getContentFittingBox(elements, links);
    };
    /** Returns paper size in paper coordinates. */
    PaperArea.prototype.getPaperSize = function () {
        var _a = this.state, width = _a.paperWidth, height = _a.paperHeight, scale = _a.scale;
        return { width: width / scale, height: height / scale };
    };
    PaperArea.prototype.getAreaMetrics = function () {
        var _a = this.area, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight, offsetWidth = _a.offsetWidth, offsetHeight = _a.offsetHeight;
        return { clientWidth: clientWidth, clientHeight: clientHeight, offsetWidth: offsetWidth, offsetHeight: offsetHeight };
    };
    PaperArea.prototype.computeAdjustedBox = function () {
        // bbox in paper coordinates
        var bbox = this.getContentFittingBox();
        var bboxLeft = bbox.x;
        var bboxTop = bbox.y;
        var bboxRight = bbox.x + bbox.width;
        var bboxBottom = bbox.y + bbox.height;
        var _a = this.pageSize, gridWidth = _a.x, gridHeight = _a.y;
        // bbox in integer grid coordinates (open-closed intervals)
        var bboxGrid = {
            left: Math.floor(bboxLeft / gridWidth),
            top: Math.floor(bboxTop / gridHeight),
            right: Math.ceil(bboxRight / gridWidth),
            bottom: Math.ceil(bboxBottom / gridHeight),
        };
        // const oldOrigin = this.paper.options.origin;
        var originX = -bboxGrid.left * gridWidth;
        var originY = -bboxGrid.top * gridHeight;
        var paperWidth = Math.max(bboxGrid.right - bboxGrid.left, 1) * gridWidth;
        var paperHeight = Math.max(bboxGrid.bottom - bboxGrid.top, 1) * gridHeight;
        return { paperWidth: paperWidth, paperHeight: paperHeight, originX: originX, originY: originY };
    };
    PaperArea.prototype.shouldStartZooming = function (e) {
        return Boolean(e.ctrlKey) && Boolean(this.zoomOptions.requireCtrl) || !this.zoomOptions.requireCtrl;
    };
    PaperArea.prototype.shouldStartPanning = function (e) {
        var modifierPressed = e.ctrlKey || e.shiftKey || e.altKey;
        return e.button === LEFT_MOUSE_BUTTON && !modifierPressed;
    };
    PaperArea.prototype.startMoving = function (e, element) {
        var _a = this.pageToPaperCoords(e.pageX, e.pageY), pointerX = _a.x, pointerY = _a.y;
        var _b = element.position, elementX = _b.x, elementY = _b.y;
        this.movingElementOrigin = { pointerX: pointerX, pointerY: pointerY, elementX: elementX, elementY: elementY };
    };
    PaperArea.prototype.startPanning = function (event) {
        var _a = this.area, scrollLeft = _a.scrollLeft, scrollTop = _a.scrollTop;
        this.panningScrollOrigin = { scrollLeft: scrollLeft, scrollTop: scrollTop };
        this.clearTextSelectionInArea();
    };
    /** Clears accidental text selection in the diagram area. */
    PaperArea.prototype.clearTextSelectionInArea = function () {
        if (document.getSelection) {
            var selection = document.getSelection();
            if (selection.removeAllRanges) {
                selection.removeAllRanges();
            }
        }
    };
    PaperArea.prototype.generateLinkVertex = function (link, location) {
        var previous = link.vertices;
        var vertices = previous ? tslib_1.__spreadArrays(previous) : [];
        var model = this.props.view.model;
        var polyline = geometry_1.computePolyline(model.getElement(link.sourceId), model.getElement(link.targetId), vertices);
        var segmentIndex = geometry_1.findNearestSegmentIndex(polyline, location);
        return new elements_1.LinkVertex(link, segmentIndex);
    };
    PaperArea.prototype.listenToPointerMove = function (event, cell, batch, restoreGeometry) {
        if (this.movingState) {
            return;
        }
        var panning = cell === undefined && this.shouldStartPanning(event);
        if (panning) {
            this.startPanning(event);
        }
        var pageX = event.pageX, pageY = event.pageY;
        this.movingState = {
            origin: { pageX: pageX, pageY: pageY },
            target: cell,
            panning: panning,
            pointerMoved: false,
            batch: batch,
            restoreGeometry: restoreGeometry,
        };
        document.addEventListener('mousemove', this.onPointerMove);
        document.addEventListener('mouseup', this.stopListeningToPointerMove);
        this.source.trigger('pointerDown', {
            source: this, sourceEvent: event, target: cell, panning: panning,
        });
    };
    PaperArea.prototype.centerTo = function (paperPosition, options) {
        if (options === void 0) { options = {}; }
        var _a = this.state, paperWidth = _a.paperWidth, paperHeight = _a.paperHeight;
        var paperCenter = paperPosition || { x: paperWidth / 2, y: paperHeight / 2 };
        var viewportState = {
            center: paperCenter,
        };
        return this.setViewportState(viewportState, options);
    };
    PaperArea.prototype.centerContent = function (options) {
        if (options === void 0) { options = {}; }
        var bbox = this.getContentFittingBox();
        return this.centerTo({
            x: bbox.x + bbox.width / 2,
            y: bbox.y + bbox.height / 2,
        }, options);
    };
    PaperArea.prototype.getScale = function () {
        return this.state.scale;
    };
    PaperArea.prototype.setScale = function (value, options) {
        var scale = value;
        var _a = this.zoomOptions, min = _a.min, max = _a.max;
        scale = Math.max(scale, min);
        scale = Math.min(scale, max);
        var viewportState;
        if (options && options.pivot) {
            var _b = options.pivot, x = _b.x, y = _b.y;
            var paperCenter = this.clientToPaperCoords(this.area.clientWidth / 2, this.area.clientHeight / 2);
            var previousScale = this.state.scale;
            var scaledBy = scale / previousScale;
            viewportState = {
                center: {
                    x: x - (x - paperCenter.x) / scaledBy,
                    y: y - (y - paperCenter.y) / scaledBy,
                },
                scale: { x: scale, y: scale },
            };
        }
        else {
            viewportState = {
                scale: { x: scale, y: scale },
            };
        }
        return this.setViewportState(viewportState, options);
    };
    PaperArea.prototype.zoomBy = function (value, options) {
        return this.setScale(this.getScale() + value, options);
    };
    PaperArea.prototype.zoomIn = function (scaleOptions) {
        return this.zoomBy(this.zoomOptions.step, scaleOptions);
    };
    PaperArea.prototype.zoomOut = function (scaleOptions) {
        return this.zoomBy(-this.zoomOptions.step, scaleOptions);
    };
    PaperArea.prototype.zoomToFit = function (options) {
        if (options === void 0) { options = {}; }
        if (options.elements && options.elements.size === 0) {
            return;
        }
        if (this.props.view.model.elements.length === 0) {
            return this.centerTo();
        }
        var bbox;
        var elements;
        if (options.elements) {
            var selectionElements_1 = [];
            options.elements.forEach(function (el) { return selectionElements_1.push(el); });
            elements = selectionElements_1;
        }
        else {
            elements = this.props.view.model.elements;
        }
        bbox = getContentFittingBox(elements, []);
        return this.zoomToFitRect(bbox, options);
    };
    PaperArea.prototype.zoomToFitRect = function (bbox, options) {
        if (options === void 0) { options = {}; }
        var _a = this.area, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
        if (bbox.width === 0) {
            return;
        }
        var width = toSvg_1.fitRectKeepingAspectRatio(bbox.width, bbox.height, clientWidth, clientHeight).width;
        var scale = width / bbox.width;
        var _b = this.zoomOptions, min = _b.min, maxFit = _b.maxFit;
        scale = Math.max(scale, min);
        scale = Math.min(scale, maxFit);
        var center = {
            x: bbox.x + bbox.width / 2,
            y: bbox.y + bbox.height / 2,
        };
        var viewPortState = {
            center: center,
            scale: { x: scale, y: scale },
        };
        return this.setViewportState(viewPortState, options);
    };
    PaperArea.prototype.makeToSVGOptions = function () {
        var _this = this;
        var svg = this.area.querySelector('.ontodia-paper__canvas');
        if (!svg) {
            throw new Error('Cannot find SVG canvas to export');
        }
        return {
            model: this.props.view.model,
            paper: svg,
            contentBox: this.getContentFittingBox(),
            getOverlayedElement: function (id) { return _this.area.querySelector("[data-element-id='" + id + "']"); },
            preserveDimensions: true,
            convertImagesToDataUris: true,
            elementsToRemoveSelector: '.ontodia-link__vertex-tools',
            watermarkSvg: this.props.watermarkSvg,
        };
    };
    PaperArea.prototype.exportSVG = function () {
        return toSvg_1.toSVG(this.makeToSVGOptions());
    };
    PaperArea.prototype.exportPNG = function (options) {
        return toSvg_1.toDataURL(tslib_1.__assign(tslib_1.__assign({}, options), this.makeToSVGOptions()));
    };
    PaperArea.prototype.isAnimatingGraph = function () {
        return this.cssAnimations > 0;
    };
    /**
     * Starts animation for graph elements and links.
     *
     * @param setupChanges immediately called function to perform animatable changes on graph
     * @param duration animation duration in milliseconds (requires custom CSS to override)
     * @returns promise which resolves when this animation ends
     */
    PaperArea.prototype.animateGraph = function (setupChanges, duration) {
        var _this = this;
        this.changeGraphAnimationCount(+1);
        setupChanges();
        var timeout = typeof duration === 'number' ? duration : DEFAULT_ANIMATION_DURATION;
        return async_1.delay(timeout).then(function () { return _this.onGraphAnimationEnd(); });
    };
    PaperArea.prototype.onGraphAnimationEnd = function () {
        this.changeGraphAnimationCount(-1);
    };
    PaperArea.prototype.changeGraphAnimationCount = function (change) {
        var newValue = this.cssAnimations + change;
        if (newValue < 0) {
            return;
        }
        var previous = this.isAnimatingGraph();
        this.cssAnimations = newValue;
        var current = this.isAnimatingGraph();
        if (previous !== current) {
            this.forceUpdate();
            this.source.trigger('changeAnimatingGraph', { source: this, previous: previous });
        }
    };
    Object.defineProperty(PaperArea.prototype, "viewportState", {
        get: function () {
            var _a = this.area, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
            var _b = this.state, originX = _b.originX, originY = _b.originY, paddingX = _b.paddingX, paddingY = _b.paddingY, scale = _b.scale;
            var scrollCenterX = this.area.scrollLeft + clientWidth / 2 - paddingX;
            var scrollCenterY = this.area.scrollTop + clientHeight / 2 - paddingY;
            var paperCenter = {
                x: scrollCenterX / scale - originX,
                y: scrollCenterY / scale - originY,
            };
            return {
                center: paperCenter,
                scale: {
                    x: scale,
                    y: scale,
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    PaperArea.prototype.setViewportState = function (state, options) {
        var _this = this;
        if (this.viewportAnimation) {
            this.viewportAnimation.cancellation.abort();
        }
        var from = this.viewportState;
        var to = tslib_1.__assign(tslib_1.__assign({}, from), state);
        var animate = options && (options.animate || options.duration > 0);
        if (animate) {
            var viewportAnimation = {
                from: from, to: to, cancellation: new async_1.Cancellation(),
            };
            var durationMs = typeof options.duration === 'number'
                ? options.duration : DEFAULT_ANIMATION_DURATION;
            var awaitPromise = async_1.animateInterval(durationMs, function (progress) {
                var t = async_1.easeInOutBezier(progress);
                var computed = {
                    center: {
                        x: from.center.x + (to.center.x - from.center.x) * t,
                        y: from.center.y + (to.center.y - from.center.y) * t,
                    },
                    scale: {
                        x: from.scale.x + (to.scale.x - from.scale.x) * t,
                        y: from.scale.y + (to.scale.y - from.scale.y) * t,
                    },
                };
                _this.applyViewportState(computed);
            }, viewportAnimation.cancellation);
            this.viewportAnimation = viewportAnimation;
            return awaitPromise.then(function () {
                _this.viewportAnimation = undefined;
            });
        }
        else {
            this.applyViewportState(to);
            return Promise.resolve();
        }
    };
    PaperArea.childContextTypes = exports.PaperAreaContextTypes;
    return PaperArea;
}(React.Component));
exports.PaperArea = PaperArea;
function clientCoordsFor(container, e) {
    var target = (e.target instanceof SVGElement && e.target.ownerSVGElement !== null)
        ? e.target.ownerSVGElement : e.target;
    var targetBox = target.getBoundingClientRect();
    var containerBox = container.getBoundingClientRect();
    return {
        x: e.offsetX + (targetBox.left - containerBox.left),
        y: e.offsetY + (targetBox.top - containerBox.top),
    };
}
function getContentFittingBox(elements, links) {
    var minX = Infinity, minY = Infinity;
    var maxX = -Infinity, maxY = -Infinity;
    for (var _i = 0, elements_2 = elements; _i < elements_2.length; _i++) {
        var element = elements_2[_i];
        var _a = element.position, x = _a.x, y = _a.y;
        var size = element.size;
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + size.width);
        maxY = Math.max(maxY, y + size.height);
    }
    for (var _b = 0, links_1 = links; _b < links_1.length; _b++) {
        var link = links_1[_b];
        var vertices = link.vertices || [];
        for (var _c = 0, vertices_1 = vertices; _c < vertices_1.length; _c++) {
            var _d = vertices_1[_c], x = _d.x, y = _d.y;
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
    }
    return {
        x: Number.isFinite(minX) ? minX : 0,
        y: Number.isFinite(minY) ? minY : 0,
        width: Number.isFinite(minX) && Number.isFinite(maxX) ? (maxX - minX) : 0,
        height: Number.isFinite(minY) && Number.isFinite(maxY) ? (maxY - minY) : 0,
    };
}
exports.getContentFittingBox = getContentFittingBox;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
// context could be imported directly from NPM package, e.g.
//   import OntodiaContextV1 from 'ontodia/schema/context-v1.json';
exports.DIAGRAM_CONTEXT_URL_V1 = 'https://ontodia.org/context/v1.json';
exports.PLACEHOLDER_ELEMENT_TYPE = 'http://ontodia.org/NewEntity';
exports.PLACEHOLDER_LINK_TYPE = 'http://ontodia.org/NewLink';
var ONTODIA_ID_URL_PREFIX = 'http://ontodia.org/data/';
var GenerateID;
(function (GenerateID) {
    function forElement() { return ONTODIA_ID_URL_PREFIX + "e_" + utils_1.generate128BitID(); }
    GenerateID.forElement = forElement;
    function forLink() { return ONTODIA_ID_URL_PREFIX + "l_" + utils_1.generate128BitID(); }
    GenerateID.forLink = forLink;
})(GenerateID = exports.GenerateID || (exports.GenerateID = {}));
var TemplateProperties;
(function (TemplateProperties) {
    TemplateProperties.PinnedProperties = 'ontodia:pinnedProperties';
    TemplateProperties.CustomLabel = 'ontodia:customLabel';
})(TemplateProperties = exports.TemplateProperties || (exports.TemplateProperties = {}));

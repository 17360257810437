"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var model_1 = require("../data/model");
var CLASS_NAME = 'ontodia-edit-form';
var EditEntityForm = /** @class */ (function (_super) {
    tslib_1.__extends(EditEntityForm, _super);
    function EditEntityForm(props) {
        var _this = _super.call(this, props) || this;
        _this.renderProperty = function (key, property) {
            var view = _this.props.view;
            var richProperty = view.model.getProperty(key);
            var label = view.formatLabel(richProperty.label, key);
            var values = [];
            if (model_1.isIriProperty(property)) {
                values = property.values.map(function (_a) {
                    var value = _a.value;
                    return value;
                });
            }
            else if (model_1.isLiteralProperty(property)) {
                values = property.values.map(function (_a) {
                    var value = _a.value;
                    return value;
                });
            }
            return (React.createElement("div", { key: key, className: CLASS_NAME + "__form-row" },
                React.createElement("label", null,
                    label,
                    values.map(function (value, index) { return (React.createElement("input", { key: index, className: 'ontodia-form-control', defaultValue: value })); }))));
        };
        _this.onChangeIri = function (e) {
            var target = e.target;
            var iri = target.value;
            _this.setState(function (prevState) {
                return {
                    elementModel: tslib_1.__assign(tslib_1.__assign({}, prevState.elementModel), { id: iri })
                };
            });
        };
        _this.onChangeLabel = function (e) {
            var target = e.target;
            var labels = target.value.length > 0 ? [{ value: target.value, language: '' }] : [];
            _this.setState({ elementModel: tslib_1.__assign(tslib_1.__assign({}, _this.state.elementModel), { label: { values: labels } }) });
        };
        _this.state = { elementModel: props.entity };
        return _this;
    }
    EditEntityForm.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.entity !== nextProps.entity) {
            this.setState({ elementModel: nextProps.entity });
        }
    };
    EditEntityForm.prototype.renderProperties = function () {
        var _this = this;
        var properties = this.props.entity.properties;
        var propertyIris = Object.keys(properties);
        return (React.createElement("div", null, propertyIris.map(function (iri) {
            return _this.renderProperty(iri, properties[iri]);
        })));
    };
    EditEntityForm.prototype.renderType = function () {
        var view = this.props.view;
        var elementModel = this.state.elementModel;
        var label = view.getElementTypeString(elementModel);
        return (React.createElement("label", null,
            "Type",
            React.createElement("input", { className: 'ontodia-form-control', value: label, disabled: true })));
    };
    EditEntityForm.prototype.renderIri = function () {
        var elementModel = this.state.elementModel;
        return (React.createElement("label", null,
            "IRI",
            React.createElement("input", { className: 'ontodia-form-control', defaultValue: elementModel.id, onChange: this.onChangeIri })));
    };
    EditEntityForm.prototype.renderLabel = function () {
        var view = this.props.view;
        var label = view.selectLabel(this.state.elementModel.label.values);
        var text = label ? label.value : '';
        return (React.createElement("label", null,
            "Label",
            React.createElement("input", { className: 'ontodia-form-control', value: text, onChange: this.onChangeLabel })));
    };
    EditEntityForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: CLASS_NAME + "__body" },
                React.createElement("div", { className: CLASS_NAME + "__form-row" }, this.renderIri()),
                React.createElement("div", { className: CLASS_NAME + "__form-row" }, this.renderType()),
                React.createElement("div", { className: CLASS_NAME + "__form-row" }, this.renderLabel()),
                this.renderProperties()),
            React.createElement("div", { className: CLASS_NAME + "__controls" },
                React.createElement("button", { className: "ontodia-btn ontodia-btn-success " + CLASS_NAME + "__apply-button", onClick: function () { return _this.props.onApply(_this.state.elementModel); } }, "Apply"),
                React.createElement("button", { className: 'ontodia-btn ontodia-btn-danger', onClick: this.props.onCancel }, "Cancel"))));
    };
    return EditEntityForm;
}(React.Component));
exports.EditEntityForm = EditEntityForm;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var async_1 = require("../viewUtils/async");
var DataFetcher = /** @class */ (function () {
    function DataFetcher(graph, dataProvider) {
        var _this = this;
        this.graph = graph;
        this.dataProvider = dataProvider;
        this.classQueue = new async_1.BufferingQueue(function (classIds) {
            _this.dataProvider.classInfo({ classIds: classIds }).then(_this.onClassesLoaded);
        });
        this.linkTypeQueue = new async_1.BufferingQueue(function (linkTypeIds) {
            _this.dataProvider.linkTypesInfo({ linkTypeIds: linkTypeIds }).then(_this.onLinkTypesLoaded);
        });
        this.propertyTypeQueue = new async_1.BufferingQueue(function (propertyIds) {
            _this.dataProvider.propertyInfo({ propertyIds: propertyIds }).then(_this.onPropertyTypesLoaded);
        });
        this.onElementInfoLoaded = function (elements) {
            for (var _i = 0, _a = _this.graph.getElements(); _i < _a.length; _i++) {
                var element = _a[_i];
                var loadedModel = elements[element.iri];
                if (loadedModel) {
                    element.setData(loadedModel);
                }
            }
        };
        this.onClassesLoaded = function (classInfos) {
            for (var _i = 0, classInfos_1 = classInfos; _i < classInfos_1.length; _i++) {
                var _a = classInfos_1[_i], id = _a.id, label = _a.label, count = _a.count;
                var model = _this.graph.getClass(id);
                if (!model) {
                    continue;
                }
                model.setLabel(label.values);
                if (typeof count === 'number') {
                    model.setCount(count);
                }
            }
        };
        this.onLinkTypesLoaded = function (linkTypesInfo) {
            for (var _i = 0, linkTypesInfo_1 = linkTypesInfo; _i < linkTypesInfo_1.length; _i++) {
                var _a = linkTypesInfo_1[_i], id = _a.id, label = _a.label;
                var model = _this.graph.getLinkType(id);
                if (!model) {
                    continue;
                }
                model.setLabel(label.values);
            }
        };
        this.onPropertyTypesLoaded = function (propertyModels) {
            for (var propId in propertyModels) {
                if (!Object.prototype.hasOwnProperty.call(propertyModels, propId)) {
                    continue;
                }
                var _a = propertyModels[propId], id = _a.id, label = _a.label;
                var targetProperty = _this.graph.getProperty(id);
                if (targetProperty) {
                    targetProperty.setLabel(label.values);
                }
            }
        };
    }
    DataFetcher.prototype.fetchElementData = function (elementIris) {
        if (elementIris.length === 0) {
            return Promise.resolve();
        }
        return this.dataProvider.elementInfo({ elementIds: tslib_1.__spreadArrays(elementIris) })
            .then(this.onElementInfoLoaded);
    };
    DataFetcher.prototype.fetchClass = function (model) {
        this.classQueue.push(model.id);
    };
    DataFetcher.prototype.fetchLinkType = function (linkType) {
        this.linkTypeQueue.push(linkType.id);
    };
    DataFetcher.prototype.fetchPropertyType = function (propertyType) {
        if (!this.dataProvider.propertyInfo) {
            return;
        }
        this.propertyTypeQueue.push(propertyType.id);
    };
    return DataFetcher;
}());
exports.DataFetcher = DataFetcher;

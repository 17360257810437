"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DATA_PROVIDER_PROPERTY = 'http://ontodia.org/property/DataProvider';
function mergeClassTree(composite) {
    var lists = composite.filter(function (r) { return r.response; }).map(function (_a) {
        var useInStats = _a.useInStats, response = _a.response;
        return ({ useInStats: useInStats, classes: classTreeToArray(response) });
    });
    var dictionary = {};
    var topLevelModels = {};
    var childrenMap = {};
    for (var _i = 0, lists_1 = lists; _i < lists_1.length; _i++) {
        var _a = lists_1[_i], useInStats = _a.useInStats, classes = _a.classes;
        var _loop_1 = function (model) {
            var childrenIds = childrenMap[model.id] || [];
            model.children.map(function (ch) { return ch.id; }).forEach(function (id) {
                if (childrenIds.indexOf(id) === -1) {
                    childrenIds.push(id);
                }
            });
            model.children = [];
            if (!useInStats) {
                delete model.count;
            }
            if (!dictionary[model.id]) {
                topLevelModels[model.id] = model;
                dictionary[model.id] = model;
                childrenMap[model.id] = childrenIds;
            }
            else {
                topLevelModels[model.id] = mergeClassModel(dictionary[model.id], model);
                dictionary[model.id] = topLevelModels[model.id];
            }
        };
        for (var _b = 0, classes_1 = classes; _b < classes_1.length; _b++) {
            var model = classes_1[_b];
            _loop_1(model);
        }
    }
    var models = Object.keys(dictionary).map(function (key) { return dictionary[key]; });
    for (var _c = 0, models_1 = models; _c < models_1.length; _c++) {
        var m = models_1[_c];
        m.children = (childrenMap[m.id] || []).map(function (id) {
            delete topLevelModels[id];
            return dictionary[id];
        });
    }
    return Object.keys(topLevelModels).map(function (key) { return topLevelModels[key]; });
}
exports.mergeClassTree = mergeClassTree;
function mergePropertyInfo(response) {
    var result = {};
    var props = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
        var model = props_1[_i];
        var keys = Object.keys(model);
        for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
            var key = keys_1[_a];
            var prop = model[key];
            if (!result[key]) {
                result[key] = prop;
            }
            else {
                result[key].label = mergeLabels(result[key].label, prop.label);
            }
        }
    }
    return result;
}
exports.mergePropertyInfo = mergePropertyInfo;
function mergeClassInfo(response) {
    var dictionaries = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    var dictionary = {};
    for (var _i = 0, dictionaries_1 = dictionaries; _i < dictionaries_1.length; _i++) {
        var models = dictionaries_1[_i];
        for (var _a = 0, models_2 = models; _a < models_2.length; _a++) {
            var model = models_2[_a];
            if (!dictionary[model.id]) {
                dictionary[model.id] = model;
            }
            else {
                dictionary[model.id] = mergeClassModel(dictionary[model.id], model);
            }
        }
    }
    return Object.keys(dictionary).map(function (key) { return dictionary[key]; });
}
exports.mergeClassInfo = mergeClassInfo;
function mergeLinkTypesInfo(response) {
    var lists = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    var mergeLinkType = function (a, b) {
        return {
            id: a.id,
            label: mergeLabels(a.label, b.label),
            count: a.count + b.count,
        };
    };
    var dictionary = {};
    for (var _i = 0, lists_2 = lists; _i < lists_2.length; _i++) {
        var linkTypes = lists_2[_i];
        for (var _a = 0, linkTypes_1 = linkTypes; _a < linkTypes_1.length; _a++) {
            var linkType = linkTypes_1[_a];
            if (!dictionary[linkType.id]) {
                dictionary[linkType.id] = linkType;
            }
            else {
                dictionary[linkType.id] = mergeLinkType(dictionary[linkType.id], linkType);
            }
        }
    }
    return Object.keys(dictionary).map(function (key) { return dictionary[key]; });
}
exports.mergeLinkTypesInfo = mergeLinkTypesInfo;
function mergeLinkTypes(response) {
    return mergeLinkTypesInfo(response);
}
exports.mergeLinkTypes = mergeLinkTypes;
function mergeElementInfo(response) {
    var mergeElementModels = function (a, b) {
        var types = a.types;
        for (var _i = 0, _a = b.types; _i < _a.length; _i++) {
            var t = _a[_i];
            if (types.indexOf(t) === -1) {
                types.push(t);
            }
        }
        var sources = [];
        for (var _b = 0, _c = a.sources; _b < _c.length; _b++) {
            var s = _c[_b];
            if (sources.indexOf(s) === -1) {
                sources.push(s);
            }
        }
        for (var _d = 0, _e = b.sources; _d < _e.length; _d++) {
            var s = _e[_d];
            if (sources.indexOf(s) === -1) {
                sources.push(s);
            }
        }
        return {
            id: a.id,
            label: mergeLabels(a.label, b.label),
            types: types,
            image: a.image || b.image,
            properties: mergeProperties(a.properties, b.properties),
            sources: sources,
        };
    };
    var dictionaries = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    var dictionary = {};
    var _loop_2 = function (resp) {
        if (!resp.response) {
            return "continue";
        }
        var list = Object.keys(resp.response).map(function (k) { return resp.response[k]; });
        for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
            var em = list_1[_i];
            em.sources = [resp.dataSourceName];
            em.properties[DATA_PROVIDER_PROPERTY] = {
                type: 'string',
                values: [{ value: resp.dataSourceName, language: '' }],
            };
            if (!dictionary[em.id]) {
                dictionary[em.id] = em;
            }
            else {
                dictionary[em.id] = mergeElementModels(dictionary[em.id], em);
            }
        }
    };
    for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
        var resp = response_1[_i];
        _loop_2(resp);
    }
    return dictionary;
}
exports.mergeElementInfo = mergeElementInfo;
function mergeProperties(a, b) {
    var aLists = Object.keys(a);
    var bLists = Object.keys(b);
    var result = {};
    function createIdForProperty(baseId) {
        var counter = 1;
        while (result[baseId + '_' + counter]) {
            counter++;
        }
        return baseId + '_' + counter;
    }
    for (var _i = 0, aLists_1 = aLists; _i < aLists_1.length; _i++) {
        var pKey = aLists_1[_i];
        var prop = a[pKey];
        if (!result[pKey]) {
            result[pKey] = prop;
        }
        else {
            result[createIdForProperty(pKey)] = prop;
        }
    }
    for (var _a = 0, bLists_1 = bLists; _a < bLists_1.length; _a++) {
        var pKey = bLists_1[_a];
        var prop = b[pKey];
        if (!result[pKey]) {
            result[pKey] = prop;
        }
        else {
            result[createIdForProperty(pKey)] = prop;
        }
    }
    return result;
}
exports.mergeProperties = mergeProperties;
function mergeLinksInfo(response) {
    var lists = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    var resultInfo = [];
    function compareLinksInfo(a, b) {
        return a.sourceId === b.sourceId &&
            a.targetId === b.targetId &&
            a.linkTypeId === b.linkTypeId;
    }
    for (var _i = 0, lists_3 = lists; _i < lists_3.length; _i++) {
        var linkInfo = lists_3[_i];
        var _loop_3 = function (linkModel) {
            if (!resultInfo.some(function (l) { return compareLinksInfo(l, linkModel); })) {
                resultInfo.push(linkModel);
            }
        };
        for (var _a = 0, linkInfo_1 = linkInfo; _a < linkInfo_1.length; _a++) {
            var linkModel = linkInfo_1[_a];
            _loop_3(linkModel);
        }
    }
    return resultInfo;
}
exports.mergeLinksInfo = mergeLinksInfo;
function mergeLinkTypesOf(response) {
    var lists = response.filter(function (r) { return r.response; }).map(function (r) { return r.response; });
    var dictionary = {};
    var merge = function (a, b) {
        return {
            id: a.id,
            inCount: a.inCount + b.inCount,
            outCount: a.outCount + b.outCount,
        };
    };
    for (var _i = 0, lists_4 = lists; _i < lists_4.length; _i++) {
        var linkCount = lists_4[_i];
        for (var _a = 0, linkCount_1 = linkCount; _a < linkCount_1.length; _a++) {
            var lCount = linkCount_1[_a];
            if (!dictionary[lCount.id]) {
                dictionary[lCount.id] = lCount;
            }
            else {
                dictionary[lCount.id] = merge(lCount, dictionary[lCount.id]);
            }
        }
    }
    return Object.keys(dictionary).map(function (key) { return dictionary[key]; });
}
exports.mergeLinkTypesOf = mergeLinkTypesOf;
function mergeLinkElements(response) {
    return mergeElementInfo(response);
}
exports.mergeLinkElements = mergeLinkElements;
function mergeFilter(response) {
    return mergeElementInfo(response);
}
exports.mergeFilter = mergeFilter;
function classTreeToArray(models) {
    var resultArray = models;
    function getDescendants(model) {
        var descendants = model.children || [];
        for (var _i = 0, descendants_1 = descendants; _i < descendants_1.length; _i++) {
            var descendant = descendants_1[_i];
            var nextGeneration = getDescendants(descendant);
            descendants = descendants.concat(nextGeneration);
        }
        return descendants;
    }
    for (var _i = 0, models_3 = models; _i < models_3.length; _i++) {
        var model = models_3[_i];
        var descendants = getDescendants(model);
        resultArray = resultArray.concat(descendants);
    }
    return resultArray;
}
exports.classTreeToArray = classTreeToArray;
function mergeLabels(a, b) {
    function compareLabels(l1, l2) {
        return l1.language === l2.language && l1.value === l2.value;
    }
    var mergedValuesList = a.values;
    var _loop_4 = function (locStr) {
        if (!mergedValuesList.some(function (l) { return compareLabels(l, locStr); })) {
            mergedValuesList.push(locStr);
        }
    };
    for (var _i = 0, _a = b.values; _i < _a.length; _i++) {
        var locStr = _a[_i];
        _loop_4(locStr);
    }
    return {
        values: mergedValuesList,
    };
}
exports.mergeLabels = mergeLabels;
function mergeCounts(a, b) {
    if (a === undefined && b === undefined) {
        return undefined;
    }
    return (a || 0) + (b || 0);
}
exports.mergeCounts = mergeCounts;
function mergeClassModel(a, b) {
    var childrenDictionary = {};
    for (var _i = 0, _a = a.children.concat(b.children); _i < _a.length; _i++) {
        var child = _a[_i];
        if (!childrenDictionary[child.id]) {
            childrenDictionary[child.id] = child;
        }
    }
    return {
        id: a.id,
        label: mergeLabels(a.label, b.label),
        count: mergeCounts(a.count, b.count),
        children: Object.keys(childrenDictionary).map(function (key) { return childrenDictionary[key]; }),
    };
}
exports.mergeClassModel = mergeClassModel;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var lodash_1 = require("lodash");
var schema_1 = require("../data/schema");
var serializedCellProperties = [
    // common properties
    'id', 'type',
    // element properties
    'size', 'angle', 'isExpanded', 'position', 'iri', 'group',
    // link properties
    'typeId', 'source', 'target', 'vertices',
];
function emptyDiagram() {
    return {
        '@context': schema_1.DIAGRAM_CONTEXT_URL_V1,
        '@type': 'Diagram',
        layoutData: emptyLayoutData(),
        linkTypeOptions: [],
    };
}
exports.emptyDiagram = emptyDiagram;
function emptyLayoutData() {
    return { '@type': 'Layout', elements: [], links: [] };
}
exports.emptyLayoutData = emptyLayoutData;
function convertToSerializedDiagram(params) {
    var elements = [];
    var links = [];
    for (var _i = 0, _a = params.layoutData.cells; _i < _a.length; _i++) {
        var cell = _a[_i];
        // get rid of unused properties
        var newCell = lodash_1.pick(cell, serializedCellProperties);
        // normalize type
        if (newCell.type === 'Ontodia.Element' || newCell.type === 'element') {
            newCell.type = 'Element';
        }
        // normalize type
        if (newCell.type === 'link') {
            newCell.type = 'Link';
        }
        if (!newCell.iri) {
            newCell.iri = newCell.id;
        }
        // rename to @id and @type to match JSON-LD
        newCell['@id'] = newCell.id;
        delete newCell.id;
        newCell['@type'] = newCell.type;
        delete newCell.type;
        // make two separate lists
        switch (newCell['@type']) {
            case 'Element':
                elements.push(newCell);
                break;
            case 'Link':
                // rename internal IDs
                newCell.source['@id'] = newCell.source.id;
                delete newCell.source.id;
                newCell.target['@id'] = newCell.target.id;
                delete newCell.target.id;
                // rename typeID to property
                newCell.property = newCell.typeId;
                delete newCell.typeId;
                links.push(newCell);
                break;
        }
    }
    return tslib_1.__assign(tslib_1.__assign({}, emptyDiagram()), { layoutData: { '@type': 'Layout', elements: elements, links: links }, linkTypeOptions: params.linkTypeOptions });
}
exports.convertToSerializedDiagram = convertToSerializedDiagram;
function makeSerializedDiagram(params) {
    var diagram = tslib_1.__assign(tslib_1.__assign({}, emptyDiagram()), { linkTypeOptions: params.linkTypeOptions });
    // layout data is a complex structure we want to persist
    if (params.layoutData) {
        diagram.layoutData = params.layoutData;
    }
    return diagram;
}
exports.makeSerializedDiagram = makeSerializedDiagram;
function makeLayoutData(modelElements, modelLinks) {
    var elements = modelElements.map(function (element) { return ({
        '@type': 'Element',
        '@id': element.id,
        iri: element.iri,
        position: element.position,
        size: element.size,
        isExpanded: element.isExpanded,
        group: element.group,
        elementState: element.elementState,
    }); });
    var links = modelLinks.map(function (link) { return ({
        '@type': 'Link',
        '@id': link.id,
        property: link.typeId,
        source: { '@id': link.sourceId },
        target: { '@id': link.targetId },
        vertices: tslib_1.__spreadArrays(link.vertices),
        linkState: link.linkState,
    }); });
    return { '@type': 'Layout', elements: elements, links: links };
}
exports.makeLayoutData = makeLayoutData;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var utils_1 = require("./utils");
var CLASS_NAME = 'ontodia-default-template';
var DefaultElementTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(DefaultElementTemplate, _super);
    function DefaultElementTemplate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DefaultElementTemplate.prototype.render = function () {
        var props = this.props;
        var image = props.imgUrl ? (React.createElement("div", { className: CLASS_NAME + "__thumbnail" },
            React.createElement("img", { src: props.imgUrl }))) : undefined;
        var expander = props.isExpanded ? (React.createElement("div", null,
            React.createElement("div", { className: 'ontodia-default-template_body_expander' },
                React.createElement("div", { className: 'ontodia-default-template_body_expander__iri_label' }, "IRI:"),
                React.createElement("div", { className: 'ontodia-default-template_body_expander_iri' },
                    React.createElement("a", { className: 'ontodia-default-template_body_expander_iri__link', href: props.iri, title: props.iri }, props.iri))),
            React.createElement("hr", { className: 'ontodia-default-template_body_expander__hr' }),
            this.renderPropertyTable())) : null;
        return (React.createElement("div", { className: 'ontodia-default-template', style: {
                backgroundColor: props.color,
                borderColor: props.color,
            }, "data-expanded": this.props.isExpanded },
            React.createElement("div", { className: 'ontodia-default-template_type-line', title: props.label },
                React.createElement("div", { className: 'ontodia-default-template_type-line__icon', "aria-hidden": 'true' },
                    React.createElement("img", { src: props.iconUrl })),
                React.createElement("div", { title: props.types, className: 'ontodia-default-template_type-line_text-container' },
                    React.createElement("div", { className: 'ontodia-default-template_type-line_text-container__text' }, props.types))),
            image,
            React.createElement("div", { className: 'ontodia-default-template_body', style: { borderColor: props.color } },
                React.createElement("span", { className: 'ontodia-default-template_body__label', title: props.label }, props.label),
                expander)));
    };
    DefaultElementTemplate.prototype.renderPropertyTable = function () {
        var propsAsList = this.props.propsAsList;
        if (propsAsList && propsAsList.length > 0) {
            return React.createElement("div", { className: 'ontodia-default-template_body_expander_property-table' }, propsAsList.map(function (prop) {
                var propertyValues = utils_1.getPropertyValues(prop.property);
                var values = propertyValues.map(function (text, index) { return (React.createElement("div", { className: 'ontodia-default-template_body_expander_property-table_row_key_values__value', key: index, title: text }, text)); });
                return (React.createElement("div", { key: prop.id, className: 'ontodia-default-template_body_expander_property-table_row' },
                    React.createElement("div", { title: prop.name + ' (' + prop.id + ')', className: 'ontodia-default-template_body_expander_property-table_row__key' }, prop.name),
                    React.createElement("div", { className: 'ontodia-default-template_body_expander_property-table_row_key_values' }, values)));
            }));
        }
        else {
            return React.createElement("div", null, "no properties");
        }
    };
    return DefaultElementTemplate;
}(React.Component));
exports.DefaultElementTemplate = DefaultElementTemplate;

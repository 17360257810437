"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var listElementView_1 = require("../listElementView");
var EXPAND_ICON = require('../../../../images/tree/expand-toggle.svg');
var COLLAPSE_ICON = require('../../../../images/tree/collapse-toggle.svg');
var DEFAULT_LEAF_ICON = require('../../../../images/tree/leaf-default.svg');
var DEFAULT_PARENT_ICON = require('../../../../images/tree/leaf-folder.svg');
var LEAF_CLASS = 'ontodia-class-leaf';
var Leaf = /** @class */ (function (_super) {
    tslib_1.__extends(Leaf, _super);
    function Leaf(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = function (e) {
            e.preventDefault();
            var _a = _this.props, node = _a.node, onSelect = _a.onSelect;
            onSelect(node);
        };
        _this.toggle = function () {
            _this.setState(function (state) { return ({ expanded: !state.expanded }); });
        };
        _this.onClickCreate = function () {
            _this.props.onClickCreate(_this.props.node);
        };
        _this.onDragCreate = function (e) {
            // sets the drag data to support drag-n-drop in Firefox
            // see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations for more details
            // IE supports only 'text' and 'URL' formats, see https://msdn.microsoft.com/en-us/ie/ms536744(v=vs.94)
            e.dataTransfer.setData('text', '');
            _this.props.onDragCreate(_this.props.node);
        };
        _this.state = {
            expanded: Boolean(_this.props.searchText),
        };
        return _this;
    }
    Leaf.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.searchText !== nextProps.searchText) {
            this.setState({
                expanded: Boolean(nextProps.searchText),
            });
        }
    };
    Leaf.prototype.render = function () {
        var _a = this.props, node = _a.node, otherProps = tslib_1.__rest(_a, ["node"]);
        var view = otherProps.view, selectedNode = otherProps.selectedNode, searchText = otherProps.searchText, creatableClasses = otherProps.creatableClasses;
        var expanded = this.state.expanded;
        var toggleIcon;
        if (node.derived.length > 0) {
            toggleIcon = expanded ? COLLAPSE_ICON : EXPAND_ICON;
        }
        var icon = view.getTypeStyle([node.model.id]).icon;
        if (!icon) {
            icon = node.derived.length === 0 ? DEFAULT_LEAF_ICON : DEFAULT_PARENT_ICON;
        }
        var bodyClass = LEAF_CLASS + "__body";
        if (selectedNode && selectedNode.model === node.model) {
            bodyClass += " " + LEAF_CLASS + "__body--selected";
        }
        var label = listElementView_1.highlightSubstring(node.label, searchText, { className: LEAF_CLASS + "__highlighted-term" });
        return (React.createElement("div", { className: LEAF_CLASS, role: 'tree-item' },
            React.createElement("div", { className: LEAF_CLASS + "__row" },
                React.createElement("div", { className: LEAF_CLASS + "__toggle", onClick: this.toggle, role: 'button' }, toggleIcon ? React.createElement("img", { className: LEAF_CLASS + "__toggle-icon", src: toggleIcon }) : null),
                React.createElement("a", { className: bodyClass, href: node.model.id, onClick: this.onClick },
                    React.createElement("div", { className: LEAF_CLASS + "__icon-container" },
                        React.createElement("img", { className: LEAF_CLASS + "__icon", src: icon })),
                    React.createElement("span", { className: LEAF_CLASS + "__label" }, label),
                    node.model.count ? (React.createElement("span", { className: LEAF_CLASS + "__count ontodia-badge" }, node.model.count)) : null),
                creatableClasses.get(node.model.id) ? (React.createElement("div", { className: LEAF_CLASS + "__create ontodia-btn-group ontodia-btn-group-xs" },
                    React.createElement("button", { className: 'ontodia-btn ontodia-btn-default', title: 'Click or drag to create new entity of this type', draggable: true, onClick: this.onClickCreate, onDragStart: this.onDragCreate }, "+"))) : null),
            expanded && node.derived.length > 0 ? (React.createElement(Forest, tslib_1.__assign({ className: LEAF_CLASS + "__children", nodes: node.derived }, otherProps))) : null));
    };
    return Leaf;
}(React.Component));
exports.Leaf = Leaf;
var Forest = /** @class */ (function (_super) {
    tslib_1.__extends(Forest, _super);
    function Forest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Forest.prototype.render = function () {
        var _a = this.props, nodes = _a.nodes, className = _a.className, otherProps = tslib_1.__rest(_a, ["nodes", "className"]);
        return (React.createElement("div", { className: className, role: 'tree' }, nodes.map(function (node) { return (React.createElement(Leaf, tslib_1.__assign({ key: "node-" + node.model.id, node: node }, otherProps))); })));
    };
    return Forest;
}(React.Component));
exports.Forest = Forest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var d3_color_1 = require("d3-color");
var lodash_1 = require("lodash");
var defaultTypeStyles_1 = require("../customization/defaultTypeStyles");
var defaultLinkStyles_1 = require("../customization/defaultLinkStyles");
var templates_1 = require("../customization/templates");
var blankNodes_1 = require("../data/sparql/blankNodes");
var utils_1 = require("../data/utils");
var events_1 = require("../viewUtils/events");
var geometry_1 = require("./geometry");
var linkRouter_1 = require("./linkRouter");
var IriClickIntent;
(function (IriClickIntent) {
    IriClickIntent["JumpToEntity"] = "jumpToEntity";
    IriClickIntent["OpenEntityIri"] = "openEntityIri";
    IriClickIntent["OpenOtherIri"] = "openOtherIri";
})(IriClickIntent = exports.IriClickIntent || (exports.IriClickIntent = {}));
var RenderingLayer;
(function (RenderingLayer) {
    RenderingLayer[RenderingLayer["Element"] = 1] = "Element";
    RenderingLayer[RenderingLayer["ElementSize"] = 2] = "ElementSize";
    RenderingLayer[RenderingLayer["PaperArea"] = 3] = "PaperArea";
    RenderingLayer[RenderingLayer["Link"] = 4] = "Link";
    RenderingLayer[RenderingLayer["Editor"] = 5] = "Editor";
    RenderingLayer[RenderingLayer["FirstToUpdate"] = 1] = "FirstToUpdate";
    RenderingLayer[RenderingLayer["LastToUpdate"] = 5] = "LastToUpdate";
})(RenderingLayer = exports.RenderingLayer || (exports.RenderingLayer = {}));
var WidgetAttachment;
(function (WidgetAttachment) {
    WidgetAttachment[WidgetAttachment["Viewport"] = 1] = "Viewport";
    WidgetAttachment[WidgetAttachment["OverElements"] = 2] = "OverElements";
    WidgetAttachment[WidgetAttachment["OverLinks"] = 3] = "OverLinks";
})(WidgetAttachment = exports.WidgetAttachment || (exports.WidgetAttachment = {}));
var DiagramView = /** @class */ (function () {
    function DiagramView(model, options) {
        if (options === void 0) { options = {}; }
        this.model = model;
        this.options = options;
        this.listener = new events_1.EventObserver();
        this.source = new events_1.EventSource();
        this.events = this.source;
        this.disposed = false;
        this.colorSeed = 0x0BADBEEF;
        this._language = 'en';
        this.linkTemplates = new Map();
        this.routings = new Map();
        this.resolveTypeStyle = options.typeStyleResolver || defaultTypeStyles_1.DefaultTypeStyleBundle;
        this.resolveLinkTemplate = options.linkTemplateResolver || defaultLinkStyles_1.DefaultLinkTemplateBundle;
        this.resolveElementTemplate = options.elementTemplateResolver || templates_1.DefaultElementTemplateBundle;
        this.initRouting();
    }
    DiagramView.prototype.initRouting = function () {
        var _this = this;
        this.router = this.options.linkRouter || new linkRouter_1.DefaultLinkRouter();
        this.updateRoutings();
        this.listener.listen(this.model.events, 'changeCells', function () { return _this.updateRoutings(); });
        this.listener.listen(this.model.events, 'linkEvent', function (_a) {
            var key = _a.key, data = _a.data;
            if (data.changeVertices) {
                _this.updateRoutings();
            }
        });
        this.listener.listen(this.model.events, 'elementEvent', function (_a) {
            var key = _a.key, data = _a.data;
            if (data.changePosition || data.changeSize) {
                _this.updateRoutings();
            }
        });
    };
    DiagramView.prototype.updateRoutings = function () {
        var previousRoutes = this.routings;
        var computedRoutes = this.router.route(this.model);
        previousRoutes.forEach(function (previous, linkId) {
            var computed = computedRoutes.get(linkId);
            if (computed && sameRoutedLink(previous, computed)) {
                // replace new route with the old one if they're equal
                // so other components can use a simple reference equality checks
                computedRoutes.set(linkId, previous);
            }
        });
        this.routings = computedRoutes;
        this.source.trigger('updateRoutings', { source: this, previous: previousRoutes });
    };
    DiagramView.prototype.getRoutings = function () {
        return this.routings;
    };
    DiagramView.prototype.getRouting = function (linkId) {
        return this.routings.get(linkId);
    };
    DiagramView.prototype.getLanguage = function () { return this._language; };
    DiagramView.prototype.setLanguage = function (value) {
        if (!value) {
            throw new Error('Cannot set empty language.');
        }
        var previous = this._language;
        if (previous === value) {
            return;
        }
        this._language = value;
        this.source.trigger('changeLanguage', { source: this, previous: previous });
    };
    DiagramView.prototype.getLinkTemplates = function () {
        return this.linkTemplates;
    };
    DiagramView.prototype.performSyncUpdate = function () {
        for (var layer = RenderingLayer.FirstToUpdate; layer <= RenderingLayer.LastToUpdate; layer++) {
            this.source.trigger('syncUpdate', { layer: layer });
        }
    };
    DiagramView.prototype.onIriClick = function (iri, element, clickIntent, event) {
        event.persist();
        event.preventDefault();
        var onIriClick = this.options.onIriClick;
        if (onIriClick) {
            onIriClick({ iri: iri, element: element, clickIntent: clickIntent, originalEvent: event });
        }
    };
    DiagramView.prototype.setPaperWidget = function (widget) {
        var _a;
        var key = widget.key, element = widget.widget, attachment = widget.attachment;
        var widgets = (_a = {}, _a[key] = element ? { element: element, attachment: attachment } : undefined, _a);
        this.source.trigger('updateWidgets', { widgets: widgets });
    };
    DiagramView.prototype.setHandlerForNextDropOnPaper = function (handler) {
        this.dropOnPaperHandler = handler;
    };
    DiagramView.prototype._tryHandleDropOnPaper = function (e) {
        var dropOnPaperHandler = this.dropOnPaperHandler;
        if (dropOnPaperHandler) {
            this.dropOnPaperHandler = undefined;
            dropOnPaperHandler(e);
            return true;
        }
        return false;
    };
    DiagramView.prototype.selectLabel = function (labels, language) {
        var targetLanguage = typeof language === 'undefined' ? this.getLanguage() : language;
        var _a = this.options.selectLabelLanguage, selectLabelLanguage = _a === void 0 ? defaultSelectLabel : _a;
        return selectLabelLanguage(labels, targetLanguage);
    };
    DiagramView.prototype.formatLabel = function (labels, fallbackIri, language) {
        var label = this.selectLabel(labels, language);
        return resolveLabel(label, fallbackIri);
    };
    DiagramView.prototype.getElementTypeString = function (elementModel) {
        var _this = this;
        return elementModel.types.map(function (typeId) {
            var type = _this.model.createClass(typeId);
            return _this.formatLabel(type.label, type.id);
        }).sort().join(', ');
    };
    DiagramView.prototype.getTypeStyle = function (types) {
        types.sort();
        var customStyle = this.resolveTypeStyle(types);
        var icon = customStyle ? customStyle.icon : undefined;
        var color;
        if (customStyle && customStyle.color) {
            color = d3_color_1.hcl(customStyle.color);
        }
        else {
            var hue = getHueFromClasses(types, this.colorSeed);
            color = { h: hue, c: 40, l: 75 };
        }
        return { icon: icon, color: color };
    };
    DiagramView.prototype.formatIri = function (iri) {
        if (blankNodes_1.isEncodedBlank(iri)) {
            return '(blank node)';
        }
        return "<" + iri + ">";
    };
    DiagramView.prototype.getElementTemplate = function (types) {
        return this.resolveElementTemplate(types) || templates_1.StandardTemplate;
    };
    DiagramView.prototype.createLinkTemplate = function (linkType) {
        var existingTemplate = this.linkTemplates.get(linkType.id);
        if (existingTemplate) {
            return existingTemplate;
        }
        var template = {};
        var result = this.resolveLinkTemplate(linkType.id);
        if (result) {
            template = lodash_1.cloneDeep(result);
        }
        fillLinkTemplateDefaults(template);
        this.linkTemplates.set(linkType.id, template);
        this.source.trigger('changeLinkTemplates', {});
        return template;
    };
    DiagramView.prototype.dispose = function () {
        if (this.disposed) {
            return;
        }
        this.source.trigger('dispose', {});
        this.listener.stopListening();
        this.disposed = true;
    };
    Object.defineProperty(DiagramView.prototype, "highlighter", {
        get: function () { return this._highlighter; },
        enumerable: true,
        configurable: true
    });
    DiagramView.prototype.setHighlighter = function (value) {
        var previous = this._highlighter;
        if (previous === value) {
            return;
        }
        this._highlighter = value;
        this.source.trigger('changeHighlight', { source: this, previous: previous });
    };
    DiagramView.prototype._setElementDecorator = function (decorator) {
        this._elementDecorator = decorator;
    };
    DiagramView.prototype._decorateElement = function (element) {
        return this._elementDecorator(element);
    };
    return DiagramView;
}());
exports.DiagramView = DiagramView;
function sameRoutedLink(a, b) {
    return (a.linkId === b.linkId &&
        a.labelTextAnchor === b.labelTextAnchor &&
        geometry_1.isPolylineEqual(a.vertices, b.vertices));
}
function getHueFromClasses(classes, seed) {
    var hash = seed;
    for (var _i = 0, classes_1 = classes; _i < classes_1.length; _i++) {
        var name_1 = classes_1[_i];
        hash = utils_1.hashFnv32a(name_1, hash);
    }
    var MAX_INT32 = 0x7fffffff;
    return 360 * ((hash === undefined ? 0 : hash) / MAX_INT32);
}
function fillLinkTemplateDefaults(template) {
    var defaults = {
        markerTarget: { d: 'M0,0 L0,8 L9,4 z', width: 9, height: 8, fill: 'black' },
    };
    lodash_1.defaultsDeep(template, defaults);
    if (!template.renderLink) {
        template.renderLink = function () { return ({}); };
    }
}
function defaultSelectLabel(texts, language) {
    if (texts.length === 0) {
        return undefined;
    }
    var defaultValue;
    var englishValue;
    for (var _i = 0, texts_1 = texts; _i < texts_1.length; _i++) {
        var text = texts_1[_i];
        if (text.language === language) {
            return text;
        }
        else if (text.language === '') {
            defaultValue = text;
        }
        else if (text.language === 'en') {
            englishValue = text;
        }
    }
    return (defaultValue !== undefined ? defaultValue :
        englishValue !== undefined ? englishValue :
            texts[0]);
}
function resolveLabel(label, fallbackIri) {
    if (label) {
        return label.value;
    }
    return utils_1.getUriLocalName(fallbackIri) || fallbackIri;
}

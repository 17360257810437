"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var model_1 = require("../data/model");
var async_1 = require("../viewUtils/async");
var collections_1 = require("../viewUtils/collections");
var ValidationState;
(function (ValidationState) {
    ValidationState.empty = createMutable();
    ValidationState.emptyElement = { loading: false, errors: [] };
    ValidationState.emptyLink = { loading: false, errors: [] };
    function createMutable() {
        return {
            elements: new Map(),
            links: new collections_1.HashMap(model_1.hashLink, model_1.sameLink),
        };
    }
    ValidationState.createMutable = createMutable;
    function setElementErrors(state, target, errors) {
        var elements = collections_1.cloneMap(state.elements);
        if (errors.length > 0) {
            elements.set(target, { loading: false, errors: errors });
        }
        else {
            elements.delete(target);
        }
        return tslib_1.__assign(tslib_1.__assign({}, state), { elements: elements });
    }
    ValidationState.setElementErrors = setElementErrors;
    function setLinkErrors(state, target, errors) {
        var links = state.links.clone();
        if (errors.length > 0) {
            links.set(target, { loading: false, errors: errors });
        }
        else {
            links.delete(target);
        }
        return tslib_1.__assign(tslib_1.__assign({}, state), { links: links });
    }
    ValidationState.setLinkErrors = setLinkErrors;
})(ValidationState = exports.ValidationState || (exports.ValidationState = {}));
function changedElementsToValidate(previousAuthoring, editor) {
    var currentAuthoring = editor.authoringState;
    var links = new collections_1.HashMap(model_1.hashLink, model_1.sameLink);
    previousAuthoring.links.forEach(function (e, model) { return links.set(model, true); });
    currentAuthoring.links.forEach(function (e, model) { return links.set(model, true); });
    var toValidate = new Set();
    links.forEach(function (value, linkModel) {
        var current = currentAuthoring.links.get(linkModel);
        var previous = previousAuthoring.links.get(linkModel);
        if (current !== previous) {
            toValidate.add(linkModel.sourceId);
        }
    });
    for (var _i = 0, _a = editor.model.elements; _i < _a.length; _i++) {
        var element = _a[_i];
        var current = currentAuthoring.elements.get(element.iri);
        var previous = previousAuthoring.elements.get(element.iri);
        if (current !== previous) {
            toValidate.add(element.iri);
            // when we remove element incoming link are removed as well so we should update their sources
            if ((current || previous).deleted) {
                for (var _b = 0, _c = element.links; _b < _c.length; _b++) {
                    var link = _c[_b];
                    if (link.data.sourceId !== element.iri) {
                        toValidate.add(link.data.sourceId);
                    }
                }
            }
        }
    }
    return toValidate;
}
exports.changedElementsToValidate = changedElementsToValidate;
function validateElements(targets, validationApi, editor, cancellationToken) {
    var previousState = editor.validationState;
    var newState = ValidationState.createMutable();
    var _loop_1 = function (element) {
        if (newState.elements.has(element.iri)) {
            return "continue";
        }
        var outboundLinks = element.links.reduce(function (acc, link) {
            if (link.sourceId === element.id) {
                acc.push(link.data);
            }
            return acc;
        }, []);
        if (targets.has(element.iri)) {
            var event_1 = {
                target: element.data,
                outboundLinks: outboundLinks,
                state: editor.authoringState,
                model: editor.model,
                cancellation: cancellationToken,
            };
            var result = async_1.CancellationToken.mapCancelledToNull(cancellationToken, validationApi.validate(event_1));
            var loadingElement = { loading: true, errors: [] };
            var loadingLink_1 = { loading: true, errors: [] };
            newState.elements.set(element.iri, loadingElement);
            outboundLinks.forEach(function (link) { return newState.links.set(link, loadingLink_1); });
            processValidationResult(result, loadingElement, loadingLink_1, event_1, editor);
        }
        else {
            // use previous state for element and outbound links
            newState.elements.set(element.iri, previousState.elements.get(element.iri));
            for (var _i = 0, outboundLinks_1 = outboundLinks; _i < outboundLinks_1.length; _i++) {
                var link = outboundLinks_1[_i];
                newState.links.set(link, previousState.links.get(link));
            }
        }
    };
    for (var _i = 0, _a = editor.model.elements; _i < _a.length; _i++) {
        var element = _a[_i];
        _loop_1(element);
    }
    editor.setValidationState(newState);
}
exports.validateElements = validateElements;
function processValidationResult(result, previousElement, previousLink, e, editor) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var allErrors, err_1, elementErrors, linkErrors, _i, allErrors_1, error, state;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, result];
                case 1:
                    allErrors = _a.sent();
                    if (allErrors === null) {
                        // validation was cancelled
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    // tslint:disable-next-line:no-console
                    console.error("Failed to validate element", e.target, err_1);
                    allErrors = [{ type: 'element', target: e.target.id, message: "Failed to validate element" }];
                    return [3 /*break*/, 3];
                case 3:
                    elementErrors = [];
                    linkErrors = new collections_1.HashMap(model_1.hashLink, model_1.sameLink);
                    e.outboundLinks.forEach(function (link) { return linkErrors.set(link, []); });
                    for (_i = 0, allErrors_1 = allErrors; _i < allErrors_1.length; _i++) {
                        error = allErrors_1[_i];
                        if (error.type === 'element' && error.target === e.target.id) {
                            elementErrors.push(error);
                        }
                        else if (error.type === 'link' && linkErrors.has(error.target)) {
                            linkErrors.get(error.target).push(error);
                        }
                    }
                    state = editor.validationState;
                    if (state.elements.get(e.target.id) === previousElement) {
                        state = ValidationState.setElementErrors(state, e.target.id, elementErrors);
                    }
                    linkErrors.forEach(function (errors, link) {
                        if (state.links.get(link) === previousLink) {
                            state = ValidationState.setLinkErrors(state, link, errors);
                        }
                    });
                    editor.setValidationState(state);
                    return [2 /*return*/];
            }
        });
    });
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var draggableHandle_1 = require("./draggableHandle");
var DockSide;
(function (DockSide) {
    DockSide[DockSide["Left"] = 1] = "Left";
    DockSide[DockSide["Right"] = 2] = "Right";
})(DockSide = exports.DockSide || (exports.DockSide = {}));
var CLASS_NAME = 'ontodia-accordion-item';
var AccordionItem = /** @class */ (function (_super) {
    tslib_1.__extends(AccordionItem, _super);
    function AccordionItem(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            resizing: false,
        };
        return _this;
    }
    Object.defineProperty(AccordionItem.prototype, "element", {
        get: function () { return this._element; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccordionItem.prototype, "header", {
        get: function () { return this._header; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccordionItem.prototype, "isVertical", {
        get: function () {
            return this.props.direction === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    AccordionItem.prototype.renderToggleButton = function () {
        var _a = this.props, collapsed = _a.collapsed, dockSide = _a.dockSide, onChangeCollapsed = _a.onChangeCollapsed;
        if (!dockSide) {
            return null;
        }
        var side = dockSide === DockSide.Left ? 'left' : 'right';
        return React.createElement("div", { className: CLASS_NAME + "__handle-btn " + CLASS_NAME + "__handle-btn-" + side, onClick: function () { return onChangeCollapsed(!collapsed); } });
    };
    AccordionItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, heading = _a.heading, bodyClassName = _a.bodyClassName, children = _a.children, bodyRef = _a.bodyRef, collapsed = _a.collapsed, size = _a.size, direction = _a.direction, onBeginDragHandle = _a.onBeginDragHandle, onDragHandle = _a.onDragHandle, onEndDragHandle = _a.onEndDragHandle, dockSide = _a.dockSide;
        var resizing = this.state.resizing;
        var shouldRenderHandle = onBeginDragHandle && onDragHandle && onEndDragHandle;
        var style = this.isVertical ? { height: size } : { width: size };
        // unmount child component when the accordion item is collapsed and has dockSide
        var isMounted = !(collapsed && dockSide);
        return React.createElement("div", { className: CLASS_NAME + " " + CLASS_NAME + "--" + (collapsed ? 'collapsed' : 'expanded') + " " + CLASS_NAME + "--" + direction + "\n                " + (resizing ? CLASS_NAME + "--resizing" : ''), ref: function (element) { return _this._element = element; }, style: style },
            React.createElement("div", { className: CLASS_NAME + "__inner" },
                heading ? React.createElement("div", { className: CLASS_NAME + "__header", ref: function (header) { return _this._header = header; }, onClick: function () { return _this.props.onChangeCollapsed(!collapsed); } }, heading) : null,
                React.createElement("div", { className: CLASS_NAME + "__body" }, children && isMounted ? children :
                    React.createElement("div", { ref: bodyRef, className: "" + (bodyClassName || '') }))),
            shouldRenderHandle ? (React.createElement(draggableHandle_1.DraggableHandle, { className: CLASS_NAME + "__handle " + CLASS_NAME + "__handle-" + direction, onBeginDragHandle: function (e) {
                    _this.setState({ resizing: true });
                    onBeginDragHandle();
                }, onDragHandle: function (e, x, y) { return onDragHandle(x, y); }, onEndDragHandle: function (e) {
                    _this.setState({ resizing: false });
                    onEndDragHandle();
                } })) : null,
            this.renderToggleButton());
    };
    AccordionItem.defaultProps = {
        direction: 'vertical',
    };
    return AccordionItem;
}(React.Component));
exports.AccordionItem = AccordionItem;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function objectValues(obj) {
    var items = [];
    for (var key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        items.push(obj[key]);
    }
    return items;
}
exports.objectValues = objectValues;
function isEmptyMap(map) {
    for (var key in map) {
        if (Object.prototype.hasOwnProperty.call(map, key)) {
            return false;
        }
    }
    return true;
}
exports.isEmptyMap = isEmptyMap;
/**
 * Clones Map collection. Required due to IE11 not supporing `new Map(map)`.
 */
function cloneMap(map) {
    var clone = new Map();
    map.forEach(function (value, key) { return clone.set(key, value); });
    return clone;
}
exports.cloneMap = cloneMap;
/**
 * Clones Set collection. Required due to IE11 not supporing `new Set(set)`.
 */
function cloneSet(set) {
    var clone = new Set();
    set.forEach(function (item) { return clone.add(item); });
    return clone;
}
exports.cloneSet = cloneSet;
function getOrCreateArrayInMap(map, key) {
    var values = map.get(key);
    if (!values) {
        values = [];
        map.set(key, values);
    }
    return values;
}
exports.getOrCreateArrayInMap = getOrCreateArrayInMap;
function getOrCreateSetInMap(map, key) {
    var values = map.get(key);
    if (!values) {
        values = new Set();
        map.set(key, values);
    }
    return values;
}
exports.getOrCreateSetInMap = getOrCreateSetInMap;
var OrderedMap = /** @class */ (function () {
    function OrderedMap() {
        this.mapping = new Map();
        this.ordered = [];
    }
    OrderedMap.prototype.reorder = function (compare) {
        this.ordered.sort(compare);
    };
    Object.defineProperty(OrderedMap.prototype, "items", {
        get: function () {
            return this.ordered;
        },
        enumerable: true,
        configurable: true
    });
    OrderedMap.prototype.get = function (key) {
        return this.mapping.get(key);
    };
    OrderedMap.prototype.push = function (key, value) {
        if (this.mapping.has(key)) {
            var previous = this.mapping.get(key);
            if (previous === value) {
                return;
            }
            var index = this.ordered.indexOf(previous);
            this.ordered.splice(index, 1);
        }
        this.mapping.set(key, value);
        this.ordered.push(value);
    };
    OrderedMap.prototype.delete = function (key) {
        if (!this.mapping.has(key)) {
            return undefined;
        }
        var previous = this.mapping.get(key);
        var index = this.ordered.indexOf(previous);
        this.ordered.splice(index, 1);
        this.mapping.delete(key);
        return previous;
    };
    return OrderedMap;
}());
exports.OrderedMap = OrderedMap;
var HashMap = /** @class */ (function () {
    function HashMap(hashCode, equals) {
        this.hashCode = hashCode;
        this.equals = equals;
        this.map = new Map();
        this._size = 0;
    }
    Object.defineProperty(HashMap.prototype, "size", {
        get: function () {
            return this._size;
        },
        enumerable: true,
        configurable: true
    });
    HashMap.prototype.has = function (key) {
        var _this = this;
        var items = this.map.get(this.hashCode(key));
        if (!items) {
            return false;
        }
        return Boolean(items.find(function (p) { return _this.equals(p.key, key); }));
    };
    HashMap.prototype.get = function (key) {
        var _this = this;
        var items = this.map.get(this.hashCode(key));
        if (!items) {
            return undefined;
        }
        var pair = items.find(function (p) { return _this.equals(p.key, key); });
        return pair ? pair.value : undefined;
    };
    HashMap.prototype.set = function (key, value) {
        var _this = this;
        var hash = this.hashCode(key);
        var items = this.map.get(hash);
        if (items) {
            var index = items.findIndex(function (p) { return _this.equals(p.key, key); });
            if (index >= 0) {
                items.splice(index, 1);
            }
            else {
                this._size++;
            }
            items.push({ key: key, value: value });
        }
        else {
            items = [{ key: key, value: value }];
            this.map.set(hash, items);
            this._size++;
        }
        return this;
    };
    HashMap.prototype.delete = function (key) {
        var _this = this;
        var items = this.map.get(this.hashCode(key));
        if (!items) {
            return false;
        }
        var index = items.findIndex(function (p) { return _this.equals(p.key, key); });
        if (index >= 0) {
            items.splice(index, 1);
            this._size--;
            return true;
        }
        else {
            return false;
        }
    };
    HashMap.prototype.clear = function () {
        this.map.clear();
        this._size = 0;
    };
    HashMap.prototype.forEach = function (callback) {
        var _this = this;
        this.map.forEach(function (items) {
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var _a = items_1[_i], key = _a.key, value = _a.value;
                callback(value, key, _this);
            }
        });
    };
    HashMap.prototype.clone = function () {
        var clone = new HashMap(this.hashCode, this.equals);
        clone._size = this.size;
        this.map.forEach(function (value, key) { return clone.map.set(key, tslib_1.__spreadArrays(value)); });
        return clone;
    };
    return HashMap;
}());
exports.HashMap = HashMap;
var MoveDirection;
(function (MoveDirection) {
    MoveDirection[MoveDirection["ToStart"] = -1] = "ToStart";
    MoveDirection[MoveDirection["ToEnd"] = 1] = "ToEnd";
})(MoveDirection = exports.MoveDirection || (exports.MoveDirection = {}));
function makeMoveComparator(items, selected, moveDirection) {
    var orderMap = new Map();
    var selectionIndexOffset = moveDirection * items.length;
    items.forEach(function (item, index) {
        orderMap.set(item, index);
    });
    for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
        var selectedItem = selected_1[_i];
        orderMap.set(selectedItem, selectionIndexOffset + orderMap.get(selectedItem));
    }
    return function (a, b) {
        var orderA = orderMap.get(a);
        var orderB = orderMap.get(b);
        return (orderA > orderB ? 1 :
            orderA < orderB ? -1 :
                0);
    };
}
exports.makeMoveComparator = makeMoveComparator;

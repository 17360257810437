"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_1 = require("../../data/model");
function getProperty(props, id) {
    if (props && props[id]) {
        return getPropertyValues(props[id]).join(', ');
    }
    else {
        return undefined;
    }
}
exports.getProperty = getProperty;
function getPropertyValues(property) {
    if (model_1.isIriProperty(property)) {
        return property.values.map(function (_a) {
            var value = _a.value;
            return value;
        });
    }
    else if (model_1.isLiteralProperty(property)) {
        return property.values.map(function (_a) {
            var value = _a.value;
            return value;
        });
    }
    return [];
}
exports.getPropertyValues = getPropertyValues;

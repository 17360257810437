"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var elements_1 = require("../../diagram/elements");
var async_1 = require("../../viewUtils/async");
var collections_1 = require("../../viewUtils/collections");
var events_1 = require("../../viewUtils/events");
var spinner_1 = require("../../viewUtils/spinner");
var progressBar_1 = require("../../widgets/progressBar");
var treeModel_1 = require("./treeModel");
var leaf_1 = require("./leaf");
var CLASS_NAME = 'ontodia-class-tree';
var MIN_TERM_LENGTH = 3;
var ClassTree = /** @class */ (function (_super) {
    tslib_1.__extends(ClassTree, _super);
    function ClassTree(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.delayedClassUpdate = new async_1.Debouncer();
        _this.delayedSearch = new async_1.Debouncer(200 /* ms */);
        _this.loadClassesOperation = new async_1.Cancellation();
        _this.refreshOperation = new async_1.Cancellation();
        _this.onSearchTextChange = function (e) {
            var requestedSearchText = e.currentTarget.value;
            _this.setState({ requestedSearchText: requestedSearchText });
            _this.delayedSearch.call(_this.performSearch);
        };
        _this.performSearch = function () {
            var requestedSearchText = _this.state.requestedSearchText;
            var requested = normalizeSearchText(requestedSearchText);
            if (requested === _this.state.appliedSearchText) {
                return;
            }
            var appliedSearchText = requested.length < MIN_TERM_LENGTH ? undefined : requested;
            _this.setState(function (state) { return applyFilters(tslib_1.__assign(tslib_1.__assign({}, state), { appliedSearchText: appliedSearchText })); });
        };
        _this.onShowOnlyCreatableChange = function (e) {
            _this.setState(function (state) { return applyFilters(tslib_1.__assign(tslib_1.__assign({}, state), { showOnlyConstructible: !state.showOnlyConstructible })); });
        };
        _this.onSelectNode = function (node) {
            var onClassSelected = _this.props.onClassSelected;
            _this.setState({ selectedNode: node });
            onClassSelected(node.model.id);
        };
        _this.onCreateInstance = function (node) {
            var onCreateInstance = _this.props.onCreateInstance;
            onCreateInstance(node.model.id);
        };
        _this.onDragCreate = function (node) {
            var _a = _this.props, view = _a.view, onCreateInstance = _a.onCreateInstance;
            view.setHandlerForNextDropOnPaper(function (e) {
                onCreateInstance(node.model.id, e.paperPosition);
            });
        };
        _this.refreshClassTree = function () {
            var cancellation = new async_1.Cancellation();
            var editor = _this.props.editor;
            _this.refreshOperation.abort();
            _this.refreshOperation = cancellation;
            _this.setState(function (state, props) {
                if (!_this.classTree) {
                    return { refreshingState: progressBar_1.ProgressState.none };
                }
                var refreshingState = progressBar_1.ProgressState.none;
                if (editor.inAuthoringMode) {
                    var newIris = getNewClassIris(state.constructibleClasses, _this.classTree);
                    if (newIris.size > 0) {
                        refreshingState = progressBar_1.ProgressState.loading;
                        _this.queryCreatableTypes(newIris, cancellation.signal);
                    }
                }
                var roots = createRoots(_this.classTree, props.view);
                return applyFilters(tslib_1.__assign(tslib_1.__assign({}, state), { roots: sortTree(roots), refreshingState: refreshingState }));
            });
        };
        _this.state = {
            refreshingState: progressBar_1.ProgressState.none,
            roots: [],
            filteredRoots: [],
            requestedSearchText: '',
            appliedSearchText: '',
            constructibleClasses: new Map(),
            showOnlyConstructible: false,
        };
        return _this;
    }
    ClassTree.prototype.render = function () {
        var _a = this.props, view = _a.view, editor = _a.editor;
        var _b = this.state, refreshingState = _b.refreshingState, requestedSearchText = _b.requestedSearchText, appliedSearchText = _b.appliedSearchText, filteredRoots = _b.filteredRoots, selectedNode = _b.selectedNode, constructibleClasses = _b.constructibleClasses, showOnlyConstructible = _b.showOnlyConstructible;
        var normalizedSearchText = normalizeSearchText(requestedSearchText);
        // highlight search term only if actual tree is already filtered by current or previous term:
        //  - this immediately highlights typed characters thus making it look more responsive,
        //  - prevents expanding non-filtered tree (which can be too large) just to highlight the term
        var searchText = appliedSearchText ? normalizedSearchText : undefined;
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: CLASS_NAME + "__filter" },
                React.createElement("div", { className: CLASS_NAME + "__filter-group" },
                    React.createElement("input", { type: 'text', className: 'search-input ontodia-form-control', placeholder: 'Search for...', value: this.state.requestedSearchText, onChange: this.onSearchTextChange }),
                    editor.inAuthoringMode ? (React.createElement("label", { className: CLASS_NAME + "__only-creatable" },
                        React.createElement("input", { type: 'checkbox', checked: showOnlyConstructible, onChange: this.onShowOnlyCreatableChange }),
                        " Show only constructible")) : null)),
            React.createElement(progressBar_1.ProgressBar, { state: refreshingState }),
            this.classTree ? (React.createElement(leaf_1.Forest, { className: CLASS_NAME + "__tree ontodia-scrollable", view: view, nodes: filteredRoots, searchText: searchText, selectedNode: selectedNode, onSelect: this.onSelectNode, creatableClasses: constructibleClasses, onClickCreate: this.onCreateInstance, onDragCreate: this.onDragCreate })) : (React.createElement("div", { className: CLASS_NAME + "__spinner" },
                React.createElement(spinner_1.HtmlSpinner, { width: 30, height: 30 })))));
    };
    ClassTree.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, view = _a.view, editor = _a.editor;
        this.listener.listen(view.events, 'changeLanguage', function () { return _this.refreshClassTree(); });
        this.listener.listen(editor.model.events, 'loadingStart', function () {
            _this.initClassTree();
        });
        this.listener.listen(editor.model.events, 'classEvent', function (_a) {
            var data = _a.data;
            if (data.changeLabel || data.changeCount) {
                _this.delayedClassUpdate.call(_this.refreshClassTree);
            }
        });
        this.initClassTree();
    };
    ClassTree.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.delayedClassUpdate.dispose();
        this.delayedSearch.dispose();
        this.loadClassesOperation.abort();
        this.refreshOperation.abort();
    };
    ClassTree.prototype.initClassTree = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cancellation, classes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.dataProvider !== this.props.editor.model.dataProvider)) return [3 /*break*/, 2];
                        this.dataProvider = this.props.editor.model.dataProvider;
                        this.classTree = undefined;
                        cancellation = new async_1.Cancellation();
                        this.loadClassesOperation.abort();
                        this.loadClassesOperation = cancellation;
                        return [4 /*yield*/, this.dataProvider.classTree()];
                    case 1:
                        classes = _a.sent();
                        if (cancellation.signal.aborted) {
                            return [2 /*return*/];
                        }
                        this.setClassTree(classes);
                        _a.label = 2;
                    case 2:
                        this.refreshClassTree();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClassTree.prototype.setClassTree = function (roots) {
        var diagramModel = this.props.editor.model;
        var visiting = new Set();
        var reduceNonCycle = function (acc, model) {
            if (!visiting.has(model.id)) {
                visiting.add(model.id);
                var children = model.children.reduce(reduceNonCycle, []);
                acc.push(tslib_1.__assign(tslib_1.__assign({}, model), { children: children }));
                visiting.delete(model.id);
            }
            return acc;
        };
        this.classTree = roots.reduce(reduceNonCycle, []);
        var addClass = function (model) {
            var existing = diagramModel.getClass(model.id);
            if (!existing) {
                var id = model.id, label = model.label, count = model.count, children = model.children;
                var richClass = new elements_1.FatClassModel({ id: id, label: label.values, count: count });
                diagramModel.addClass(richClass);
                children.forEach(addClass);
            }
        };
        this.classTree.forEach(addClass);
        this.refreshClassTree();
    };
    ClassTree.prototype.queryCreatableTypes = function (typeIris, ct) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result_1, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(ct, this.props.editor.metadataApi.filterConstructibleTypes(typeIris, ct))];
                    case 1:
                        result_1 = _a.sent();
                        if (result_1 === null) {
                            return [2 /*return*/];
                        }
                        this.setState(function (state) {
                            var constructibleClasses = collections_1.cloneMap(state.constructibleClasses);
                            typeIris.forEach(function (type) {
                                constructibleClasses.set(type, result_1.has(type));
                            });
                            return applyFilters(tslib_1.__assign(tslib_1.__assign({}, state), { constructibleClasses: constructibleClasses, refreshingState: progressBar_1.ProgressState.completed }));
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        // tslint:disable-next-line:no-console
                        console.error(err_1);
                        if (ct.aborted) {
                            return [2 /*return*/];
                        }
                        this.setState(function (state) { return applyFilters(tslib_1.__assign(tslib_1.__assign({}, state), { refreshingState: progressBar_1.ProgressState.error })); });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ClassTree;
}(React.Component));
exports.ClassTree = ClassTree;
function createRoots(classTree, view) {
    var mapClass = function (model) {
        var richClass = view.model.createClass(model.id);
        return {
            model: richClass,
            label: view.formatLabel(richClass.label, richClass.id),
            derived: model.children.map(mapClass),
        };
    };
    return classTree.map(mapClass);
}
function getNewClassIris(existingClasses, classTree) {
    var classIris = new Set();
    var visitClass = function (model) {
        if (!existingClasses.has(model.id)) {
            classIris.add(model.id);
        }
        model.children.forEach(visitClass);
    };
    classTree.forEach(visitClass);
    return classIris;
}
function normalizeSearchText(text) {
    return text.trim().toLowerCase();
}
function sortTree(roots) {
    function mapNodes(nodes) {
        if (nodes.length === 0) {
            return nodes;
        }
        var mapped = nodes.map(mapNode);
        mapped.sort(compareByLabel);
        return mapped;
    }
    function mapNode(node) {
        return treeModel_1.TreeNode.setDerived(node, mapNodes(node.derived));
    }
    function compareByLabel(left, right) {
        return left.label.localeCompare(right.label);
    }
    return mapNodes(roots);
}
function applyFilters(state) {
    var filteredRoots = state.roots;
    if (state.appliedSearchText) {
        filteredRoots = filterByKeyword(filteredRoots, state.appliedSearchText);
    }
    if (state.showOnlyConstructible) {
        filteredRoots = filterOnlyCreatable(filteredRoots, state.constructibleClasses);
    }
    return tslib_1.__assign(tslib_1.__assign({}, state), { filteredRoots: filteredRoots });
}
function filterByKeyword(roots, searchText) {
    if (roots.length === 0) {
        return roots;
    }
    function collectByKeyword(acc, node) {
        var derived = node.derived.reduce(collectByKeyword, []);
        // keep parent if children is included or label contains keyword
        if (derived.length > 0 || node.label.toLowerCase().indexOf(searchText) >= 0) {
            acc.push(treeModel_1.TreeNode.setDerived(node, derived));
        }
        return acc;
    }
    return roots.reduce(collectByKeyword, []);
}
function filterOnlyCreatable(roots, creatableClasses) {
    function collectOnlyCreatable(acc, node) {
        var derived = node.derived.reduce(collectOnlyCreatable, []);
        if (derived.length > 0 || creatableClasses.get(node.model.id)) {
            acc.push(treeModel_1.TreeNode.setDerived(node, derived));
        }
        return acc;
    }
    return roots.reduce(collectOnlyCreatable, []);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var view_1 = require("../diagram/view");
var paperArea_1 = require("../diagram/paperArea");
var classTree_1 = require("../widgets/classTree");
var instancesSearch_1 = require("../widgets/instancesSearch");
var linksToolbox_1 = require("../widgets/linksToolbox");
var workspaceContext_1 = require("./workspaceContext");
var async_1 = require("../viewUtils/async");
var layout_1 = require("./layout/layout");
var WorkspaceMarkup = /** @class */ (function (_super) {
    tslib_1.__extends(WorkspaceMarkup, _super);
    function WorkspaceMarkup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.untilMouseUpClasses = [];
        _this.cancellation = new async_1.Cancellation();
        _this.triggerWorkspaceEvent = function (key) {
            var onWorkspaceEvent = _this.props.onWorkspaceEvent;
            if (onWorkspaceEvent) {
                onWorkspaceEvent(key);
            }
        };
        _this.onCreateInstance = function (classId, position) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, editor, view, model, metadataApi, batch, type, typeName, types, signal, newEntityIri, elementModel, element, targetPosition;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, editor = _a.editor, view = _a.view, model = _a.model, metadataApi = _a.metadataApi;
                        return [4 /*yield*/, forceNonReactExecutionContext()];
                    case 1:
                        _b.sent();
                        batch = model.history.startBatch();
                        type = editor.model.getClass(classId);
                        typeName = view.formatLabel(type.label, type.id);
                        types = [classId];
                        signal = this.cancellation.signal;
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.generateNewElementIri(types, signal))];
                    case 2:
                        newEntityIri = _b.sent();
                        if (newEntityIri === null) {
                            return [2 /*return*/];
                        }
                        elementModel = {
                            id: newEntityIri,
                            types: types,
                            label: { values: [{ value: "New " + typeName, language: '' }] },
                            properties: {},
                        };
                        element = editor.createNewEntity({ elementModel: elementModel });
                        targetPosition = position || getViewportCenterInPaperCoords(this.paperArea);
                        element.setPosition(targetPosition);
                        view.performSyncUpdate();
                        centerElementToPosition(element, targetPosition);
                        batch.store();
                        editor.setSelection([element]);
                        editor.showEditEntityForm(element);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onDocumentMouseUp = function () {
            for (var _i = 0, _a = _this.untilMouseUpClasses; _i < _a.length; _i++) {
                var className = _a[_i];
                _this.element.classList.remove(className);
            }
            _this.untilMouseUpClasses = [];
        };
        _this.onDropOnPaper = function (e, paperPosition) {
            e.preventDefault();
            var event = { dragEvent: e, paperPosition: paperPosition };
            if (_this.props.view._tryHandleDropOnPaper(event)) {
                return;
            }
            var iris = tryParseDefaultDragAndDropData(e);
            if (iris.length > 0) {
                _this.props.editor.onDragDrop(iris, paperPosition);
            }
        };
        return _this;
    }
    WorkspaceMarkup.prototype.getChildContext = function () {
        var editor = this.props.editor;
        var ontodiaWorkspace = { editor: editor, triggerWorkspaceEvent: this.triggerWorkspaceEvent };
        return { ontodiaWorkspace: ontodiaWorkspace };
    };
    WorkspaceMarkup.prototype.addToolbarWidgetToPaper = function () {
        var _a = this.props, hideToolbar = _a.hideToolbar, view = _a.view, toolbar = _a.toolbar;
        if (!hideToolbar) {
            view.setPaperWidget({
                key: 'toolbar',
                widget: React.createElement(ToolbarWidget, null, toolbar),
                attachment: view_1.WidgetAttachment.Viewport,
            });
        }
    };
    WorkspaceMarkup.prototype.getLeftPanelLayout = function () {
        var _a = this.props, view = _a.view, editor = _a.editor, model = _a.model, searchCriteria = _a.searchCriteria, onSearchCriteriaChanged = _a.onSearchCriteriaChanged;
        var classTree = (React.createElement(classTree_1.ClassTree, { view: view, editor: editor, onClassSelected: function (classId) {
                var elementType = model.createClass(classId);
                onSearchCriteriaChanged({ elementType: elementType });
            }, onCreateInstance: this.onCreateInstance }));
        var instancesSearch = (React.createElement(instancesSearch_1.InstancesSearch, { view: view, model: model, criteria: searchCriteria || {}, onCriteriaChanged: onSearchCriteriaChanged }));
        return {
            type: layout_1.WorkspaceLayoutType.Column,
            children: [{
                    id: 'classes',
                    type: layout_1.WorkspaceLayoutType.Component,
                    content: classTree,
                    heading: 'Classes',
                }, {
                    id: 'instances',
                    type: layout_1.WorkspaceLayoutType.Component,
                    content: instancesSearch,
                    heading: 'Instances',
                }],
            defaultSize: 275,
            defaultCollapsed: !this.props.isLeftPanelOpen,
        };
    };
    WorkspaceMarkup.prototype.getRightPanelLayout = function () {
        var _a = this.props, view = _a.view, editor = _a.editor, elementsSearchPanel = _a.elementsSearchPanel;
        var rightPanel = {
            type: layout_1.WorkspaceLayoutType.Column,
            children: [{
                    id: 'connections',
                    type: layout_1.WorkspaceLayoutType.Component,
                    content: React.createElement(linksToolbox_1.LinkTypesToolbox, { view: view, editor: editor }),
                    heading: 'Connections',
                }],
            defaultSize: 275,
            defaultCollapsed: !this.props.isRightPanelOpen,
        };
        if (elementsSearchPanel) {
            rightPanel.children = tslib_1.__spreadArrays(rightPanel.children, [
                {
                    id: 'search',
                    type: layout_1.WorkspaceLayoutType.Component,
                    content: React.cloneElement(elementsSearchPanel, { view: view, editor: editor }),
                    heading: 'Search in diagram',
                }
            ]);
        }
        return rightPanel;
    };
    WorkspaceMarkup.prototype.render = function () {
        var _this = this;
        var paper = {
            id: 'paper',
            type: layout_1.WorkspaceLayoutType.Component,
            content: (React.createElement("div", { className: 'ontodia__main-panel', style: { flex: '1 1 0px', width: '100%' } },
                React.createElement(paperArea_1.PaperArea, { ref: function (el) { return _this.paperArea = el; }, view: this.props.view, zoomOptions: this.props.zoomOptions, hideScrollBars: this.props.hideScrollBars, watermarkSvg: this.props.watermarkSvg, watermarkUrl: this.props.watermarkUrl, onDragDrop: this.onDropOnPaper, onZoom: this.props.onZoom }))),
        };
        var workspaceLayout = this.props.hidePanels ? paper : {
            type: layout_1.WorkspaceLayoutType.Row,
            children: [
                this.getLeftPanelLayout(),
                paper,
                this.getRightPanelLayout(),
            ]
        };
        return (React.createElement("div", { ref: function (e) { return _this.element = e; }, className: 'ontodia' },
            React.createElement("div", { className: 'ontodia__workspace' },
                React.createElement(layout_1.WorkspaceLayout, { layout: workspaceLayout, _onStartResize: function (direction) {
                        return _this.untilMouseUp({
                            preventTextSelection: true,
                            verticalResizing: direction === 'vertical',
                            horizontalResizing: direction === 'horizontal',
                        });
                    } }))));
    };
    WorkspaceMarkup.prototype.componentDidMount = function () {
        document.addEventListener('mouseup', this.onDocumentMouseUp);
        this.addToolbarWidgetToPaper();
    };
    WorkspaceMarkup.prototype.componentWillUnmount = function () {
        document.removeEventListener('mouseup', this.onDocumentMouseUp);
        this.cancellation.abort();
    };
    WorkspaceMarkup.prototype.preventTextSelection = function () {
        this.untilMouseUp({ preventTextSelection: true });
    };
    WorkspaceMarkup.prototype.untilMouseUp = function (params) {
        this.untilMouseUpClasses = [];
        if (params.preventTextSelection) {
            this.untilMouseUpClasses.push('ontodia--unselectable');
        }
        if (params.horizontalResizing) {
            this.untilMouseUpClasses.push('ontodia--horizontal-resizing');
        }
        if (params.verticalResizing) {
            this.untilMouseUpClasses.push('ontodia--vertical-resizing');
        }
        for (var _i = 0, _a = this.untilMouseUpClasses; _i < _a.length; _i++) {
            var className = _a[_i];
            this.element.classList.add(className);
        }
    };
    WorkspaceMarkup.childContextTypes = workspaceContext_1.WorkspaceContextTypes;
    return WorkspaceMarkup;
}(React.Component));
exports.WorkspaceMarkup = WorkspaceMarkup;
var ToolbarWidget = /** @class */ (function (_super) {
    tslib_1.__extends(ToolbarWidget, _super);
    function ToolbarWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ToolbarWidget.prototype.render = function () {
        return (React.createElement("div", { className: 'ontodia__toolbar-widget' }, this.props.children));
    };
    return ToolbarWidget;
}(React.Component));
function forceNonReactExecutionContext() {
    // force non-React executing context to resolve forceUpdate() synchronously
    return Promise.resolve();
}
function getViewportCenterInPaperCoords(paperArea) {
    var viewport = paperArea.getAreaMetrics();
    return paperArea.clientToPaperCoords(viewport.clientWidth / 2, viewport.clientHeight / 2);
}
function centerElementToPosition(element, center) {
    var position = {
        x: center.x - element.size.width / 2,
        y: center.y - element.size.height / 2,
    };
    element.setPosition(position);
}
function tryParseDefaultDragAndDropData(e) {
    var tryGetIri = function (type, decode) {
        if (decode === void 0) { decode = false; }
        try {
            var iriString = e.dataTransfer.getData(type);
            if (!iriString) {
                return undefined;
            }
            var iris = void 0;
            try {
                iris = JSON.parse(iriString);
            }
            catch (e) {
                iris = [(decode ? decodeURI(iriString) : iriString)];
            }
            return iris.length === 0 ? undefined : iris;
        }
        catch (e) {
            return undefined;
        }
    };
    return tryGetIri('application/x-ontodia-elements')
        || tryGetIri('text/uri-list', true)
        || tryGetIri('text') // IE11, Edge
        || [];
}

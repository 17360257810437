"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var schema_1 = require("../data/schema");
var events_1 = require("../viewUtils/events");
var geometry_1 = require("./geometry");
var LinkDirection;
(function (LinkDirection) {
    LinkDirection["in"] = "in";
    LinkDirection["out"] = "out";
})(LinkDirection = exports.LinkDirection || (exports.LinkDirection = {}));
var Element = /** @class */ (function () {
    function Element(props) {
        this.source = new events_1.EventSource();
        this.events = this.source;
        /** All in and out links of the element */
        this.links = [];
        var id = props.id, data = props.data, _a = props.position, position = _a === void 0 ? { x: 0, y: 0 } : _a, _b = props.size, size = _b === void 0 ? { width: 0, height: 0 } : _b, _c = props.expanded, expanded = _c === void 0 ? false : _c, group = props.group, elementState = props.elementState, _d = props.temporary, temporary = _d === void 0 ? false : _d;
        this.id = id;
        this._data = data;
        this._position = position;
        this._size = size;
        this._expanded = expanded;
        this._group = group;
        this._elementState = elementState;
        this._temporary = temporary;
    }
    Object.defineProperty(Element.prototype, "iri", {
        get: function () { return this._data.id; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Element.prototype, "data", {
        get: function () { return this._data; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setData = function (value) {
        var previous = this._data;
        if (previous === value) {
            return;
        }
        this._data = value;
        this.source.trigger('changeData', { source: this, previous: previous });
        updateLinksToReferByNewIri(this, previous.id, value.id);
    };
    Object.defineProperty(Element.prototype, "position", {
        get: function () { return this._position; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setPosition = function (value) {
        var previous = this._position;
        var same = (previous.x === value.x &&
            previous.y === value.y);
        if (same) {
            return;
        }
        this._position = value;
        this.source.trigger('changePosition', { source: this, previous: previous });
    };
    Object.defineProperty(Element.prototype, "size", {
        get: function () { return this._size; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setSize = function (value) {
        var previous = this._size;
        var same = (previous.width === value.width &&
            previous.height === value.height);
        if (same) {
            return;
        }
        this._size = value;
        this.source.trigger('changeSize', { source: this, previous: previous });
    };
    Object.defineProperty(Element.prototype, "isExpanded", {
        get: function () { return this._expanded; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setExpanded = function (value) {
        var previous = this._expanded;
        if (previous === value) {
            return;
        }
        this._expanded = value;
        this.source.trigger('changeExpanded', { source: this, previous: previous });
    };
    Object.defineProperty(Element.prototype, "group", {
        get: function () { return this._group; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setGroup = function (value) {
        var previous = this._group;
        if (previous === value) {
            return;
        }
        this._group = value;
        this.source.trigger('changeGroup', { source: this, previous: previous });
    };
    Object.defineProperty(Element.prototype, "elementState", {
        get: function () { return this._elementState; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.setElementState = function (value) {
        var previous = this._elementState;
        if (previous === value) {
            return;
        }
        this._elementState = value;
        this.source.trigger('changeElementState', { source: this, previous: previous });
    };
    Object.defineProperty(Element.prototype, "temporary", {
        get: function () { return this._temporary; },
        enumerable: true,
        configurable: true
    });
    Element.prototype.focus = function () {
        this.source.trigger('requestedFocus', { source: this });
    };
    Element.prototype.requestGroupContent = function () {
        this.source.trigger('requestedGroupContent', { source: this });
    };
    Element.prototype.addToFilter = function (linkType, direction) {
        this.source.trigger('requestedAddToFilter', {
            source: this, linkType: linkType, direction: direction,
        });
    };
    Element.prototype.redraw = function () {
        this.source.trigger('requestedRedraw', { source: this });
    };
    return Element;
}());
exports.Element = Element;
function updateLinksToReferByNewIri(element, oldIri, newIri) {
    if (oldIri === newIri) {
        return;
    }
    for (var _i = 0, _a = element.links; _i < _a.length; _i++) {
        var link = _a[_i];
        var data = link.data;
        if (data.sourceId === oldIri) {
            data = tslib_1.__assign(tslib_1.__assign({}, data), { sourceId: newIri });
        }
        if (data.targetId === oldIri) {
            data = tslib_1.__assign(tslib_1.__assign({}, data), { targetId: newIri });
        }
        link.setData(data);
    }
}
var FatClassModel = /** @class */ (function () {
    function FatClassModel(props) {
        this.source = new events_1.EventSource();
        this.events = this.source;
        var id = props.id, _a = props.label, label = _a === void 0 ? [] : _a, count = props.count;
        this.id = id;
        this._label = label;
        this._count = count;
    }
    Object.defineProperty(FatClassModel.prototype, "label", {
        get: function () { return this._label; },
        enumerable: true,
        configurable: true
    });
    FatClassModel.prototype.setLabel = function (value) {
        var previous = this._label;
        if (previous === value) {
            return;
        }
        this._label = value;
        this.source.trigger('changeLabel', { source: this, previous: previous });
    };
    Object.defineProperty(FatClassModel.prototype, "count", {
        get: function () { return this._count; },
        enumerable: true,
        configurable: true
    });
    FatClassModel.prototype.setCount = function (value) {
        var previous = this._count;
        if (previous === value) {
            return;
        }
        this._count = value;
        this.source.trigger('changeCount', { source: this, previous: previous });
    };
    return FatClassModel;
}());
exports.FatClassModel = FatClassModel;
var RichProperty = /** @class */ (function () {
    function RichProperty(props) {
        this.source = new events_1.EventSource();
        this.events = this.source;
        var id = props.id, _a = props.label, label = _a === void 0 ? [] : _a;
        this.id = id;
        this._label = label;
    }
    Object.defineProperty(RichProperty.prototype, "label", {
        get: function () { return this._label; },
        enumerable: true,
        configurable: true
    });
    RichProperty.prototype.setLabel = function (value) {
        var previous = this._label;
        if (previous === value) {
            return;
        }
        this._label = value;
        this.source.trigger('changeLabel', { source: this, previous: previous });
    };
    return RichProperty;
}());
exports.RichProperty = RichProperty;
var Link = /** @class */ (function () {
    function Link(props) {
        this.source = new events_1.EventSource();
        this.events = this.source;
        var _a = props.id, id = _a === void 0 ? schema_1.GenerateID.forLink() : _a, typeId = props.typeId, sourceId = props.sourceId, targetId = props.targetId, data = props.data, _b = props.vertices, vertices = _b === void 0 ? [] : _b, linkState = props.linkState;
        this.id = id;
        this._typeId = typeId;
        this._sourceId = sourceId;
        this._targetId = targetId;
        this._data = data;
        this._vertices = vertices;
        this._linkState = linkState;
    }
    Object.defineProperty(Link.prototype, "typeId", {
        get: function () { return this._typeId; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "sourceId", {
        get: function () { return this._sourceId; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "targetId", {
        get: function () { return this._targetId; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "data", {
        get: function () { return this._data; },
        enumerable: true,
        configurable: true
    });
    Link.prototype.setData = function (value) {
        var previous = this._data;
        if (previous === value) {
            return;
        }
        this._data = value;
        this._typeId = value.linkTypeId;
        this.source.trigger('changeData', { source: this, previous: previous });
    };
    Object.defineProperty(Link.prototype, "labelBounds", {
        get: function () { return this._labelBounds; },
        enumerable: true,
        configurable: true
    });
    Link.prototype.setLabelBounds = function (value) {
        var previous = this._labelBounds;
        if (previous === value) {
            return;
        }
        this._labelBounds = value;
        this.source.trigger('changeLabelBounds', { source: this, previous: previous });
    };
    Object.defineProperty(Link.prototype, "layoutOnly", {
        get: function () { return this._layoutOnly; },
        enumerable: true,
        configurable: true
    });
    Link.prototype.setLayoutOnly = function (value) {
        var previous = this._layoutOnly;
        if (previous === value) {
            return;
        }
        this._layoutOnly = value;
        this.source.trigger('changeLayoutOnly', { source: this, previous: previous });
    };
    Object.defineProperty(Link.prototype, "vertices", {
        get: function () { return this._vertices; },
        enumerable: true,
        configurable: true
    });
    Link.prototype.setVertices = function (value) {
        var previous = this._vertices;
        if (geometry_1.isPolylineEqual(this._vertices, value)) {
            return;
        }
        this._vertices = value;
        this.source.trigger('changeVertices', { source: this, previous: previous });
    };
    Object.defineProperty(Link.prototype, "linkState", {
        get: function () { return this._linkState; },
        enumerable: true,
        configurable: true
    });
    Link.prototype.setLinkState = function (value) {
        var previous = this._linkState;
        if (previous === value) {
            return;
        }
        this._linkState = value;
        this.source.trigger('changeLinkState', { source: this, previous: previous });
    };
    return Link;
}());
exports.Link = Link;
function linkMarkerKey(linkTypeIndex, startMarker) {
    return "ontodia-" + (startMarker ? 'mstart' : 'mend') + "-" + linkTypeIndex;
}
exports.linkMarkerKey = linkMarkerKey;
/**
 * Properties:
 *     visible: boolean
 *     showLabel: boolean
 *     isNew?: boolean
 *     label?: { values: LocalizedString[] }
 */
var FatLinkType = /** @class */ (function () {
    function FatLinkType(props) {
        this.source = new events_1.EventSource();
        this.events = this.source;
        this._isNew = false;
        this._visible = true;
        this._showLabel = true;
        var id = props.id, index = props.index, _a = props.label, label = _a === void 0 ? [] : _a;
        this.id = id;
        this._index = index;
        this._label = label;
    }
    Object.defineProperty(FatLinkType.prototype, "index", {
        get: function () { return this._index; },
        enumerable: true,
        configurable: true
    });
    FatLinkType.prototype.setIndex = function (value) {
        if (typeof this._index === 'number') {
            throw new Error('Cannot set index for link type more than once.');
        }
        this._index = value;
    };
    Object.defineProperty(FatLinkType.prototype, "label", {
        get: function () { return this._label; },
        enumerable: true,
        configurable: true
    });
    FatLinkType.prototype.setLabel = function (value) {
        var previous = this._label;
        if (previous === value) {
            return;
        }
        this._label = value;
        this.source.trigger('changeLabel', { source: this, previous: previous });
    };
    Object.defineProperty(FatLinkType.prototype, "visible", {
        get: function () { return this._visible; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FatLinkType.prototype, "showLabel", {
        get: function () { return this._showLabel; },
        enumerable: true,
        configurable: true
    });
    FatLinkType.prototype.setVisibility = function (params) {
        var same = (this._visible === params.visible &&
            this._showLabel === params.showLabel);
        if (same) {
            return;
        }
        var preventLoading = Boolean(params.preventLoading) || this._visible === params.visible;
        this._visible = params.visible;
        this._showLabel = params.showLabel;
        this.source.trigger('changeVisibility', { source: this, preventLoading: preventLoading });
    };
    Object.defineProperty(FatLinkType.prototype, "isNew", {
        get: function () { return this._isNew; },
        enumerable: true,
        configurable: true
    });
    FatLinkType.prototype.setIsNew = function (value) {
        var previous = this._isNew;
        if (previous === value) {
            return;
        }
        this._isNew = value;
        this.source.trigger('changeIsNew', { source: this, previous: previous });
    };
    return FatLinkType;
}());
exports.FatLinkType = FatLinkType;
var LinkVertex = /** @class */ (function () {
    function LinkVertex(link, vertexIndex) {
        this.link = link;
        this.vertexIndex = vertexIndex;
    }
    LinkVertex.prototype.createAt = function (location) {
        var vertices = tslib_1.__spreadArrays(this.link.vertices);
        vertices.splice(this.vertexIndex, 0, location);
        this.link.setVertices(vertices);
    };
    LinkVertex.prototype.moveTo = function (location) {
        var vertices = tslib_1.__spreadArrays(this.link.vertices);
        vertices.splice(this.vertexIndex, 1, location);
        this.link.setVertices(vertices);
    };
    LinkVertex.prototype.remove = function () {
        var vertices = tslib_1.__spreadArrays(this.link.vertices);
        var location = vertices.splice(this.vertexIndex, 1)[0];
        this.link.setVertices(vertices);
    };
    return LinkVertex;
}());
exports.LinkVertex = LinkVertex;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var model_1 = require("../data/model");
var schema_1 = require("../data/schema");
var elements_1 = require("../diagram/elements");
var events_1 = require("../viewUtils/events");
var async_1 = require("../viewUtils/async");
var spinner_1 = require("../viewUtils/spinner");
var CLASS_NAME = 'ontodia-edit-form';
var LinkTypeSelector = /** @class */ (function (_super) {
    tslib_1.__extends(LinkTypeSelector, _super);
    function LinkTypeSelector(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.cancellation = new async_1.Cancellation();
        _this.updateAll = function () { return _this.forceUpdate(); };
        _this.onChangeType = function (e) {
            var _a = _this.props.linkValue.value, originalLink = _a.link, originalDirection = _a.direction;
            var index = parseInt(e.currentTarget.value, 10);
            var _b = _this.state.fatLinkTypes[index], fatLinkType = _b.fatLinkType, direction = _b.direction;
            var link = tslib_1.__assign(tslib_1.__assign({}, originalLink), { linkTypeId: fatLinkType.id });
            // switches source and target if the direction has changed
            if (originalDirection !== direction) {
                link.sourceId = originalLink.targetId;
                link.targetId = originalLink.sourceId;
            }
            _this.props.onChange({ link: link, direction: direction });
        };
        _this.renderPossibleLinkType = function (_a, index) {
            var _b;
            var fatLinkType = _a.fatLinkType, direction = _a.direction;
            var _c = _this.props, view = _c.view, linkValue = _c.linkValue, source = _c.source, target = _c.target;
            var label = view.formatLabel(fatLinkType.label, fatLinkType.id);
            var _d = [source, target].map(function (element) {
                return view.formatLabel(element.label.values, element.id);
            }), sourceLabel = _d[0], targetLabel = _d[1];
            if (direction === elements_1.LinkDirection.in) {
                _b = [targetLabel, sourceLabel], sourceLabel = _b[0], targetLabel = _b[1];
            }
            return React.createElement("option", { key: index, value: index },
                label,
                " [",
                sourceLabel,
                " \u2192 ",
                targetLabel,
                "]");
        };
        _this.state = {
            fatLinkTypes: [],
        };
        return _this;
    }
    LinkTypeSelector.prototype.componentDidMount = function () {
        this.fetchPossibleLinkTypes();
    };
    LinkTypeSelector.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, source = _a.source, target = _a.target;
        if (prevProps.source !== source || prevProps.target !== target) {
            this.setState({ fatLinkTypes: undefined });
            this.fetchPossibleLinkTypes();
        }
    };
    LinkTypeSelector.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.cancellation.abort();
    };
    LinkTypeSelector.prototype.fetchPossibleLinkTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, view, metadataApi, source, target, linkTypes, fatLinkTypes;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, view = _a.view, metadataApi = _a.metadataApi, source = _a.source, target = _a.target;
                        if (!metadataApi) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.possibleLinkTypes(source, target, this.cancellation.signal))];
                    case 1:
                        linkTypes = _b.sent();
                        if (linkTypes === null) {
                            return [2 /*return*/];
                        }
                        fatLinkTypes = [];
                        linkTypes.forEach(function (_a) {
                            var linkTypeIri = _a.linkTypeIri, direction = _a.direction;
                            var fatLinkType = view.model.createLinkType(linkTypeIri);
                            fatLinkTypes.push({ fatLinkType: fatLinkType, direction: direction });
                        });
                        fatLinkTypes.sort(makeLinkTypeComparatorByLabelAndDirection(view));
                        this.setState({ fatLinkTypes: fatLinkTypes });
                        this.listenToLinkLabels(fatLinkTypes);
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkTypeSelector.prototype.listenToLinkLabels = function (fatLinkTypes) {
        var _this = this;
        fatLinkTypes.forEach(function (_a) {
            var fatLinkType = _a.fatLinkType;
            return _this.listener.listen(fatLinkType.events, 'changeLabel', _this.updateAll);
        });
    };
    LinkTypeSelector.prototype.render = function () {
        var _a = this.props, linkValue = _a.linkValue, disabled = _a.disabled;
        var fatLinkTypes = this.state.fatLinkTypes;
        var value = (fatLinkTypes || []).findIndex(function (_a) {
            var fatLinkType = _a.fatLinkType, direction = _a.direction;
            return fatLinkType.id === linkValue.value.link.linkTypeId && direction === linkValue.value.direction;
        });
        return (React.createElement("div", { className: CLASS_NAME + "__control-row" },
            React.createElement("label", null, "Link Type"),
            fatLinkTypes ? (React.createElement("select", { className: 'ontodia-form-control', value: value, onChange: this.onChangeType, disabled: disabled },
                React.createElement("option", { value: -1, disabled: true }, "Select link type"),
                fatLinkTypes.map(this.renderPossibleLinkType))) : React.createElement("div", null,
                React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 })),
            linkValue.error ? React.createElement("span", { className: CLASS_NAME + "__control-error" }, linkValue.error) : ''));
    };
    return LinkTypeSelector;
}(React.Component));
exports.LinkTypeSelector = LinkTypeSelector;
function makeLinkTypeComparatorByLabelAndDirection(view) {
    return function (a, b) {
        var labelA = view.formatLabel(a.fatLinkType.label, a.fatLinkType.id);
        var labelB = view.formatLabel(b.fatLinkType.label, b.fatLinkType.id);
        var labelCompareResult = labelA.localeCompare(labelB);
        if (labelCompareResult !== 0) {
            return labelCompareResult;
        }
        if (a.direction === elements_1.LinkDirection.out && b.direction === elements_1.LinkDirection.in) {
            return -1;
        }
        if (a.direction === elements_1.LinkDirection.in && b.direction === elements_1.LinkDirection.out) {
            return 1;
        }
        return 0;
    };
}
function validateLinkType(editor, currentLink, originalLink) {
    if (currentLink.linkTypeId === schema_1.PLACEHOLDER_LINK_TYPE) {
        return Promise.resolve({ error: 'Required.', allowChange: true });
    }
    if (model_1.sameLink(currentLink, originalLink)) {
        return Promise.resolve({ error: undefined, allowChange: true });
    }
    var alreadyOnDiagram = editor.model.links.find(function (_a) {
        var _b = _a.data, linkTypeId = _b.linkTypeId, sourceId = _b.sourceId, targetId = _b.targetId;
        return linkTypeId === currentLink.linkTypeId &&
            sourceId === currentLink.sourceId &&
            targetId === currentLink.targetId &&
            !editor.temporaryState.links.has(currentLink);
    });
    if (alreadyOnDiagram) {
        return Promise.resolve({ error: 'The link already exists.', allowChange: false });
    }
    return editor.model.dataProvider.linksInfo({
        elementIds: [currentLink.sourceId, currentLink.targetId],
        linkTypeIds: [currentLink.linkTypeId],
    }).then(function (links) {
        var alreadyExists = links.some(function (link) { return model_1.sameLink(link, currentLink); });
        return alreadyExists
            ? { error: 'The link already exists.', allowChange: false }
            : { error: undefined, allowChange: true };
    });
}
exports.validateLinkType = validateLinkType;

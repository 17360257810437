"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var model_1 = require("../data/model");
var collections_1 = require("../viewUtils/collections");
var AuthoringKind;
(function (AuthoringKind) {
    AuthoringKind["ChangeElement"] = "changeElement";
    AuthoringKind["ChangeLink"] = "changeLink";
})(AuthoringKind = exports.AuthoringKind || (exports.AuthoringKind = {}));
var AuthoringState;
(function (AuthoringState) {
    AuthoringState.empty = {
        elements: new Map(),
        links: new collections_1.HashMap(model_1.hashLink, model_1.sameLink),
    };
    function isEmpty(state) {
        return state.elements.size === 0 && state.links.size === 0;
    }
    AuthoringState.isEmpty = isEmpty;
    function clone(index) {
        return {
            elements: collections_1.cloneMap(index.elements),
            links: index.links.clone(),
        };
    }
    AuthoringState.clone = clone;
    function has(state, event) {
        return event.type === AuthoringKind.ChangeElement
            ? state.elements.get(event.after.id) === event
            : state.links.get(event.after) === event;
    }
    AuthoringState.has = has;
    function discard(state, discarded) {
        if (!has(state, discarded)) {
            return state;
        }
        var newState = clone(state);
        if (discarded.type === AuthoringKind.ChangeElement) {
            newState.elements.delete(discarded.after.id);
            if (!discarded.before) {
                state.links.forEach(function (e) {
                    if (isLinkConnectedToElement(e.after, discarded.after.id)) {
                        newState.links.delete(e.after);
                    }
                });
            }
        }
        else {
            newState.links.delete(discarded.after);
        }
        return newState;
    }
    AuthoringState.discard = discard;
    function addElement(state, item) {
        var event = { type: AuthoringKind.ChangeElement, after: item, deleted: false };
        var newState = clone(state);
        newState.elements.set(event.after.id, event);
        return newState;
    }
    AuthoringState.addElement = addElement;
    function addLink(state, item) {
        var event = { type: AuthoringKind.ChangeLink, after: item, deleted: false };
        var newState = clone(state);
        newState.links.set(event.after, event);
        return newState;
    }
    AuthoringState.addLink = addLink;
    function changeElement(state, before, after) {
        var newState = clone(state);
        // delete previous state for an entity
        newState.elements.delete(before.id);
        var previous = state.elements.get(before.id);
        if (previous && !previous.before) {
            // adding or changing new entity
            newState.elements.set(after.id, {
                type: AuthoringKind.ChangeElement,
                after: after,
                deleted: false,
            });
            if (before.id !== after.id) {
                state.links.forEach(function (e) {
                    if (!e.before && isLinkConnectedToElement(e.after, before.id)) {
                        var updatedLink = updateLinkToReferByNewIri(e.after, before.id, after.id);
                        newState.links.delete(e.after);
                        newState.links.set(updatedLink, {
                            type: AuthoringKind.ChangeLink,
                            after: updatedLink,
                            deleted: false,
                        });
                    }
                });
            }
        }
        else {
            // changing existing entity
            var iriChanged = after.id !== before.id;
            var previousBefore = previous ? previous.before : undefined;
            newState.elements.set(before.id, {
                type: AuthoringKind.ChangeElement,
                // always initialize 'before', otherwise entity will be considered new
                before: previousBefore || before,
                after: iriChanged ? tslib_1.__assign(tslib_1.__assign({}, after), { id: before.id }) : after,
                newIri: iriChanged ? after.id : undefined,
                deleted: false,
            });
        }
        return newState;
    }
    AuthoringState.changeElement = changeElement;
    function changeLink(state, before, after) {
        if (!model_1.sameLink(before, after)) {
            throw new Error('Cannot move link to another element or change its type');
        }
        var newState = clone(state);
        var previous = state.links.get(before);
        newState.links.set(before, {
            type: AuthoringKind.ChangeLink,
            before: previous ? previous.before : undefined,
            after: after,
            deleted: false,
        });
        return newState;
    }
    AuthoringState.changeLink = changeLink;
    function deleteElement(state, model) {
        var newState = clone(state);
        newState.elements.delete(model.id);
        state.links.forEach(function (e) {
            if (isLinkConnectedToElement(e.after, model.id)) {
                newState.links.delete(e.after);
            }
        });
        if (!isNewElement(state, model.id)) {
            newState.elements.set(model.id, {
                type: AuthoringKind.ChangeElement,
                before: model,
                after: model,
                deleted: true,
            });
        }
        return newState;
    }
    AuthoringState.deleteElement = deleteElement;
    function deleteLink(state, target) {
        var newState = clone(state);
        newState.links.delete(target);
        if (!isNewLink(state, target)) {
            newState.links.set(target, {
                type: AuthoringKind.ChangeLink,
                before: target,
                after: target,
                deleted: true,
            });
        }
        return newState;
    }
    AuthoringState.deleteLink = deleteLink;
    function deleteNewLinksConnectedToElements(state, elementIris) {
        var newState = clone(state);
        state.links.forEach(function (e) {
            if (!e.before) {
                var target = e.after;
                if (elementIris.has(target.sourceId) || elementIris.has(target.targetId)) {
                    newState.links.delete(target);
                }
            }
        });
        return newState;
    }
    AuthoringState.deleteNewLinksConnectedToElements = deleteNewLinksConnectedToElements;
    function isNewElement(state, target) {
        var event = state.elements.get(target);
        return event && event.type === AuthoringKind.ChangeElement && !event.before;
    }
    AuthoringState.isNewElement = isNewElement;
    function isDeletedElement(state, target) {
        var event = state.elements.get(target);
        return event && event.deleted;
    }
    AuthoringState.isDeletedElement = isDeletedElement;
    function isElementWithModifiedIri(state, target) {
        var event = state.elements.get(target);
        return event && event.type === AuthoringKind.ChangeElement &&
            event.before && Boolean(event.newIri);
    }
    AuthoringState.isElementWithModifiedIri = isElementWithModifiedIri;
    function isNewLink(state, linkModel) {
        var event = state.links.get(linkModel);
        return event && !event.before;
    }
    AuthoringState.isNewLink = isNewLink;
    function isDeletedLink(state, linkModel) {
        var event = state.links.get(linkModel);
        return event && event.deleted ||
            isDeletedElement(state, linkModel.sourceId) ||
            isDeletedElement(state, linkModel.targetId);
    }
    AuthoringState.isDeletedLink = isDeletedLink;
    function isUncertainLink(state, linkModel) {
        return !isDeletedLink(state, linkModel) && (isElementWithModifiedIri(state, linkModel.sourceId) ||
            isElementWithModifiedIri(state, linkModel.targetId));
    }
    AuthoringState.isUncertainLink = isUncertainLink;
})(AuthoringState = exports.AuthoringState || (exports.AuthoringState = {}));
var TemporaryState;
(function (TemporaryState) {
    TemporaryState.empty = {
        elements: new Map(),
        links: new collections_1.HashMap(model_1.hashLink, model_1.sameLink),
    };
    function addElement(state, element) {
        var elements = collections_1.cloneMap(state.elements);
        elements.set(element.id, element);
        return tslib_1.__assign(tslib_1.__assign({}, state), { elements: elements });
    }
    TemporaryState.addElement = addElement;
    function deleteElement(state, element) {
        var elements = collections_1.cloneMap(state.elements);
        elements.delete(element.id);
        return tslib_1.__assign(tslib_1.__assign({}, state), { elements: elements });
    }
    TemporaryState.deleteElement = deleteElement;
    function addLink(state, link) {
        var links = state.links.clone();
        links.set(link, link);
        return tslib_1.__assign(tslib_1.__assign({}, state), { links: links });
    }
    TemporaryState.addLink = addLink;
    function deleteLink(state, link) {
        var links = state.links.clone();
        links.delete(link);
        return tslib_1.__assign(tslib_1.__assign({}, state), { links: links });
    }
    TemporaryState.deleteLink = deleteLink;
})(TemporaryState = exports.TemporaryState || (exports.TemporaryState = {}));
function isLinkConnectedToElement(link, elementIri) {
    return link.sourceId === elementIri || link.targetId === elementIri;
}
exports.isLinkConnectedToElement = isLinkConnectedToElement;
function updateLinkToReferByNewIri(link, oldIri, newIri) {
    return tslib_1.__assign(tslib_1.__assign({}, link), { sourceId: link.sourceId === oldIri ? newIri : link.sourceId, targetId: link.targetId === oldIri ? newIri : link.targetId });
}

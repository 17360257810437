"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var paper_1 = require("./paper");
var paperArea_1 = require("./paperArea");
var elements_1 = require("./elements");
var elementLayer_1 = require("./elementLayer");
var events_1 = require("../viewUtils/events");
var paperArea_2 = require("./paperArea");
var EmbeddedLayer = /** @class */ (function (_super) {
    tslib_1.__extends(EmbeddedLayer, _super);
    function EmbeddedLayer(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.nestedElementListener = new events_1.EventObserver();
        _this.layerOffsetLeft = 0;
        _this.layerOffsetTop = 0;
        _this.isApplyingParentMove = false;
        _this.isNestedElementMoving = false;
        _this.previousPositions = [];
        _this.moveNestedElements = function (offsetX, offsetY) {
            _this.isApplyingParentMove = true;
            try {
                for (var _i = 0, _a = _this.getNestedElements(); _i < _a.length; _i++) {
                    var element = _a[_i];
                    var _b = element.position, x = _b.x, y = _b.y;
                    var newPosition = { x: x + offsetX, y: y + offsetY };
                    element.setPosition(newPosition);
                }
            }
            finally {
                _this.isApplyingParentMove = false;
                _this.recomputeSelfBounds();
            }
        };
        _this.recomputeSelfBounds = function () {
            if (_this.isApplyingParentMove) {
                return;
            }
            var element = _this.context.ontodiaElement.element;
            var _a = _this.getContentFittingBox(), offsetX = _a.x, offsetY = _a.y, paperWidth = _a.width, paperHeight = _a.height;
            if (_this.isNestedElementMoving) {
                var position = {
                    x: offsetX - _this.layerOffsetLeft,
                    y: offsetY - _this.layerOffsetTop,
                };
                element.setPosition(position);
            }
            _this.setState({ offsetX: offsetX, offsetY: offsetY, paperWidth: paperWidth, paperHeight: paperHeight }, function () { return element.redraw(); });
        };
        _this.onPaperPointerDown = function (e, cell) {
            if (e.button !== 0 /* left mouse button */) {
                return;
            }
            if (cell && cell instanceof elements_1.Element) {
                e.preventDefault();
                _this.isNestedElementMoving = true;
            }
        };
        _this.onLayerInit = function (layer) {
            if (!layer) {
                return;
            }
            var _a = _this.calculateOffset(layer), left = _a.left, top = _a.top;
            _this.layerOffsetLeft = left;
            _this.layerOffsetTop = top;
        };
        _this.state = { paperWidth: 0, paperHeight: 0, offsetX: 0, offsetY: 0 };
        return _this;
    }
    EmbeddedLayer.prototype.componentDidMount = function () {
        var _this = this;
        var element = this.context.ontodiaElement.element;
        var _a = this.context.ontodiaPaperArea, paperArea = _a.paperArea, view = _a.view;
        this.listener.listen(view.model.events, 'changeGroupContent', function (_a) {
            var group = _a.group;
            if (group === element.id) {
                _this.listenNestedElements(_this.getNestedElements());
                var _b = _this.getOffset(), offsetX = _b.offsetX, offsetY = _b.offsetY;
                _this.moveNestedElements(offsetX, offsetY);
            }
        });
        this.listener.listen(element.events, 'changePosition', function () {
            if (_this.isNestedElementMoving) {
                return;
            }
            var _a = _this.getOffset(), offsetX = _a.offsetX, offsetY = _a.offsetY;
            var _b = _this.getContentFittingBox(), x = _b.x, y = _b.y;
            var diffX = offsetX - x;
            var diffY = offsetY - y;
            _this.moveNestedElements(diffX, diffY);
            _this.setState({ offsetX: offsetX, offsetY: offsetY });
        });
        this.listener.listen(paperArea.events, 'pointerUp', function (e) {
            _this.isNestedElementMoving = false;
        });
        var nestedElements = this.getNestedElements();
        this.listenNestedElements(nestedElements);
        if (nestedElements.length > 0) {
            var _b = this.getContentFittingBox(), offsetX = _b.x, offsetY = _b.y, paperWidth = _b.width, paperHeight = _b.height;
            this.setState({ offsetX: offsetX, offsetY: offsetY, paperWidth: paperWidth, paperHeight: paperHeight }, function () { return element.redraw(); });
        }
        else {
            element.requestGroupContent();
        }
    };
    EmbeddedLayer.prototype.listenNestedElements = function (nestedElements) {
        var listener = new events_1.EventObserver();
        for (var _i = 0, nestedElements_1 = nestedElements; _i < nestedElements_1.length; _i++) {
            var nestedElement = nestedElements_1[_i];
            listener.listen(nestedElement.events, 'changePosition', this.recomputeSelfBounds);
            listener.listen(nestedElement.events, 'changeSize', this.recomputeSelfBounds);
        }
        this.nestedElementListener.stopListening();
        this.nestedElementListener = listener;
    };
    EmbeddedLayer.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.nestedElementListener.stopListening();
        this.removeElements();
        this.setState({ paperWidth: 0, paperHeight: 0, offsetX: 0, offsetY: 0 });
    };
    EmbeddedLayer.prototype.getNestedElements = function () {
        var element = this.context.ontodiaElement.element;
        var view = this.context.ontodiaPaperArea.view;
        return view.model.elements.filter(function (el) { return el.group === element.id; });
    };
    EmbeddedLayer.prototype.getContentFittingBox = function () {
        var nestedElements = this.getNestedElements();
        return paperArea_2.getContentFittingBox(nestedElements, []);
    };
    EmbeddedLayer.prototype.removeElements = function () {
        var view = this.context.ontodiaPaperArea.view;
        var batch = view.model.history.startBatch();
        for (var _i = 0, _a = this.getNestedElements(); _i < _a.length; _i++) {
            var element = _a[_i];
            view.model.removeElement(element.id);
        }
        batch.discard();
    };
    EmbeddedLayer.prototype.getOffset = function () {
        var element = this.context.ontodiaElement.element;
        var _a = element.position, elementX = _a.x, elementY = _a.y;
        var offsetX = elementX + this.layerOffsetLeft;
        var offsetY = elementY + this.layerOffsetTop;
        return { offsetX: offsetX, offsetY: offsetY };
    };
    EmbeddedLayer.prototype.calculateOffset = function (layer) {
        var paperArea = this.context.ontodiaPaperArea.paperArea;
        var scale = paperArea.getScale();
        var parent = findParentElement(layer);
        var _a = layer.getBoundingClientRect(), left = _a.left, top = _a.top;
        var _b = parent.getBoundingClientRect(), parentLeft = _b.left, parentTop = _b.top;
        return { left: (left - parentLeft) / scale, top: (top - parentTop) / scale };
    };
    EmbeddedLayer.prototype.render = function () {
        var element = this.context.ontodiaElement.element;
        var view = this.context.ontodiaPaperArea.view;
        var _a = this.state, paperWidth = _a.paperWidth, paperHeight = _a.paperHeight, offsetX = _a.offsetX, offsetY = _a.offsetY;
        var paperTransform = {
            width: paperWidth,
            height: paperHeight,
            originX: -offsetX,
            originY: -offsetY,
            scale: 1,
            paddingX: 0,
            paddingY: 0,
        };
        return (React.createElement("div", { className: 'ontodia-embedded-layer', ref: this.onLayerInit },
            React.createElement(paper_1.Paper, { view: view, paperTransform: paperTransform, onPointerDown: this.onPaperPointerDown, group: element.id })));
    };
    EmbeddedLayer.contextTypes = tslib_1.__assign(tslib_1.__assign({}, elementLayer_1.ElementContextTypes), paperArea_1.PaperAreaContextTypes);
    return EmbeddedLayer;
}(React.Component));
exports.EmbeddedLayer = EmbeddedLayer;
function findParentElement(layer) {
    var parent = layer.parentElement;
    if (!parent) {
        throw new Error('Cannot find parent diagram element for EmbeddedLayer');
    }
    else if (parent.hasAttribute('data-element-id')) {
        return parent;
    }
    else {
        return findParentElement(parent);
    }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mergeUtils_1 = require("./mergeUtils");
function isDefinition(dp) {
    var definition = dp;
    return definition.name !== undefined && definition.dataProvider !== undefined;
}
var CompositeDataProvider = /** @class */ (function () {
    function CompositeDataProvider(dataProviders, params) {
        this.mergeMode = 'fetchAll';
        var dpCounter = 1;
        this.dataProviders = dataProviders.map(function (dp) {
            if (isDefinition(dp)) {
                return dp;
            }
            else {
                return {
                    name: 'dataProvider_' + dpCounter++,
                    dataProvider: dp,
                };
            }
        });
        if (params && params.mergeMode) {
            this.mergeMode = params.mergeMode;
        }
    }
    CompositeDataProvider.prototype.classTree = function () {
        return this.fetchSequentially('classTree', mergeUtils_1.mergeClassTree, undefined);
    };
    CompositeDataProvider.prototype.propertyInfo = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('propertyInfo', mergeUtils_1.mergePropertyInfo, params);
        }
        else {
            var propertyIds_1 = params.propertyIds;
            return this.queueProcessResults(function (previousResult, dp) {
                propertyIds_1 = propertyIds_1.filter(function (id) { return !previousResult || !previousResult[id]; });
                return propertyIds_1.length > 0 ? dp.dataProvider.propertyInfo({ propertyIds: propertyIds_1 }) : undefined;
            }).then(mergeUtils_1.mergePropertyInfo);
        }
    };
    CompositeDataProvider.prototype.classInfo = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('classInfo', mergeUtils_1.mergeClassInfo, params);
        }
        else {
            var classIds_1 = params.classIds;
            return this.queueProcessResults(function (previousResult, dp) {
                classIds_1 = classIds_1.filter(function (id) { return !previousResult || previousResult.map(function (cm) { return cm.id; }).indexOf(id) === -1; });
                return classIds_1.length > 0 ? dp.dataProvider.classInfo({ classIds: classIds_1 }) : undefined;
            }).then(mergeUtils_1.mergeClassInfo);
        }
    };
    CompositeDataProvider.prototype.linkTypesInfo = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('linkTypesInfo', mergeUtils_1.mergeLinkTypesInfo, params);
        }
        else {
            var linkTypeIds_1 = params.linkTypeIds;
            return this.queueProcessResults(function (previousResult, dp) {
                linkTypeIds_1 = linkTypeIds_1.filter(function (id) {
                    return !previousResult || previousResult.map(function (lt) { return lt.id; }).indexOf(id) === -1;
                });
                return linkTypeIds_1.length > 0 ? dp.dataProvider.linkTypesInfo({ linkTypeIds: linkTypeIds_1 }) : undefined;
            }).then(mergeUtils_1.mergeLinkTypesInfo);
        }
    };
    CompositeDataProvider.prototype.linkTypes = function () {
        return this.fetchSequentially('linkTypes', mergeUtils_1.mergeLinkTypes, undefined);
    };
    CompositeDataProvider.prototype.elementInfo = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('elementInfo', mergeUtils_1.mergeElementInfo, params);
        }
        else {
            var elementIds_1 = params.elementIds;
            return this.queueProcessResults(function (previousResult, dp) {
                elementIds_1 = elementIds_1.filter(function (id) { return !previousResult || !previousResult[id]; });
                return elementIds_1.length > 0 ? dp.dataProvider.elementInfo({ elementIds: elementIds_1 }) : undefined;
            }).then(mergeUtils_1.mergeElementInfo);
        }
    };
    CompositeDataProvider.prototype.linksInfo = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('linksInfo', mergeUtils_1.mergeLinksInfo, params);
        }
        else {
            var elementIds_2 = params.elementIds;
            return this.queueProcessResults(function (previousResult, dp) {
                elementIds_2 = elementIds_2.filter(function (id) {
                    if (previousResult) {
                        for (var _i = 0, previousResult_1 = previousResult; _i < previousResult_1.length; _i++) {
                            var linkModel = previousResult_1[_i];
                            if (linkModel.sourceId === id) {
                                return false;
                            }
                        }
                    }
                    return true;
                });
                return elementIds_2.length > 0 ?
                    dp.dataProvider.linksInfo({ elementIds: elementIds_2, linkTypeIds: params.linkTypeIds }) : undefined;
            }).then(mergeUtils_1.mergeLinksInfo);
        }
    };
    CompositeDataProvider.prototype.linkTypesOf = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('linkTypesOf', mergeUtils_1.mergeLinkTypesOf, params);
        }
        else {
            return this.queueProcessResults(function (previousResult, dp) {
                if (!previousResult || previousResult && previousResult.length === 0) {
                    return dp.dataProvider.linkTypesOf(params);
                }
                else {
                    return undefined;
                }
            }).then(mergeUtils_1.mergeLinkTypesOf);
        }
    };
    CompositeDataProvider.prototype.linkElements = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('linkElements', mergeUtils_1.mergeLinkElements, params);
        }
        else {
            return this.queueProcessResults(function (previousResult, dp) {
                if (!previousResult || previousResult && Object.keys(previousResult).length === 0) {
                    return dp.dataProvider.linkElements(params);
                }
                else {
                    return undefined;
                }
            }).then(mergeUtils_1.mergeLinkElements);
        }
    };
    CompositeDataProvider.prototype.filter = function (params) {
        if (this.mergeMode === 'fetchAll') {
            return this.fetchSequentially('filter', mergeUtils_1.mergeFilter, params);
        }
        else {
            return this.queueProcessResults(function (previousResult, dp) {
                if (!previousResult || previousResult && Object.keys(previousResult).length === 0) {
                    return dp.dataProvider.filter(params);
                }
                else {
                    return undefined;
                }
            }).then(mergeUtils_1.mergeFilter);
        }
    };
    CompositeDataProvider.prototype.processResults = function (responsePromise, dpName, useProviderInStats) {
        return responsePromise
            .then(function (response) { return ({ dataSourceName: dpName, useInStats: useProviderInStats, response: response }); })
            .catch(function (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
            return { dataSourceName: dpName, useInStats: useProviderInStats, response: undefined };
        });
    };
    CompositeDataProvider.prototype.queueProcessResults = function (callBack) {
        var _this = this;
        var counter = 0;
        var responseList = [];
        var recursiveCall = function (result) {
            if (_this.dataProviders.length > counter) {
                var dp_1 = _this.dataProviders[counter++];
                var callBackResult = callBack(result, dp_1);
                if (!callBackResult) {
                    return Promise.resolve(responseList);
                }
                return callBackResult.then(function (newResult) {
                    responseList.push({
                        dataSourceName: dp_1.name,
                        response: newResult,
                    });
                    return recursiveCall(newResult);
                }).catch(function (error) {
                    // tslint:disable-next-line:no-console
                    console.error(error);
                    return recursiveCall(result);
                });
            }
            else {
                return Promise.resolve(responseList);
            }
        };
        return recursiveCall();
    };
    CompositeDataProvider.prototype.fetchSequentially = function (functionName, mergeFunction, params) {
        var _this = this;
        var resultPromises = this.dataProviders.map(function (dp) {
            var providerMethod = dp.dataProvider[functionName];
            return _this.processResults(providerMethod.call(dp.dataProvider, params), dp.name, dp.useInStats);
        });
        return Promise.all(resultPromises).then(mergeFunction);
    };
    return CompositeDataProvider;
}());
exports.CompositeDataProvider = CompositeDataProvider;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rdf_ext_1 = require("rdf-ext");
var lodash_1 = require("lodash");
var DEFAULT_STORAGE_TYPE = 'text/turtle';
var DEFAULT_STORAGE_URI = 'http://ontodia.org/defaultGraph';
function prefixFactory(prefix) {
    var lastSymbol = prefix[prefix.length - 1];
    var _prefix = lastSymbol === '/' || lastSymbol === '#' ? prefix : prefix + '/';
    return function (id) {
        return _prefix + id;
    };
}
exports.prefixFactory = prefixFactory;
function isLiteral(el) {
    return el.interfaceName === 'Literal';
}
exports.isLiteral = isLiteral;
function isNamedNode(el) {
    return el.interfaceName === 'NamedNode';
}
exports.isNamedNode = isNamedNode;
exports.LABEL_URIS = [
    'http://www.w3.org/2004/02/skos/core#prefLabel',
    'http://www.w3.org/2004/02/skos/core#label',
    'http://www.w3.org/2004/02/skos/core#altLabel',
    'http://www.w3.org/2000/01/rdf-schema#prefLabel',
    'http://www.w3.org/2000/01/rdf-schema#label',
    'http://xmlns.com/foaf/0.1/name',
    'http://schema.org/name',
];
exports.LABEL_POSTFIXES = [
    'prefLabel',
    'prefName',
    'label',
    'name',
    'title',
];
function isLabelType(predicateIri) {
    var isLabelIri = exports.LABEL_URIS.indexOf(predicateIri) !== -1;
    var type = predicateIri.toLocaleLowerCase();
    var looksLikeLabel = Boolean(exports.LABEL_POSTFIXES.find(function (value, index, array) {
        var postfix = value.toLocaleLowerCase();
        return type.indexOf(postfix) !== -1;
    }));
    return isLabelIri || looksLikeLabel;
}
exports.isLabelType = isLabelType;
var RDFCacheableStore = /** @class */ (function () {
    function RDFCacheableStore(options) {
        this.checkingElementMap = {};
        this.labelsMap = {};
        this.countMap = {};
        this.elementTypes = {};
        this.parser = options.parser;
        this.acceptBlankNodes = Boolean(options.acceptBlankNodes);
        this.rdfStorage = rdf_ext_1.createStore();
        this.prefs = {
            RDF: prefixFactory('http://www.w3.org/1999/02/22-rdf-syntax-ns#'),
            RDFS: prefixFactory('http://www.w3.org/2000/01/rdf-schema#'),
            FOAF: prefixFactory('http://xmlns.com/foaf/0.1/'),
            XSD: prefixFactory('http://www.w3.org/2001/XMLSchema#'),
            OWL: prefixFactory('http://www.w3.org/2002/07/owl#'),
        };
    }
    RDFCacheableStore.prototype.add = function (rdfGraph, prefix) {
        this.rdfStorage.add(lodash_1.uniqueId(prefix) || (lodash_1.uniqueId(DEFAULT_STORAGE_URI)), rdfGraph);
        this.enrichMaps(rdfGraph);
    };
    RDFCacheableStore.prototype.match = function (subject, predicate, object, iri, callback, limit) {
        if (subject && (exports.LABEL_URIS.indexOf(predicate) !== -1) && !object) {
            return Promise.resolve(this._getLabels(subject));
        }
        else if (subject && predicate === this.prefs.RDF('type') && !object) {
            return Promise.resolve(this.getTypes(subject));
        }
        else {
            return this.rdfStorage.match(subject, predicate, object, iri, callback, limit);
        }
    };
    RDFCacheableStore.prototype.matchAll = function (statements) {
        var _this = this;
        var slowQueries = [];
        var queries = [];
        statements.forEach(function (statement) {
            if (statement.subject && (exports.LABEL_URIS.indexOf(statement.predicate) !== -1) && !statement.object) {
                queries.push(_this._getLabels(statement.subject));
            }
            else if (statement.subject && statement.predicate === _this.prefs.RDF('type') && !statement.object) {
                queries.push(_this.getTypes(statement.subject));
            }
            else {
                slowQueries.push(statement);
            }
        });
        queries.push(this.multipleMatch(slowQueries));
        return Promise.resolve(this.combineGraphs(queries));
    };
    RDFCacheableStore.prototype.getLabels = function (id) {
        return Promise.resolve(rdf_ext_1.createGraph(this.labelsMap[id]));
    };
    // Checks whetger the element is in the storage.
    RDFCacheableStore.prototype.checkElement = function (id) {
        if (this.labelsMap[id]) { // if there is label for an id then the element is already fetched
            return Promise.resolve(true);
        }
        else {
            if (!this.checkingElementMap[id]) {
                this.checkingElementMap[id] = this.rdfStorage.match(id, null, null).then(function (result) {
                    var resultArray = result.toArray();
                    return resultArray.length !== 0;
                });
            }
            return this.checkingElementMap[id];
        }
    };
    RDFCacheableStore.prototype.getTypeCount = function (id) {
        return this.countMap[id] || 0;
    };
    RDFCacheableStore.prototype.enrichMaps = function (newGraph) {
        var _this = this;
        var triples = newGraph.toArray();
        // Deal with labels
        for (var _i = 0, triples_1 = triples; _i < triples_1.length; _i++) {
            var triple = triples_1[_i];
            var element = triple.subject.nominalValue;
            var predicate = triple.predicate.nominalValue;
            if (isLabelType(predicate)) {
                if (!this.labelsMap[element]) {
                    this.labelsMap[element] = [];
                }
                if (isLiteral(triple.object)) {
                    this.labelsMap[element].push(triple);
                    this.labelsMap[element].sort(function (a, b) {
                        var index1 = exports.LABEL_URIS.indexOf(a.predicate.nominalValue);
                        var index2 = exports.LABEL_URIS.indexOf(b.predicate.nominalValue);
                        if (index1 > index2) {
                            return 1;
                        }
                        else if (index1 < index2) {
                            return -1;
                        }
                        else {
                            return 0;
                        }
                    });
                }
            }
        }
        // Deal with counts
        var typeInstances = newGraph.match(null, this.prefs.RDF('type'), null).toArray();
        var typeInstMap = {};
        for (var _a = 0, typeInstances_1 = typeInstances; _a < typeInstances_1.length; _a++) {
            var instTriple = typeInstances_1[_a];
            var type = instTriple.object.nominalValue;
            var inst = instTriple.subject.nominalValue;
            if (!typeInstMap[type]) {
                typeInstMap[type] = [];
            }
            if (this.acceptBlankNodes || isNamedNode(instTriple.subject)) {
                if (!this.elementTypes[inst]) {
                    this.elementTypes[inst] = [];
                }
                if (typeInstMap[type].indexOf(inst) === -1) {
                    typeInstMap[type].push(inst);
                }
                this.elementTypes[inst].push(instTriple);
            }
        }
        Object.keys(typeInstMap).forEach(function (key) { return _this.countMap[key] = typeInstMap[key].length; });
        return true;
    };
    RDFCacheableStore.prototype._getLabels = function (id) {
        return rdf_ext_1.createGraph(this.labelsMap[id]);
    };
    RDFCacheableStore.prototype.getTypes = function (id) {
        return rdf_ext_1.createGraph(this.elementTypes[id]);
    };
    RDFCacheableStore.prototype.combineGraphs = function (graphs) {
        var triples = [];
        for (var _i = 0, graphs_1 = graphs; _i < graphs_1.length; _i++) {
            var graph = graphs_1[_i];
            for (var _a = 0, _b = graph.toArray(); _a < _b.length; _a++) {
                var triple = _b[_a];
                triples.push(triple);
            }
        }
        return rdf_ext_1.createGraph(triples);
    };
    RDFCacheableStore.prototype.multipleMatch = function (statements) {
        var _this = this;
        var triples = [];
        var graphs = Object.keys(this.rdfStorage.graphs).map(function (id) { return _this.rdfStorage.graphs[id]; });
        for (var _i = 0, graphs_2 = graphs; _i < graphs_2.length; _i++) {
            var graph = graphs_2[_i];
            for (var _a = 0, _b = graph._graph; _a < _b.length; _a++) {
                var triple = _b[_a];
                for (var _c = 0, statements_1 = statements; _c < statements_1.length; _c++) {
                    var statement = statements_1[_c];
                    if (((!statement.object) || statement.object === triple.object.nominalValue) &&
                        ((!statement.predicate) || statement.predicate === triple.predicate.nominalValue) &&
                        ((!statement.subject) || statement.subject === triple.subject.nominalValue)) {
                        triples.push(triple);
                        continue;
                    }
                }
            }
        }
        return rdf_ext_1.createGraph(triples);
    };
    return RDFCacheableStore;
}());
exports.RDFCacheableStore = RDFCacheableStore;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var schema_1 = require("../data/schema");
var events_1 = require("../viewUtils/events");
var elements_1 = require("./elements");
var graph_1 = require("./graph");
var history_1 = require("./history");
/**
 * Model of diagram.
 */
var DiagramModel = /** @class */ (function () {
    function DiagramModel(history) {
        this.history = history;
        this.source = new events_1.EventSource();
        this.events = this.source;
        this.graph = new graph_1.Graph();
        this.graphListener = new events_1.EventObserver();
    }
    Object.defineProperty(DiagramModel.prototype, "elements", {
        get: function () { return this.graph.getElements(); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DiagramModel.prototype, "links", {
        get: function () { return this.graph.getLinks(); },
        enumerable: true,
        configurable: true
    });
    DiagramModel.prototype.getElement = function (elementId) {
        return this.graph.getElement(elementId);
    };
    DiagramModel.prototype.getLinkById = function (linkId) {
        return this.graph.getLink(linkId);
    };
    DiagramModel.prototype.linksOfType = function (linkTypeId) {
        return this.graph.getLinks().filter(function (link) { return link.typeId === linkTypeId; });
    };
    DiagramModel.prototype.findLink = function (linkTypeId, sourceId, targetId) {
        return this.graph.findLink(linkTypeId, sourceId, targetId);
    };
    DiagramModel.prototype.sourceOf = function (link) { return this.getElement(link.sourceId); };
    DiagramModel.prototype.targetOf = function (link) { return this.getElement(link.targetId); };
    DiagramModel.prototype.isSourceAndTargetVisible = function (link) {
        return Boolean(this.sourceOf(link) && this.targetOf(link));
    };
    DiagramModel.prototype.resetGraph = function () {
        if (this.graphListener) {
            this.graphListener.stopListening();
            this.graphListener = new events_1.EventObserver();
        }
        this.graph = new graph_1.Graph();
    };
    DiagramModel.prototype.subscribeGraph = function () {
        var _this = this;
        this.graphListener.listen(this.graph.events, 'changeCells', function (e) {
            _this.source.trigger('changeCells', e);
        });
        this.graphListener.listen(this.graph.events, 'elementEvent', function (e) {
            _this.source.trigger('elementEvent', e);
        });
        this.graphListener.listen(this.graph.events, 'linkEvent', function (e) {
            _this.source.trigger('linkEvent', e);
        });
        this.graphListener.listen(this.graph.events, 'linkTypeEvent', function (e) {
            _this.source.trigger('linkTypeEvent', e);
        });
        this.graphListener.listen(this.graph.events, 'classEvent', function (e) {
            _this.source.trigger('classEvent', e);
        });
        this.source.trigger('changeCells', { updateAll: true });
    };
    DiagramModel.prototype.reorderElements = function (compare) {
        this.graph.reorderElements(compare);
    };
    DiagramModel.prototype.createElement = function (elementIriOrModel, group) {
        var elementIri = typeof elementIriOrModel === 'string'
            ? elementIriOrModel : elementIriOrModel.id;
        var elements = this.elements.filter(function (el) { return el.iri === elementIri && el.group === group; });
        if (elements.length > 0) {
            // usually there should be only one element
            return elements[0];
        }
        var data = typeof elementIriOrModel === 'string'
            ? placeholderDataFromIri(elementIri)
            : elementIriOrModel;
        data = tslib_1.__assign(tslib_1.__assign({}, data), { id: data.id });
        var element = new elements_1.Element({ id: schema_1.GenerateID.forElement(), data: data, group: group });
        this.addElement(element);
        return element;
    };
    DiagramModel.prototype.addElement = function (element) {
        this.history.execute(addElement(this.graph, element, []));
    };
    DiagramModel.prototype.removeElement = function (elementId) {
        var element = this.getElement(elementId);
        if (element) {
            this.history.execute(removeElement(this.graph, element));
        }
    };
    DiagramModel.prototype.addLink = function (link) {
        var typeId = link.typeId, sourceId = link.sourceId, targetId = link.targetId, data = link.data;
        if (data && data.linkTypeId !== typeId) {
            throw new Error('linkTypeId must match linkType.id');
        }
        var existingLink = this.findLink(typeId, sourceId, targetId);
        if (existingLink) {
            if (link.data) {
                existingLink.setLayoutOnly(false);
                existingLink.setData(data);
            }
            return existingLink;
        }
        var linkType = this.createLinkType(link.typeId);
        var source = this.getElement(sourceId);
        var target = this.getElement(targetId);
        var shouldBeVisible = linkType.visible && source && target;
        if (!shouldBeVisible) {
            return undefined;
        }
        if (!link.data) {
            link.setData({ linkTypeId: typeId, sourceId: source.iri, targetId: target.iri });
        }
        this.graph.addLink(link);
        return link;
    };
    DiagramModel.prototype.removeLink = function (linkId) {
        this.graph.removeLink(linkId);
    };
    DiagramModel.prototype.getClass = function (classIri) {
        return this.graph.getClass(classIri);
    };
    DiagramModel.prototype.createClass = function (classIri) {
        var existing = this.graph.getClass(classIri);
        if (existing) {
            return existing;
        }
        var classModel = new elements_1.FatClassModel({ id: classIri });
        this.addClass(classModel);
        return classModel;
    };
    DiagramModel.prototype.addClass = function (model) {
        this.graph.addClass(model);
    };
    DiagramModel.prototype.getLinkType = function (linkTypeIri) {
        return this.graph.getLinkType(linkTypeIri);
    };
    DiagramModel.prototype.createLinkType = function (linkTypeIri) {
        var existing = this.graph.getLinkType(linkTypeIri);
        if (existing) {
            return existing;
        }
        var linkType = new elements_1.FatLinkType({ id: linkTypeIri });
        this.graph.addLinkType(linkType);
        return linkType;
    };
    DiagramModel.prototype.getProperty = function (propertyTypeIri) {
        return this.graph.getProperty(propertyTypeIri);
    };
    DiagramModel.prototype.createProperty = function (propertyIri) {
        var existing = this.graph.getProperty(propertyIri);
        if (existing) {
            return existing;
        }
        var property = new elements_1.RichProperty({ id: propertyIri });
        this.graph.addProperty(property);
        return property;
    };
    DiagramModel.prototype.triggerChangeGroupContent = function (group) {
        this.source.trigger('changeGroupContent', { group: group });
    };
    DiagramModel.prototype.createTemporaryElement = function () {
        var target = new elements_1.Element({
            id: schema_1.GenerateID.forElement(),
            data: placeholderDataFromIri(''),
            temporary: true,
        });
        this.graph.addElement(target);
        return target;
    };
    return DiagramModel;
}());
exports.DiagramModel = DiagramModel;
function placeholderDataFromIri(iri) {
    return {
        id: iri,
        types: [],
        label: { values: [] },
        properties: {},
    };
}
exports.placeholderDataFromIri = placeholderDataFromIri;
function addElement(graph, element, connectedLinks) {
    return history_1.Command.create('Add element', function () {
        graph.addElement(element);
        for (var _i = 0, connectedLinks_1 = connectedLinks; _i < connectedLinks_1.length; _i++) {
            var link = connectedLinks_1[_i];
            var existing = graph.getLink(link.id) || graph.findLink(link.typeId, link.sourceId, link.targetId);
            if (!existing) {
                graph.addLink(link);
            }
        }
        return removeElement(graph, element);
    });
}
function removeElement(graph, element) {
    return history_1.Command.create('Remove element', function () {
        var connectedLinks = tslib_1.__spreadArrays(element.links);
        graph.removeElement(element.id);
        return addElement(graph, element, connectedLinks);
    });
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rdfCacheableStore_1 = require("./rdfCacheableStore");
var rdfLoader_1 = require("./rdfLoader");
var rdfCompositeParser_1 = require("./rdfCompositeParser");
var PREFIX_FACTORIES = {
    RDF: rdfCacheableStore_1.prefixFactory('http://www.w3.org/1999/02/22-rdf-syntax-ns#'),
    RDFS: rdfCacheableStore_1.prefixFactory('http://www.w3.org/2000/01/rdf-schema#'),
    FOAF: rdfCacheableStore_1.prefixFactory('http://xmlns.com/foaf/0.1/'),
    XSD: rdfCacheableStore_1.prefixFactory('http://www.w3.org/2001/XMLSchema#'),
    OWL: rdfCacheableStore_1.prefixFactory('http://www.w3.org/2002/07/owl#'),
};
var RDFDataProvider = /** @class */ (function () {
    function RDFDataProvider(options) {
        var _this = this;
        var parser = new rdfCompositeParser_1.RDFCompositeParser(options.parsers);
        this.rdfStorage = new rdfCacheableStore_1.RDFCacheableStore({
            parser: parser,
            acceptBlankNodes: options.acceptBlankNodes,
        });
        this.rdfLoader = new rdfLoader_1.RDFLoader({
            parser: parser,
            proxy: options.proxy,
        });
        this.dataFetching = options.dataFetching;
        var parsePromises = (options.data || []).map(function (datum) {
            return parser.parse(datum.content, datum.type, datum.fileName)
                .then(function (rdfGraph) {
                _this.rdfStorage.add(rdfGraph);
                return true;
            });
        });
        this.initStatement = Promise.all(parsePromises).catch(function (error) {
            error.message = 'Initialization failed! Cause: ' + error.message;
            throw error;
        });
        this.options = options;
    }
    RDFDataProvider.prototype.addGraph = function (graph) {
        this.rdfStorage.add(graph);
    };
    RDFDataProvider.prototype.waitInitCompleted = function () {
        var _this = this;
        if (this.initStatement) {
            return this.initStatement.then(function () {
                delete _this.initStatement;
            });
        }
        else {
            return Promise.resolve();
        }
    };
    RDFDataProvider.prototype.classTree = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rdfClassesQuery, owlClassesQuery, fromRDFTypesQuery, subClassesQuery, _a, rdfClassesGraph, owlClassesGraph, rdfTypesGraph, subClassesGraph, rdfClasses, owlClasses, rdfTypes, subClasses, classes, classIris, parents, _i, subClasses_1, triple, subClassIRI, classIRI, dictionary, firstLevel, labelQueries, _loop_1, this_1, _b, classIris_1, classIri, result;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _c.sent();
                        rdfClassesQuery = this.rdfStorage.match(null, PREFIX_FACTORIES.RDF('type'), PREFIX_FACTORIES.RDFS('Class'), null);
                        owlClassesQuery = this.rdfStorage.match(null, PREFIX_FACTORIES.RDF('type'), PREFIX_FACTORIES.OWL('Class'));
                        fromRDFTypesQuery = this.rdfStorage.match(null, PREFIX_FACTORIES.RDF('type'), null);
                        subClassesQuery = this.rdfStorage.match(null, PREFIX_FACTORIES.RDFS('subClassOf'), null);
                        return [4 /*yield*/, Promise.all([
                                rdfClassesQuery,
                                owlClassesQuery,
                                fromRDFTypesQuery,
                                subClassesQuery,
                            ])];
                    case 2:
                        _a = _c.sent(), rdfClassesGraph = _a[0], owlClassesGraph = _a[1], rdfTypesGraph = _a[2], subClassesGraph = _a[3];
                        rdfClasses = rdfClassesGraph.toArray().map(function (cl) { return cl.subject; });
                        owlClasses = owlClassesGraph.toArray().map(function (cl) { return cl.subject; });
                        rdfTypes = rdfTypesGraph.toArray().map(function (cl) { return cl.object; });
                        subClasses = subClassesGraph.toArray();
                        classes = rdfClasses.concat(owlClasses).concat(rdfTypes);
                        classIris = classes
                            .filter(function (clazz) { return clazz.interfaceName !== 'BlankNode'; })
                            .map(function (clazz) { return clazz.nominalValue; });
                        parents = {};
                        for (_i = 0, subClasses_1 = subClasses; _i < subClasses_1.length; _i++) {
                            triple = subClasses_1[_i];
                            subClassIRI = triple.subject.nominalValue;
                            classIRI = triple.object.nominalValue;
                            if (rdfCacheableStore_1.isNamedNode(triple.subject) && !parents[subClassIRI]) {
                                parents[subClassIRI] = [];
                            }
                            if (rdfCacheableStore_1.isNamedNode(triple.object) && parents[subClassIRI].indexOf(classIRI) === -1) {
                                parents[subClassIRI].push(classIRI);
                            }
                        }
                        dictionary = {};
                        firstLevel = {};
                        labelQueries = [];
                        _loop_1 = function (classIri) {
                            var classElement;
                            if (!dictionary[classIri]) {
                                classElement = this_1.createEmptyClass(classIri);
                                dictionary[classIri] = classElement;
                                firstLevel[classIri] = classElement;
                                labelQueries.push(this_1.getLabels(classIri).then(function (labels) {
                                    classElement.label = { values: labels };
                                }));
                            }
                            else {
                                classElement = dictionary[classIri];
                            }
                            if (parents[classIri]) {
                                var _loop_2 = function (parentIri) {
                                    if (!dictionary[parentIri]) {
                                        var parentElement_1 = this_1.createEmptyClass(parentIri);
                                        dictionary[parentIri] = parentElement_1;
                                        firstLevel[parentIri] = parentElement_1;
                                        labelQueries.push(this_1.getLabels(parentIri).then(function (labels) {
                                            parentElement_1.label = { values: labels };
                                        }));
                                    }
                                    if (dictionary[parentIri].children.indexOf(classElement) === -1) {
                                        dictionary[parentIri].children.push(classElement);
                                        dictionary[parentIri].count += classElement.count;
                                    }
                                    delete firstLevel[classElement.id];
                                };
                                for (var _i = 0, _a = parents[classIri]; _i < _a.length; _i++) {
                                    var parentIri = _a[_i];
                                    _loop_2(parentIri);
                                }
                            }
                        };
                        this_1 = this;
                        for (_b = 0, classIris_1 = classIris; _b < classIris_1.length; _b++) {
                            classIri = classIris_1[_b];
                            _loop_1(classIri);
                        }
                        return [4 /*yield*/, Promise.all(labelQueries)];
                    case 3:
                        _c.sent();
                        result = Object.keys(firstLevel).map(function (k) { return firstLevel[k]; });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    RDFDataProvider.prototype.propertyInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var propertyInfoResult, queries, fetchedModels, _i, fetchedModels_1, model;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        propertyInfoResult = {};
                        queries = params.propertyIds.map(function (propId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var isExists, labels, error_1;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, this.checkElement(propId)];
                                    case 1:
                                        isExists = _a.sent();
                                        return [4 /*yield*/, this.fetchIfNecessary(propId, isExists)];
                                    case 2:
                                        _a.sent();
                                        return [4 /*yield*/, this.getLabels(propId)];
                                    case 3:
                                        labels = _a.sent();
                                        return [2 /*return*/, {
                                                id: propId,
                                                label: { values: labels },
                                            }];
                                    case 4:
                                        error_1 = _a.sent();
                                        // tslint:disable-next-line:no-console
                                        console.warn(error_1);
                                        return [2 /*return*/, null];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(queries)];
                    case 2:
                        fetchedModels = _a.sent();
                        for (_i = 0, fetchedModels_1 = fetchedModels; _i < fetchedModels_1.length; _i++) {
                            model = fetchedModels_1[_i];
                            if (model) {
                                propertyInfoResult[model.id] = model;
                            }
                        }
                        return [2 /*return*/, propertyInfoResult];
                }
            });
        });
    };
    RDFDataProvider.prototype.classInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queries, fetchedModels;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        queries = params.classIds.map(function (classId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var isExists, labels, error_2;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, this.checkElement(classId)];
                                    case 1:
                                        isExists = _a.sent();
                                        return [4 /*yield*/, this.fetchIfNecessary(classId, isExists)];
                                    case 2:
                                        _a.sent();
                                        return [4 /*yield*/, this.getLabels(classId)];
                                    case 3:
                                        labels = _a.sent();
                                        return [2 /*return*/, {
                                                id: classId,
                                                label: { values: labels },
                                                count: this.rdfStorage.getTypeCount(classId),
                                                children: [],
                                            }];
                                    case 4:
                                        error_2 = _a.sent();
                                        // tslint:disable-next-line:no-console
                                        console.warn(error_2);
                                        return [2 /*return*/, null];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(queries)];
                    case 2:
                        fetchedModels = _a.sent();
                        return [2 /*return*/, fetchedModels.filter(function (cm) { return cm; })];
                }
            });
        });
    };
    RDFDataProvider.prototype.linkTypesInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queries, fetchedModels;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        queries = params.linkTypeIds.map(function (typeId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var isExists, labels, error_3;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, this.rdfStorage.checkElement(typeId)];
                                    case 1:
                                        isExists = _a.sent();
                                        return [4 /*yield*/, this.fetchIfNecessary(typeId, isExists)];
                                    case 2:
                                        _a.sent();
                                        return [4 /*yield*/, this.getLabels(typeId)];
                                    case 3:
                                        labels = _a.sent();
                                        return [2 /*return*/, {
                                                id: typeId,
                                                label: { values: labels },
                                                count: this.rdfStorage.getTypeCount(typeId),
                                            }];
                                    case 4:
                                        error_3 = _a.sent();
                                        // tslint:disable-next-line:no-console
                                        console.warn(error_3);
                                        return [2 /*return*/, null];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(queries)];
                    case 2:
                        fetchedModels = _a.sent();
                        return [2 /*return*/, fetchedModels.filter(function (lt) { return lt; })];
                }
            });
        });
    };
    RDFDataProvider.prototype.linkTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var linkTypes, rdfLinksQueries, owlLinksQueries, _a, rdfLinks, owlLinks, linkTypeIds, mapLinkTriple, linkTypePromises, _i, _b, linkTriple, _c, _d, linkTriple;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _e.sent();
                        linkTypes = [];
                        rdfLinksQueries = this.rdfStorage.match(undefined, PREFIX_FACTORIES.RDF('type'), PREFIX_FACTORIES.RDF('Property'));
                        owlLinksQueries = this.rdfStorage.match(undefined, PREFIX_FACTORIES.RDF('type'), PREFIX_FACTORIES.OWL('ObjectProperty'));
                        return [4 /*yield*/, Promise.all([rdfLinksQueries, owlLinksQueries])];
                    case 2:
                        _a = _e.sent(), rdfLinks = _a[0], owlLinks = _a[1];
                        linkTypeIds = new Set();
                        mapLinkTriple = function (triple) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var labels;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!!linkTypeIds.has(triple.subject.nominalValue)) return [3 /*break*/, 2];
                                        linkTypeIds.add(triple.subject.nominalValue);
                                        return [4 /*yield*/, this.getLabels(triple.subject.nominalValue)];
                                    case 1:
                                        labels = _a.sent();
                                        linkTypes.push({
                                            id: triple.subject.nominalValue,
                                            label: { values: labels },
                                            count: this.rdfStorage.getTypeCount(triple.subject.nominalValue),
                                        });
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); };
                        linkTypePromises = [];
                        for (_i = 0, _b = rdfLinks.toArray(); _i < _b.length; _i++) {
                            linkTriple = _b[_i];
                            linkTypePromises.push(mapLinkTriple(linkTriple));
                        }
                        for (_c = 0, _d = owlLinks.toArray(); _c < _d.length; _c++) {
                            linkTriple = _d[_c];
                            linkTypePromises.push(mapLinkTriple(linkTriple));
                        }
                        return [4 /*yield*/, Promise.all(linkTypePromises)];
                    case 3:
                        _e.sent();
                        return [2 /*return*/, linkTypes];
                }
            });
        });
    };
    RDFDataProvider.prototype.elementInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var elementInfoResult, queries, fetchedModels, _i, fetchedModels_2, model;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        elementInfoResult = {};
                        queries = params.elementIds.map(function (elementId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var isExists, error_4;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 3, , 4]);
                                        return [4 /*yield*/, this.rdfStorage.checkElement(elementId)];
                                    case 1:
                                        isExists = _a.sent();
                                        return [4 /*yield*/, this.fetchIfNecessary(elementId, isExists)];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/, this.getElementInfo(elementId)];
                                    case 3:
                                        error_4 = _a.sent();
                                        // tslint:disable-next-line:no-console
                                        console.warn(error_4);
                                        return [2 /*return*/, null];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(queries)];
                    case 2:
                        fetchedModels = _a.sent();
                        for (_i = 0, fetchedModels_2 = fetchedModels; _i < fetchedModels_2.length; _i++) {
                            model = fetchedModels_2[_i];
                            if (model) {
                                elementInfoResult[model.id] = model;
                            }
                        }
                        return [2 /*return*/, elementInfoResult];
                }
            });
        });
    };
    RDFDataProvider.prototype.linksInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var statementPromises, _loop_3, this_2, _i, _a, source, statements, graph, triples, fetchedModels;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _b.sent();
                        statementPromises = [];
                        _loop_3 = function (source) {
                            var _loop_4 = function (target) {
                                statementPromises.push(Promise.all([
                                    this_2.rdfStorage.checkElement(source)
                                        .then(function (exists) { return _this.fetchIfNecessary(source, exists); }),
                                    this_2.rdfStorage.checkElement(target)
                                        .then(function (exists) { return _this.fetchIfNecessary(target, exists); }),
                                ]).then(function () { return ({ subject: source, object: target }); }).catch(function (error) {
                                    // tslint:disable-next-line:no-console
                                    console.warn(error);
                                    return null;
                                }));
                            };
                            for (var _i = 0, _a = params.elementIds; _i < _a.length; _i++) {
                                var target = _a[_i];
                                _loop_4(target);
                            }
                        };
                        this_2 = this;
                        for (_i = 0, _a = params.elementIds; _i < _a.length; _i++) {
                            source = _a[_i];
                            _loop_3(source);
                        }
                        return [4 /*yield*/, Promise.all(statementPromises)];
                    case 2:
                        statements = _b.sent();
                        return [4 /*yield*/, this.rdfStorage.matchAll(statements.filter(function (statement) { return statement; }))];
                    case 3:
                        graph = _b.sent();
                        if (this.options.acceptBlankNodes) {
                            triples = graph.toArray();
                        }
                        else {
                            triples = graph.toArray().filter(function (tripple) {
                                return rdfCacheableStore_1.isNamedNode(tripple.subject) && rdfCacheableStore_1.isNamedNode(tripple.object);
                            });
                        }
                        fetchedModels = triples.map(function (t) { return ({
                            linkTypeId: t.predicate.nominalValue,
                            sourceId: t.subject.nominalValue,
                            targetId: t.object.nominalValue,
                        }); });
                        return [2 /*return*/, fetchedModels];
                }
            });
        });
    };
    RDFDataProvider.prototype.linkTypesOf = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var links, element, linkMap, incomingElementsTriples, incomingElements, _i, incomingElements_1, el, linkTypeId, outgoingElementsTriples, outElements, _a, outElements_1, el, linkTypeId;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _b.sent();
                        links = [];
                        element = params.elementId;
                        linkMap = {};
                        return [4 /*yield*/, this.rdfStorage.match(null, null, element)];
                    case 2:
                        incomingElementsTriples = _b.sent();
                        incomingElements = incomingElementsTriples.toArray()
                            .filter(function (t) { return rdfCacheableStore_1.isNamedNode(t.subject); })
                            .map(function (triple) { return triple.predicate; });
                        for (_i = 0, incomingElements_1 = incomingElements; _i < incomingElements_1.length; _i++) {
                            el = incomingElements_1[_i];
                            linkTypeId = el.nominalValue;
                            if (!linkMap[linkTypeId]) {
                                linkMap[linkTypeId] = {
                                    id: linkTypeId,
                                    inCount: 1,
                                    outCount: 0,
                                };
                                links.push(linkMap[linkTypeId]);
                            }
                            else {
                                linkMap[linkTypeId].inCount++;
                            }
                        }
                        return [4 /*yield*/, this.rdfStorage.match(element, null, null)];
                    case 3:
                        outgoingElementsTriples = _b.sent();
                        outElements = outgoingElementsTriples.toArray()
                            .filter(function (t) { return rdfCacheableStore_1.isNamedNode(t.object); })
                            .map(function (triple) { return triple.predicate; });
                        for (_a = 0, outElements_1 = outElements; _a < outElements_1.length; _a++) {
                            el = outElements_1[_a];
                            linkTypeId = el.nominalValue;
                            if (!linkMap[linkTypeId]) {
                                linkMap[linkTypeId] = {
                                    id: linkTypeId,
                                    inCount: 0,
                                    outCount: 1,
                                };
                                links.push(linkMap[linkTypeId]);
                            }
                            else {
                                linkMap[linkTypeId].outCount++;
                            }
                        }
                        return [2 /*return*/, links];
                }
            });
        });
    };
    RDFDataProvider.prototype.linkElements = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.filter({
                                refElementId: params.elementId,
                                refElementLinkId: params.linkId,
                                linkDirection: params.direction,
                                limit: params.limit,
                                offset: params.offset,
                                languageCode: '',
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RDFDataProvider.prototype.filter = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var limitCallback, offsetIndex, limitIndex, blankNodes, elementsPromise, filteredElements, elements;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitInitCompleted()];
                    case 1:
                        _a.sent();
                        if (params.limit === undefined) {
                            params.limit = 100;
                        }
                        limitCallback = function (node, index) {
                            return (blankNodes || rdfCacheableStore_1.isNamedNode(node))
                                && offsetIndex <= index
                                && index < limitIndex;
                        };
                        offsetIndex = params.offset;
                        limitIndex = params.offset + params.limit;
                        blankNodes = this.options.acceptBlankNodes;
                        if (!params.elementTypeId) return [3 /*break*/, 2];
                        elementsPromise = this.filterByTypeId(params.elementTypeId, limitCallback);
                        return [3 /*break*/, 7];
                    case 2:
                        if (!(params.refElementId && params.refElementLinkId)) return [3 /*break*/, 3];
                        elementsPromise = this.filterByRefAndLink(params.refElementId, params.refElementLinkId, params.linkDirection, limitCallback);
                        return [3 /*break*/, 7];
                    case 3:
                        if (!params.refElementId) return [3 /*break*/, 4];
                        elementsPromise = this.filterByRef(params.refElementId, limitCallback);
                        return [3 /*break*/, 7];
                    case 4:
                        if (!params.text) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.getAllElements(params.text, limitCallback)];
                    case 5:
                        filteredElements = _a.sent();
                        return [2 /*return*/, this.filterByKey(undefined, filteredElements)];
                    case 6: return [2 /*return*/, {}];
                    case 7: return [4 /*yield*/, elementsPromise];
                    case 8:
                        elements = _a.sent();
                        return [2 /*return*/, this.filterByKey(params.text, elements)];
                }
            });
        });
    };
    RDFDataProvider.prototype.filterByTypeId = function (elementTypeId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var elementTriples;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rdfStorage.match(undefined, PREFIX_FACTORIES.RDF('type'), elementTypeId)];
                    case 1:
                        elementTriples = _a.sent();
                        return [2 /*return*/, Promise.all(elementTriples.toArray()
                                .filter(function (t, index) { return filter(t.subject, index); })
                                .map(function (el) { return _this.getElementInfo(el.subject.nominalValue, true); }))];
                }
            });
        });
    };
    RDFDataProvider.prototype.filterByRefAndLink = function (refEl, refLink, linkDirection, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var elementTriples, elementTriples;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(linkDirection === 'in')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.rdfStorage.match(null, refLink, refEl)];
                    case 1:
                        elementTriples = _a.sent();
                        return [2 /*return*/, Promise.all(elementTriples.toArray().filter(function (t, index) { return filter(t.subject, index); })
                                .map(function (el) { return _this.getElementInfo(el.subject.nominalValue, true); }))];
                    case 2: return [4 /*yield*/, this.rdfStorage.match(refEl, refLink, null)];
                    case 3:
                        elementTriples = _a.sent();
                        return [2 /*return*/, Promise.all(elementTriples.toArray().filter(function (t, index) { return filter(t.object, index); })
                                .map(function (el) { return _this.getElementInfo(el.object.nominalValue, true); }))];
                }
            });
        });
    };
    RDFDataProvider.prototype.filterByRef = function (refEl, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var incomingTriples, inRelations, outgoingTriples, outRelations;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rdfStorage.match(null, null, refEl)];
                    case 1:
                        incomingTriples = _a.sent();
                        return [4 /*yield*/, Promise.all(incomingTriples.toArray().filter(function (t, index) { return filter(t.subject, index); })
                                .map(function (el) { return _this.getElementInfo(el.subject.nominalValue, true); }))];
                    case 2:
                        inRelations = _a.sent();
                        return [4 /*yield*/, this.rdfStorage.match(refEl, null, null)];
                    case 3:
                        outgoingTriples = _a.sent();
                        return [4 /*yield*/, Promise.all(outgoingTriples.toArray().filter(function (t, index) { return filter(t.object, index); })
                                .map(function (el) { return _this.getElementInfo(el.object.nominalValue, true); }))];
                    case 4:
                        outRelations = _a.sent();
                        return [2 /*return*/, inRelations.concat(outRelations)];
                }
            });
        });
    };
    RDFDataProvider.prototype.getAllElements = function (text, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, elementTriples, promices, _i, _a, triple, objectIsLiteral, isLabel, containsKey;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        key = (text ? text.toLowerCase() : null);
                        return [4 /*yield*/, this.rdfStorage.match(null, null, null)];
                    case 1:
                        elementTriples = _b.sent();
                        promices = [];
                        for (_i = 0, _a = elementTriples.toArray(); _i < _a.length; _i++) {
                            triple = _a[_i];
                            if (filter(triple.subject, promices.length)) {
                                objectIsLiteral = triple.object.interfaceName === 'Literal';
                                isLabel = rdfCacheableStore_1.isLabelType(triple.predicate.nominalValue);
                                containsKey = isLabel &&
                                    triple.object.nominalValue.toLowerCase().indexOf(key) !== -1 ||
                                    !objectIsLiteral &&
                                        triple.subject.nominalValue.toLowerCase().indexOf(key) !== -1;
                                if (containsKey) {
                                    promices.push(this.getElementInfo(triple.subject.nominalValue, true));
                                }
                            }
                        }
                        return [2 /*return*/, Promise.all(promices)];
                }
            });
        });
    };
    RDFDataProvider.prototype.filterByKey = function (text, elements) {
        var result = {};
        var key = (text ? text.toLowerCase() : null);
        if (key) {
            for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
                var el = elements_1[_i];
                var acceptableKey = false;
                for (var _a = 0, _b = el.label.values; _a < _b.length; _a++) {
                    var label = _b[_a];
                    acceptableKey = acceptableKey || label.value.toLowerCase().indexOf(key) !== -1;
                    if (acceptableKey) {
                        break;
                    }
                }
                acceptableKey = acceptableKey || el.id.toLowerCase().indexOf(key) !== -1;
                if (acceptableKey) {
                    result[el.id] = el;
                }
            }
        }
        else {
            for (var _c = 0, elements_2 = elements; _c < elements_2.length; _c++) {
                var el = elements_2[_c];
                result[el.id] = el;
            }
        }
        return result;
    };
    RDFDataProvider.prototype.checkElement = function (id) {
        return this.dataFetching ?
            this.rdfStorage.checkElement(id) :
            Promise.resolve(true);
    };
    RDFDataProvider.prototype.fetchIfNecessary = function (id, exists) {
        var _this = this;
        if (exists || !this.dataFetching) {
            return Promise.resolve();
        }
        else {
            return this.rdfLoader.downloadElement(id).then(function (rdfGraph) {
                _this.rdfStorage.add(rdfGraph);
                return;
            }).catch(function (error) {
                // tslint:disable-next-line:no-console
                console.warn(error);
                return;
            });
        }
    };
    RDFDataProvider.prototype.getElementInfo = function (id, shortInfo) {
        return Promise.all([
            this.getTypes(id),
            (!shortInfo ? this.getProps(id) : Promise.resolve({})),
            this.getLabels(id),
        ]).then(function (_a) {
            var types = _a[0], props = _a[1], labels = _a[2];
            return {
                id: id,
                types: types,
                label: { values: labels },
                properties: props,
            };
        });
    };
    RDFDataProvider.prototype.getLabels = function (id) {
        return this.rdfStorage.getLabels(id).then(function (labelTriples) {
            return labelTriples.toArray().map(function (l) { return ({
                value: l.object.nominalValue,
                language: rdfCacheableStore_1.isLiteral(l.object) ? l.object.language || '' : '',
            }); });
        });
    };
    RDFDataProvider.prototype.getProps = function (el) {
        return this.rdfStorage.match(el, null, null).then(function (propsGraph) {
            var props = {};
            var propTriples = propsGraph.toArray();
            for (var _i = 0, propTriples_1 = propTriples; _i < propTriples_1.length; _i++) {
                var statemet = propTriples_1[_i];
                if (rdfCacheableStore_1.isLiteral(statemet.object) &&
                    statemet.predicate.nominalValue !== PREFIX_FACTORIES.RDFS('label')) {
                    var property = props[statemet.predicate.nominalValue];
                    var value = {
                        text: statemet.object.nominalValue,
                        lang: statemet.object.language || '',
                    };
                    props[statemet.predicate.nominalValue] = {
                        type: 'string',
                        values: property ? tslib_1.__spreadArrays(property.values, [value]) : [value],
                    };
                }
            }
            return props;
        });
    };
    RDFDataProvider.prototype.getTypes = function (el) {
        return this.rdfStorage.match(el, PREFIX_FACTORIES.RDF('type'), null)
            .then(function (typeTriples) {
            return typeTriples.toArray().map(function (t) { return t.object.nominalValue; });
        });
    };
    RDFDataProvider.prototype.createEmptyClass = function (classIri) {
        return {
            id: classIri,
            label: {
                values: [],
            },
            count: this.rdfStorage.getTypeCount(classIri),
            children: [],
        };
    };
    return RDFDataProvider;
}());
exports.RDFDataProvider = RDFDataProvider;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var DraggableHandle = /** @class */ (function (_super) {
    tslib_1.__extends(DraggableHandle, _super);
    function DraggableHandle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isHoldingMouse = false;
        _this.onHandleMouseDown = function (e) {
            if (e.target !== e.currentTarget) {
                return;
            }
            if (_this.isHoldingMouse) {
                return;
            }
            var LEFT_BUTTON = 0;
            if (e.button !== LEFT_BUTTON) {
                return;
            }
            _this.isHoldingMouse = true;
            _this.originPageX = e.pageX;
            _this.originPageY = e.pageY;
            document.addEventListener('mousemove', _this.onMouseMove);
            document.addEventListener('mouseup', _this.onMouseUp);
            _this.props.onBeginDragHandle(e);
        };
        _this.onMouseMove = function (e) {
            if (!_this.isHoldingMouse) {
                return;
            }
            e.preventDefault();
            _this.props.onDragHandle(e, e.pageX - _this.originPageX, e.pageY - _this.originPageY);
        };
        _this.onMouseUp = function (e) {
            _this.removeListeners();
            if (_this.props.onEndDragHandle) {
                _this.props.onEndDragHandle(e);
            }
        };
        return _this;
    }
    DraggableHandle.prototype.render = function () {
        // remove custom handlers from `div` props
        // tslint:disable-next-line:no-unused-variable
        var _a = this.props, onBeginDragHandle = _a.onBeginDragHandle, onDragHandle = _a.onDragHandle, onEndDragHandle = _a.onEndDragHandle, props = tslib_1.__rest(_a, ["onBeginDragHandle", "onDragHandle", "onEndDragHandle"]);
        return React.createElement("div", tslib_1.__assign({}, props, { onMouseDown: this.onHandleMouseDown }), this.props.children);
    };
    DraggableHandle.prototype.componentWillUnmount = function () {
        this.removeListeners();
    };
    DraggableHandle.prototype.removeListeners = function () {
        if (this.isHoldingMouse) {
            this.isHoldingMouse = false;
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', this.onMouseUp);
        }
    };
    return DraggableHandle;
}(React.Component));
exports.DraggableHandle = DraggableHandle;

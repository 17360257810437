"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var elements_1 = require("../diagram/elements");
var async_1 = require("../viewUtils/async");
var spinner_1 = require("../viewUtils/spinner");
var progressBar_1 = require("../widgets/progressBar");
var elementTypeSelector_1 = require("./elementTypeSelector");
var linkTypeSelector_1 = require("./linkTypeSelector");
var CLASS_NAME = 'ontodia-edit-form';
var EditElementTypeForm = /** @class */ (function (_super) {
    tslib_1.__extends(EditElementTypeForm, _super);
    function EditElementTypeForm(props) {
        var _this = _super.call(this, props) || this;
        _this.validationCancellation = new async_1.Cancellation();
        var _a = _this.props, target = _a.target, link = _a.link;
        _this.state = {
            elementValue: {
                value: target.value,
                isNew: target.isNew,
                loading: false,
                validated: true,
                allowChange: true,
            },
            linkValue: {
                value: { link: link, direction: elements_1.LinkDirection.out },
                validated: true,
                allowChange: true,
            },
        };
        return _this;
    }
    EditElementTypeForm.prototype.componentDidMount = function () {
        this.validate();
    };
    EditElementTypeForm.prototype.componentWillUnmount = function () {
        this.validationCancellation.abort();
    };
    EditElementTypeForm.prototype.setElementOrLink = function (_a) {
        var _this = this;
        var elementValue = _a.elementValue, linkValue = _a.linkValue;
        this.setState(function (state) { return ({
            elementValue: elementValue || state.elementValue,
            linkValue: linkValue || state.linkValue,
        }); }, function () {
            if ((elementValue && !elementValue.validated) || (linkValue && !linkValue.validated)) {
                _this.validate();
            }
            if (elementValue && elementValue.validated && elementValue.allowChange) {
                _this.props.onChangeElement(elementValue.value);
            }
            if (linkValue && linkValue.validated && linkValue.allowChange) {
                _this.props.onChangeLink(linkValue.value.link);
            }
        });
    };
    EditElementTypeForm.prototype.validate = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, originalLink = _a.link;
        var _b = this.state, elementValue = _b.elementValue, linkValue = _b.linkValue;
        this.setState({ isValidating: true });
        this.validationCancellation.abort();
        this.validationCancellation = new async_1.Cancellation();
        var signal = this.validationCancellation.signal;
        var validateElement = elementTypeSelector_1.validateElementType(elementValue.value);
        var validateLink = linkTypeSelector_1.validateLinkType(editor, linkValue.value.link, originalLink);
        Promise.all([validateElement, validateLink]).then(function (_a) {
            var elementError = _a[0], linkError = _a[1];
            if (signal.aborted) {
                return;
            }
            _this.setState({ isValidating: false });
            _this.setElementOrLink({
                elementValue: tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, elementValue), elementError), { validated: true }),
                linkValue: tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, linkValue), linkError), { validated: true }),
            });
        });
    };
    EditElementTypeForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, view = _a.view, metadataApi = _a.metadataApi, source = _a.source, originalLink = _a.link;
        var _b = this.state, elementValue = _b.elementValue, linkValue = _b.linkValue, isValidating = _b.isValidating;
        var isValid = !elementValue.error && !linkValue.error;
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: CLASS_NAME + "__body" },
                React.createElement(elementTypeSelector_1.ElementTypeSelector, { editor: editor, view: view, metadataApi: metadataApi, source: source, elementValue: elementValue, onChange: function (newState) {
                        _this.setElementOrLink({
                            elementValue: {
                                value: newState.value,
                                isNew: newState.isNew,
                                loading: newState.loading,
                                error: undefined,
                                validated: false,
                                allowChange: false,
                            },
                            linkValue: {
                                value: {
                                    link: tslib_1.__assign(tslib_1.__assign({}, originalLink), { targetId: newState.value.id }),
                                    direction: elements_1.LinkDirection.out,
                                },
                                validated: false,
                                allowChange: false,
                            },
                        });
                    } }),
                elementValue.loading ? (React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 }),
                    "\u00A0Loading entity...")) : (React.createElement(linkTypeSelector_1.LinkTypeSelector, { editor: editor, view: view, metadataApi: metadataApi, linkValue: linkValue, source: source, target: elementValue.value, onChange: function (value) { return _this.setElementOrLink({
                        linkValue: { value: value, error: undefined, validated: false, allowChange: false },
                    }); }, disabled: elementValue.error !== undefined })),
                isValidating ? (React.createElement("div", { className: CLASS_NAME + "__progress" },
                    React.createElement(progressBar_1.ProgressBar, { state: progressBar_1.ProgressState.loading, height: 10 }))) : null),
            React.createElement("div", { className: CLASS_NAME + "__controls" },
                React.createElement("button", { className: "ontodia-btn ontodia-btn-success " + CLASS_NAME + "__apply-button", onClick: function () { return _this.props.onApply(elementValue.value, elementValue.isNew, linkValue.value.link); }, disabled: elementValue.loading || !isValid || isValidating }, "Apply"),
                React.createElement("button", { className: 'ontodia-btn ontodia-btn-danger', onClick: this.props.onCancel }, "Cancel"))));
    };
    return EditElementTypeForm;
}(React.Component));
exports.EditElementTypeForm = EditElementTypeForm;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var collections_1 = require("../viewUtils/collections");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var listElementView_1 = require("./listElementView");
var CLASS_NAME = 'ontodia-search-results';
var SearchResults = /** @class */ (function (_super) {
    tslib_1.__extends(SearchResults, _super);
    function SearchResults(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.delayedChangeCells = new async_1.Debouncer();
        _this.startSelection = 0;
        _this.endSelection = 0;
        _this.onRootMount = function (root) {
            _this.root = root;
        };
        _this.renderResultItem = function (model) {
            var useDragAndDrop = _this.props.useDragAndDrop;
            var canBeSelected = _this.canBeSelected(model);
            return (React.createElement(listElementView_1.ListElementView, { key: model.id, model: model, view: _this.props.view, highlightText: _this.props.highlightText, disabled: !canBeSelected, selected: _this.props.selection.has(model.id), onClick: canBeSelected ? _this.onItemClick : undefined, onDragStart: useDragAndDrop ? function (e) {
                    var selection = _this.props.selection;
                    var iris = [];
                    selection.forEach(function (iri) { return iris.push(iri); });
                    if (!selection.has(model.id)) {
                        iris.push(model.id);
                    }
                    return listElementView_1.startDragElements(e, iris);
                } : undefined }));
        };
        _this.onChangeCells = function () {
            var _a = _this.props, items = _a.items, selection = _a.selection;
            if (selection.size === 0) {
                if (items && items.length > 0) {
                    // redraw "already on diagram" state
                    _this.forceUpdate();
                }
            }
            else {
                var newSelection = collections_1.cloneSet(selection);
                for (var _i = 0, _b = _this.props.view.model.elements; _i < _b.length; _i++) {
                    var element = _b[_i];
                    if (element.group === undefined && selection.has(element.iri)) {
                        newSelection.delete(element.iri);
                    }
                }
                _this.updateSelection(newSelection);
            }
        };
        _this.addKeyListener = function () {
            document.addEventListener('keydown', _this.onKeyUp);
        };
        _this.removeKeyListener = function () {
            document.removeEventListener('keydown', _this.onKeyUp);
        };
        _this.onKeyUp = function (event) {
            var items = _this.props.items;
            var isPressedUp = event.keyCode === 38 || event.which === 38;
            var isPressDown = event.keyCode === 40 || event.which === 40;
            if (isPressedUp || isPressDown) {
                if (event.shiftKey) { // select range
                    if (isPressedUp) {
                        _this.endSelection = _this.getNextIndex(_this.endSelection, 0 /* Up */);
                    }
                    else if (isPressDown) {
                        _this.endSelection = _this.getNextIndex(_this.endSelection, 1 /* Down */);
                    }
                    var startIndex = Math.min(_this.startSelection, _this.endSelection);
                    var finishIndex = Math.max(_this.startSelection, _this.endSelection);
                    var selection = _this.selectRange(startIndex, finishIndex);
                    _this.updateSelection(selection);
                    _this.focusOn(_this.endSelection);
                }
                else { // change focus
                    var startIndex = Math.min(_this.startSelection, _this.endSelection);
                    var finishIndex = Math.max(_this.startSelection, _this.endSelection);
                    if (isPressedUp) {
                        _this.startSelection = _this.getNextIndex(startIndex, 0 /* Up */);
                    }
                    else if (isPressDown) {
                        _this.startSelection = _this.getNextIndex(finishIndex, 1 /* Down */);
                    }
                    _this.endSelection = _this.startSelection;
                    var focusElement = items[_this.startSelection];
                    var newSelection = new Set();
                    newSelection.add(focusElement.id);
                    _this.updateSelection(newSelection);
                    _this.focusOn(_this.startSelection);
                }
            }
            event.preventDefault();
        };
        _this.onItemClick = function (event, model) {
            event.preventDefault();
            var _a = _this.props, items = _a.items, selection = _a.selection, onSelectionChanged = _a.onSelectionChanged;
            var previouslySelected = selection.has(model.id);
            var modelIndex = items.indexOf(model);
            var newSelection;
            if (event.shiftKey && _this.startSelection !== -1) { // select range
                var start = Math.min(_this.startSelection, modelIndex);
                var end = Math.max(_this.startSelection, modelIndex);
                newSelection = _this.selectRange(start, end);
            }
            else {
                _this.endSelection = _this.startSelection = modelIndex;
                var ctrlKey = event.ctrlKey || event.metaKey;
                if (ctrlKey) { // select/deselect
                    newSelection = collections_1.cloneSet(selection);
                    if (selection.has(model.id)) {
                        newSelection.delete(model.id);
                    }
                    else {
                        newSelection.add(model.id);
                    }
                }
                else { // single click
                    newSelection = new Set();
                    newSelection.add(model.id);
                }
            }
            onSelectionChanged(newSelection);
        };
        _this.state = {
            selection: props.selection || {},
        };
        return _this;
    }
    SearchResults.prototype.render = function () {
        var items = this.props.items || [];
        return React.createElement("ul", { className: CLASS_NAME, ref: this.onRootMount, tabIndex: -1, onFocus: this.addKeyListener, onBlur: this.removeKeyListener }, items.map(this.renderResultItem));
    };
    SearchResults.prototype.componentDidMount = function () {
        var _this = this;
        this.listener.listen(this.props.view.model.events, 'changeCells', function () {
            _this.delayedChangeCells.call(_this.onChangeCells);
        });
    };
    SearchResults.prototype.componentWillReceiveProps = function (props) {
        this.setState({ selection: props.selection || {} });
    };
    SearchResults.prototype.componentWillUnmount = function () {
        this.removeKeyListener();
        this.listener.stopListening();
        this.delayedChangeCells.dispose();
    };
    SearchResults.prototype.updateSelection = function (selection) {
        var onSelectionChanged = this.props.onSelectionChanged;
        onSelectionChanged(selection);
    };
    SearchResults.prototype.selectRange = function (start, end) {
        var items = this.props.items;
        var selection = new Set();
        for (var i = start; i <= end; i++) {
            var selectedModel = items[i];
            if (this.canBeSelected(selectedModel)) {
                selection.add(selectedModel.id);
            }
        }
        return selection;
    };
    SearchResults.prototype.getNextIndex = function (startIndex, direction) {
        var items = this.props.items;
        if (items.length === 0) {
            return startIndex;
        }
        var indexDelta = direction === 0 /* Up */ ? -1 : 1;
        for (var step = 1; step < items.length; step++) {
            var nextIndex = startIndex + step * indexDelta;
            if (nextIndex < 0) {
                nextIndex += items.length;
            }
            if (nextIndex >= items.length) {
                nextIndex -= items.length;
            }
            if (this.canBeSelected(items[nextIndex])) {
                return nextIndex;
            }
        }
        return startIndex;
    };
    SearchResults.prototype.canBeSelected = function (model) {
        var alreadyOnDiagram = this.props.view.model.elements.findIndex(function (element) { return element.iri === model.id && element.group === undefined; }) >= 0;
        return !this.props.useDragAndDrop || !alreadyOnDiagram;
    };
    SearchResults.prototype.focusOn = function (index) {
        var scrollableContainer = this.root.parentElement;
        var containerBounds = scrollableContainer.getBoundingClientRect();
        var item = this.root.children.item(index);
        var itemBounds = item.getBoundingClientRect();
        var itemTop = itemBounds.top - containerBounds.top;
        var itemBottom = itemBounds.bottom - containerBounds.top;
        if (itemTop < 0) {
            scrollableContainer.scrollTop += itemTop;
        }
        else if (itemBottom > containerBounds.height) {
            scrollableContainer.scrollTop += (itemBottom - containerBounds.height);
        }
        item.focus();
    };
    SearchResults.defaultProps = {
        useDragAndDrop: true,
    };
    return SearchResults;
}(React.Component));
exports.SearchResults = SearchResults;

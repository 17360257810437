"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
exports.RDFSettings = {
    linkConfigurations: [],
    openWorldLinks: false,
    propertyConfigurations: [],
    openWorldProperties: false,
    linksInfoQuery: "SELECT ?source ?type ?target\n            WHERE {\n                ${linkConfigurations}\n                VALUES (?source) {${ids}}\n                VALUES (?target) {${ids}}\n            }",
    defaultPrefix: '',
    schemaLabelProperty: 'rdfs:label',
    dataLabelProperty: 'rdfs:label',
    fullTextSearch: {
        prefix: '',
        queryPattern: "",
    },
    classTreeQuery: "",
    classInfoQuery: "SELECT ?class ?label ?instcount WHERE {\n    VALUES(?class) {${ids}}\n    OPTIONAL { ?class ${schemaLabelProperty} ?label }\n    BIND(\"\" as ?instcount)\n}",
    linkTypesQuery: "SELECT DISTINCT ?link ?instcount ?label WHERE {\n    ${linkTypesPattern}\n    OPTIONAL { ?link ${schemaLabelProperty} ?label }\n}",
    linkTypesPattern: "",
    linkTypesInfoQuery: "SELECT ?link ?label WHERE {\n    VALUES(?link) {${ids}}\n    OPTIONAL { ?link ${schemaLabelProperty} ?label }\n}",
    propertyInfoQuery: "SELECT ?property ?label WHERE {\n    VALUES(?property) {${ids}}\n    OPTIONAL { ?property ${schemaLabelProperty} ?label }\n}",
    elementInfoQuery: "",
    imageQueryPattern: "",
    linkTypesOfQuery: "",
    linkTypesStatisticsQuery: "",
    filterRefElementLinkPattern: '',
    filterTypePattern: "",
    filterAdditionalRestriction: "",
    filterElementInfoPattern: "",
};
var WikidataSettingsOverride = {
    defaultPrefix: "PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\n PREFIX wdt: <http://www.wikidata.org/prop/direct/>\n PREFIX wd: <http://www.wikidata.org/entity/>\n PREFIX owl:  <http://www.w3.org/2002/07/owl#>\n\n",
    schemaLabelProperty: 'rdfs:label',
    dataLabelProperty: 'rdfs:label',
    fullTextSearch: {
        prefix: 'PREFIX bds: <http://www.bigdata.com/rdf/search#>' + '\n',
        queryPattern: "\n              ?inst rdfs:label ?searchLabel.\n              SERVICE bds:search {\n                     ?searchLabel bds:search \"${text}*\" ;\n                                  bds:minRelevance '0.5' ;\n                                  bds:matchAllTerms 'true' .\n              }\n              BIND(IF(STRLEN(?strInst) > 33,\n                            0-<http://www.w3.org/2001/XMLSchema#integer>(SUBSTR(?strInst, 33)),\n                            -10000) as ?score)\n            ",
    },
    classTreeQuery: "\n            SELECT distinct ?class ?label ?parent WHERE {\n              ?class rdfs:label ?label.\n              { ?class wdt:P279 wd:Q35120. }\n                UNION\n              { ?parent wdt:P279 wd:Q35120.\n                ?class wdt:P279 ?parent. }\n                UNION\n              { ?parent wdt:P279/wdt:P279 wd:Q35120.\n                ?class wdt:P279 ?parent. }\n            }\n        ",
    // todo: think more, maybe add a limit here?
    linkTypesPattern: "?link wdt:P279* wd:Q18616576.\n    BIND(0 as ?instcount)\n",
    elementInfoQuery: "\n        CONSTRUCT {\n            ?inst rdf:type ?class .\n            ?inst rdfs:label ?label .\n            ?inst ?propType ?propValue.\n        } WHERE {\n            VALUES (?inst) {${ids}}\n            OPTIONAL {\n                ?inst wdt:P31 ?class\n            }\n            OPTIONAL {?inst rdfs:label ?label}\n            OPTIONAL {\n                ${propertyConfigurations}\n                FILTER (isLiteral(?propValue))\n            }\n        }\n    ",
    imageQueryPattern: " { ?inst ?linkType ?fullImage } union { ?inst wdt:P163/wdt:P18 ?fullImage }\n                BIND(CONCAT(\"https://commons.wikimedia.org/w/thumb.php?f=\",\n                    STRAFTER(STR(?fullImage), \"Special:FilePath/\"), \"&w=200\") AS ?image)",
    linkTypesOfQuery: "\n        SELECT DISTINCT ?link\n        WHERE {\n            ${linkConfigurations}\n            ?claim <http://wikiba.se/ontology#directClaim> ?link .\n        }\n    ",
    linkTypesStatisticsQuery: "\n        SELECT (${linkId} as ?link) (COUNT(?outObject) AS ?outCount) (COUNT(?inObject) AS ?inCount)\n        WHERE {\n            {\n                {\n                    SELECT DISTINCT ?outObject WHERE {\n                        ${linkConfigurationOut}\n                        FILTER(ISIRI(?outObject))\n                        ?outObject ?someprop ?someobj.\n                    }\n                    LIMIT 101\n                }\n            } UNION {\n                {\n                    SELECT DISTINCT ?inObject WHERE {\n                        ${linkConfigurationIn}\n                        FILTER(ISIRI(?inObject))\n                        ?inObject ?someprop ?someobj.\n                    }\n                    LIMIT 101\n                }\n            }\n        }\n    ",
    filterRefElementLinkPattern: '?claim <http://wikiba.se/ontology#directClaim> ?link .',
    filterTypePattern: "?inst wdt:P31 ?instType. ?instType wdt:P279* ?class",
    filterAdditionalRestriction: "FILTER ISIRI(?inst)\n                        BIND(STR(?inst) as ?strInst)\n                        FILTER exists {?inst ?someprop ?someobj}\n",
    filterElementInfoPattern: "OPTIONAL {?inst wdt:P31 ?foundClass}\n                BIND (coalesce(?foundClass, owl:Thing) as ?class)\n                OPTIONAL {?inst rdfs:label ?label}\n",
};
exports.WikidataSettings = tslib_1.__assign(tslib_1.__assign({}, exports.RDFSettings), WikidataSettingsOverride);
exports.OWLRDFSSettingsOverride = {
    defaultPrefix: "PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\n PREFIX owl:  <http://www.w3.org/2002/07/owl#>\n",
    schemaLabelProperty: 'rdfs:label',
    dataLabelProperty: 'rdfs:label',
    fullTextSearch: {
        prefix: '',
        queryPattern: " OPTIONAL {?inst ${dataLabelProperty} ?search1}\n        FILTER regex(COALESCE(str(?search1), str(?extractedLabel)), \"${text}\", \"i\")\n        BIND(0 as ?score)\n",
        extractLabel: true,
    },
    classTreeQuery: "\n            SELECT ?class ?label ?parent\n            WHERE {\n                {\n                    ?class a rdfs:Class\n                } UNION {\n                    ?class a owl:Class\n                }\n                FILTER ISIRI(?class)\n                OPTIONAL {?class rdfs:label ?label}\n                OPTIONAL {?class rdfs:subClassOf ?parent. FILTER ISIRI(?parent)}\n            }\n        ",
    // todo: think more, maybe add a limit here?
    linkTypesPattern: "{\t?link a rdf:Property\n                    } UNION {\n                    ?link a owl:ObjectProperty\n                }\n                BIND('' as ?instcount)\n    ",
    elementInfoQuery: "\n        CONSTRUCT {\n            ?inst rdf:type ?class .\n            ?inst rdfs:label ?label .\n            ?inst ?propType ?propValue.\n        } WHERE {\n            VALUES (?inst) {${ids}}\n            OPTIONAL { ?inst a ?class }\n            OPTIONAL {?inst ${dataLabelProperty} ?label}\n            OPTIONAL {\n                ${propertyConfigurations}\n                FILTER (isLiteral(?propValue))\n            }\n        }\n    ",
    imageQueryPattern: "{ ?inst ?linkType ?image } UNION { [] ?linkType ?inst. BIND(?inst as ?image) }",
    linkTypesOfQuery: "\n        SELECT DISTINCT ?link\n        WHERE {\n            ${linkConfigurations}\n        }\n    ",
    linkTypesStatisticsQuery: "\n        SELECT ?link ?outCount ?inCount\n        WHERE {\n            {\n                SELECT (${linkId} as ?link) (count(?outObject) as ?outCount) WHERE {\n                    ${linkConfigurationOut}\n                    ${navigateElementFilterOut}\n                } LIMIT 101\n            } {\n                SELECT (${linkId} as ?link) (count(?inObject) as ?inCount) WHERE {\n                    ${linkConfigurationIn}\n                    ${navigateElementFilterIn}\n                } LIMIT 101\n            }\n        }\n    ",
    filterRefElementLinkPattern: '',
    filterTypePattern: "?inst a ?instType. ?instType rdfs:subClassOf* ?class",
    filterElementInfoPattern: "OPTIONAL {?inst rdf:type ?foundClass}\n                BIND (coalesce(?foundClass, owl:Thing) as ?class)\n                OPTIONAL {?inst ${dataLabelProperty} ?label}",
    filterAdditionalRestriction: '',
};
exports.OWLRDFSSettings = tslib_1.__assign(tslib_1.__assign({}, exports.RDFSettings), exports.OWLRDFSSettingsOverride);
var OWLStatsOverride = {
    classTreeQuery: "\n        SELECT ?class ?instcount ?label ?parent\n        WHERE {\n            {SELECT ?class (count(?inst) as ?instcount)\n                WHERE {\n                    ?inst rdf:type ?class.\n                    FILTER ISIRI(?class)\n                } GROUP BY ?class } UNION\n            {\n                ?class rdf:type rdfs:Class\n            } UNION {\n                ?class rdf:type owl:Class\n            }\n            OPTIONAL {?class rdfs:label ?label}\n            OPTIONAL {?class rdfs:subClassOf ?parent. FILTER ISIRI(?parent)}\n        }\n    ",
};
exports.OWLStatsSettings = tslib_1.__assign(tslib_1.__assign({}, exports.OWLRDFSSettings), OWLStatsOverride);
var DBPediaOverride = {
    fullTextSearch: {
        prefix: 'PREFIX dbo: <http://dbpedia.org/ontology/>\n',
        queryPattern: "\n              ?inst rdfs:label ?searchLabel.\n              ?searchLabel bif:contains \"${text}\".\n              ?inst dbo:wikiPageID ?origScore .\n              BIND(0-?origScore as ?score)\n        ",
    },
    classTreeQuery: "\n        SELECT distinct ?class ?label ?parent WHERE {\n            ?class rdfs:label ?label.\n            OPTIONAL {?class rdfs:subClassOf ?parent}\n            ?root rdfs:subClassOf owl:Thing.\n            ?class rdfs:subClassOf? | rdfs:subClassOf/rdfs:subClassOf ?root\n        }\n    ",
    elementInfoQuery: "\n        CONSTRUCT {\n            ?inst rdf:type ?class .\n            ?inst rdfs:label ?label .\n            ?inst ?propType ?propValue.\n        } WHERE {\n            VALUES (?inst) {${ids}}\n            ?inst a ?class .\n            ?inst rdfs:label ?label .\n            FILTER (!contains(str(?class), 'http://dbpedia.org/class/yago'))\n            OPTIONAL {\n                ${propertyConfigurations}\n                FILTER (isLiteral(?propValue))\n            }\n        }\n    ",
    filterTypePattern: "?inst a ?instType. ?instType rdfs:subClassOf* ?class",
    filterElementInfoPattern: "\n        OPTIONAL {?inst rdf:type ?foundClass. FILTER (!contains(str(?foundClass), 'http://dbpedia.org/class/yago'))}\n        BIND (coalesce(?foundClass, owl:Thing) as ?class)\n        OPTIONAL {?inst ${dataLabelProperty} ?label}",
    imageQueryPattern: " { ?inst ?linkType ?fullImage } UNION { [] ?linkType ?inst. BIND(?inst as ?fullImage) }\n            BIND(CONCAT(\"https://commons.wikimedia.org/w/thumb.php?f=\",\n            STRAFTER(STR(?fullImage), \"Special:FilePath/\"), \"&w=200\") AS ?image)\n    ",
};
exports.DBPediaSettings = tslib_1.__assign(tslib_1.__assign({}, exports.OWLRDFSSettings), DBPediaOverride);

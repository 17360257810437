"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var events_1 = require("../viewUtils/events");
var spinner_1 = require("../viewUtils/spinner");
var CLASS_NAME = "ontodia-authoring-state";
var ElementDecorator = /** @class */ (function (_super) {
    tslib_1.__extends(ElementDecorator, _super);
    function ElementDecorator(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        var model = props.model, editor = props.editor;
        _this.state = {
            state: editor.authoringState.elements.get(model.iri),
            validation: editor.validationState.elements.get(model.iri),
            isTemporary: editor.temporaryState.elements.has(model.iri),
        };
        return _this;
    }
    ElementDecorator.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, model = _a.model, editor = _a.editor;
        this.listener.listen(model.events, 'changeSize', function () {
            return _this.forceUpdate();
        });
        this.listener.listen(editor.events, 'changeAuthoringState', function (e) {
            var state = editor.authoringState.elements.get(model.iri);
            if (state === e.previous.elements.get(model.iri)) {
                return;
            }
            _this.setState({ state: state });
        });
        this.listener.listen(editor.events, 'changeValidationState', function (e) {
            var validation = editor.validationState.elements.get(model.iri);
            if (validation === e.previous.elements.get(model.iri)) {
                return;
            }
            _this.setState({ validation: validation });
        });
        this.listener.listen(editor.events, 'changeTemporaryState', function (e) {
            var isTemporary = editor.temporaryState.elements.has(model.iri);
            if (isTemporary === e.previous.elements.has(model.iri)) {
                return;
            }
            _this.setState({ isTemporary: isTemporary });
        });
        this.listener.listen(model.events, 'changeData', function (e) {
            if (e.previous.id !== model.iri) {
                _this.setState({
                    isTemporary: editor.temporaryState.elements.has(model.iri),
                    validation: editor.validationState.elements.get(model.iri),
                    state: editor.authoringState.elements.get(model.iri),
                });
            }
        });
    };
    ElementDecorator.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
    };
    ElementDecorator.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return (this.state.state !== nextState.state ||
            this.state.validation !== nextState.validation ||
            this.state.isTemporary !== nextState.isTemporary ||
            this.props.position !== nextProps.position);
    };
    ElementDecorator.prototype.renderElementOutlines = function () {
        var model = this.props.model;
        var _a = this.state, state = _a.state, isTemporary = _a.isTemporary;
        var _b = model.size, width = _b.width, height = _b.height;
        if (isTemporary) {
            return [
                React.createElement("rect", { key: model.id + "-opacity", x: 0, y: 0, width: width, height: height, fill: 'rgba(255, 255, 255, 0.5)' }),
                React.createElement("rect", { key: model.id + "-stripes", x: 0, y: 0, width: width, height: height, fill: 'url(#stripe-pattern)' })
            ];
        }
        if (state && state.deleted) {
            var right = width;
            var bottom = height;
            return (React.createElement("g", { key: model.id },
                React.createElement("rect", { x: 0, y: 0, width: width, height: height, fill: 'white', fillOpacity: 0.5 }),
                React.createElement("line", { x1: 0, y1: 0, x2: right, y2: bottom, stroke: 'red' }),
                React.createElement("line", { x1: right, y1: 0, x2: 0, y2: bottom, stroke: 'red' })));
        }
        return null;
    };
    ElementDecorator.prototype.renderErrorIcon = function (title, validation) {
        return React.createElement("div", { className: CLASS_NAME + "__item-error", title: title },
            validation.loading
                ? React.createElement(spinner_1.HtmlSpinner, { width: 15, height: 17 })
                : React.createElement("div", { className: CLASS_NAME + "__item-error-icon" }),
            (!validation.loading && validation.errors.length > 0)
                ? validation.errors.length : undefined);
    };
    ElementDecorator.prototype.renderElementErrors = function () {
        var view = this.props.view;
        var validation = this.state.validation;
        if (!validation) {
            return null;
        }
        var title = validation.errors.map(function (error) {
            if (error.propertyType) {
                var _a = view.model.createProperty(error.propertyType), id = _a.id, label = _a.label;
                var source = view.formatLabel(label, id);
                return source + ": " + error.message;
            }
            else {
                return error.message;
            }
        }).join('\n');
        return this.renderErrorIcon(title, validation);
    };
    ElementDecorator.prototype.renderElementState = function () {
        var _a = this.props, model = _a.model, editor = _a.editor;
        var state = this.state.state;
        if (state) {
            var onCancel = function () { return editor.discardChange(state); };
            var renderedState = void 0;
            var statusText = void 0;
            var title = void 0;
            if (state.deleted) {
                statusText = 'Delete';
                title = 'Revert deletion of the element';
            }
            else if (!state.before) {
                statusText = 'New';
                title = 'Revert creation of the element';
            }
            else {
                statusText = 'Change';
                title = 'Revert all changes in properties of the element';
            }
            if (statusText && title) {
                renderedState = (React.createElement("span", null,
                    React.createElement("span", { className: CLASS_NAME + "__state-label" }, statusText),
                    "[",
                    React.createElement("span", { className: CLASS_NAME + "__state-cancel", onClick: onCancel, title: title }, "cancel"),
                    "]"));
            }
            var renderedErrors = this.renderElementErrors();
            if (renderedState || renderedErrors) {
                return (React.createElement("div", { className: CLASS_NAME + "__state-indicator", key: model.id, style: { left: 0, top: 0 } },
                    React.createElement("div", { className: CLASS_NAME + "__state-indicator-container" },
                        React.createElement("div", { className: CLASS_NAME + "__state-indicator-body" },
                            renderedState,
                            renderedErrors))));
            }
        }
        return null;
    };
    ElementDecorator.prototype.render = function () {
        var _a = this.props.model, position = _a.position, size = _a.size;
        var transform = "translate(" + position.x + "px," + position.y + "px)";
        var outlines = this.renderElementOutlines();
        var state = this.renderElementState();
        if (!outlines && !state) {
            return null;
        }
        return (React.createElement("div", { style: { position: 'absolute', transform: transform } },
            outlines ? (React.createElement("svg", { width: size.width, height: size.height, style: { position: 'absolute', pointerEvents: 'none', overflow: 'visible' } },
                React.createElement("defs", null,
                    React.createElement("pattern", { id: 'stripe-pattern', patternUnits: 'userSpaceOnUse', width: 13, height: 13, patternTransform: 'rotate(45)' },
                        React.createElement("line", { x1: 0, y: 0, x2: 0, y2: 13, stroke: '#ddd', strokeWidth: 10, strokeOpacity: 0.2 }))),
                this.renderElementOutlines())) : null,
            state));
    };
    return ElementDecorator;
}(React.Component));
exports.ElementDecorator = ElementDecorator;
